import * as React from 'react';
import { WBButton, WBFlex } from '@admiin-com/ds-web';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { Bill } from '../PayBillCreateForm';
import { ManualBillForm } from './ManualBillForm';
import { useScanning } from './useScanning';
import { t } from 'i18next';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { ReviewForm } from './ReviewForm';
import { useTaskCreateModal } from '../../TaskCreateModal/useTaskCreateModal';
import { useBillCreate } from '../useBillCreate';
import BillModal from './BillModal';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { findFirstErrorField } from '../../../helpers/string';

interface Props {
  bills: Bill[];
  setBills: (bills: Bill[]) => void;
  manualBillInput: boolean;
  scanning: boolean;
  setScanning: (scanning: boolean) => void;
}
export const ReviewBills = ({
  bills,
  setBills,
  manualBillInput,
  scanning,
  setScanning,
}: Props) => {
  const [isDraftLoading, setIsDraftLoading] = React.useState(false);
  const [isAdding, setIsAdding] = React.useState(false);
  const { onClose } = useTaskCreateModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const scrollToError = (errorFields: any) => {
    console.log(errorFields);
    // Helper function to recursively find the first error field

    const firstErrorField = findFirstErrorField(errorFields);
    // console.log(firstErrorField);
    if (firstErrorField) {
      // setFocus(firstErrorField as any); // Focus the first error field
      const errorElement = document.getElementsByName(firstErrorField)?.[0];
      console.log(errorElement);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' }); // Smoothly scroll to the field
      }
    }
  };

  const setErrors = async (
    index: number,
    errors: { path: string; message: string }[],
    fieldErrors?: FieldErrors<FieldValues>
  ) => {
    const errorObject: { [key: string]: string } = {};
    console.log(fieldErrors);
    if (fieldErrors) {
      scrollToError(fieldErrors);
    }
    errors.forEach((error) => {
      errorObject[error.path] = error.message;
    });
    setBills(
      bills.map((bill, i) =>
        i === index ? { ...bill, errors: errorObject } : bill
      )
    );
  };

  const { createBillsDraft, createBills } = useBillCreate({
    onErrorRow: async ({ index, errors, fieldErrors }) => {
      setErrors(index, errors, fieldErrors);
      if (manualBillInput) return;
      setOpened(index);
      if (isMobile) setEditBillModal(bills[index]);
    },
  });

  const handleDraft = async () => {
    try {
      setIsDraftLoading(true);
      await createBillsDraft(bills);
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsDraftLoading(false);
    }
  };
  const isValid = bills.filter((bill) => bill.checked).length > 0;
  const billCount = bills.filter((bill) => bill.checked).length;

  const handleConfirm = async () => {
    try {
      setIsAdding(true);
      await createBills(bills);
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsAdding(false);
    }
  };
  const [opened, setOpened] = React.useState<number>(-1);
  const [editBillModal, setEditBillModal] = React.useState<Bill | null>(null);
  useScanning({ bills, setBills, setScanning });
  return (
    <Container>
      {manualBillInput ? (
        <ManualBillForm
          bill={bills[0]}
          onUpdateBill={(bill) => setBills([bill])}
        />
      ) : (
        <ReviewForm
          bills={bills}
          isLoading={scanning}
          setBills={setBills}
          openedRow={opened}
          setOpenedRow={setOpened}
        />
      )}
      {editBillModal && (
        <BillModal
          item={editBillModal}
          open={!!editBillModal}
          onClose={() => {
            setEditBillModal(null);
          }}
          onOk={(newBill) => {
            setBills(
              bills.map((bill, i) =>
                i === opened ? { ...bill, ...newBill } : bill
              )
            );
            setEditBillModal(null);
          }}
        />
      )}
      <ButtonContainer>
        <WBButton
          variant="outlined"
          onClick={handleDraft}
          loading={isDraftLoading}
          sx={{ mt: 1, mr: 1 }}
          disabled={scanning}
          fullWidth
        >
          {t('saveAsDraft', { ns: 'taskbox' })}
        </WBButton>
        <WBButton
          loading={isAdding}
          type="submit"
          onClick={handleConfirm}
          sx={{ mt: 1, mr: 1 }}
          disabled={!isValid || scanning}
          fullWidth
        >
          {billCount <= 1
            ? t('addBillToTaskboxSingle', { ns: 'taskbox', count: billCount })
            : t('addBillToTaskboxPlural', { ns: 'taskbox', count: billCount })}
          &nbsp;
          {isAdding && <LoadingSpinner />}
        </WBButton>
      </ButtonContainer>
    </Container>
  );
};
const ButtonContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  width: '50%',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column-reverse',
  },
}));

const Container = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(2),
  width: '100%',
  alignItems: 'end',
  backgroundColor: theme.palette.background.default,
}));
