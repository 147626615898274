import { LOGO_LARGE_ADMIIN, LOGO_ICON_ADMIIN } from '@admiin-com/ds-common';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useNotificationService } from '../../hooks/useNotificationService/useNotificationService';
import { SidebarLayout } from '../SidebarLayout/SidebarLayout';
import { DRAWER_WIDTH } from '../../constants/config';
import { useAuthGuard } from '../../features/hooks/useAuthGuard/useAuthGuard';
import { useFeatureFlags } from '../../hooks/useFeatureFlags/useFeatureFlags';
import MainDrawerLayout from '../../features/layouts/MainDrawerLayout/MainDrawerLayout';
import { useEntitySelect } from '../../features/hooks/useEntitySelect/useEntitySelect';
const drawerWidth = DRAWER_WIDTH;

export const DrawerLayout = () => {
  useAuthGuard();
  useEntitySelect();
  useNotificationService();

  const { featureFlags } = useFeatureFlags();
  if (featureFlags && featureFlags.newLayout)
    return (
      <MainDrawerLayout>
        <Suspense fallback={<div />}>
          <Outlet />
        </Suspense>
      </MainDrawerLayout>
    );

  return (
    <SidebarLayout
      drawerWidth={drawerWidth}
      logoFullSrc={LOGO_LARGE_ADMIIN}
      logoIconSrc={LOGO_ICON_ADMIIN}
    >
      <Suspense fallback={<div />}>
        <Outlet />
      </Suspense>
    </SidebarLayout>
  );
};
