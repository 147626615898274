import { TaskGuest, TaskSignatureStatus } from '@admiin-com/ds-graphql';
import { useMediaQuery, useTheme } from '@mui/material';
import PSPDFKit, { AnnotationsUnion } from 'pspdfkit';
import React from 'react';
import { isAllSigned } from '../../helpers/signature';

const PdfScrollContext = React.createContext<any>(null);

export const PdfScrollProvider = ({
  annotations,
  children,
  onSave,
  taskGuest,
}: {
  taskGuest: TaskGuest;
  onSave?: (annotations: string) => Promise<void>;
  annotations: string;
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = React.useState(false);
  const fullSigned = taskGuest.signatureStatus === TaskSignatureStatus.SIGNED;
  const completed = React.useMemo(
    () => isAllSigned(taskGuest.annotations),
    [taskGuest]
  );
  const pdfInstanceRef = React.useRef<any>(null);
  const [indicatorIndex, setIndicatorIndex] = React.useState<number>(0);
  const [actionedSignatures, setActionedSignatures] = React.useState<number>(0);
  const [totalSignatures, setTotalSignatures] = React.useState<number>(0);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [signedSignatures, setSignedSignatures] = React.useState<Array<string>>(
    []
  );
  const signingAnnotationRef = React.useRef<any>(null);
  const pdfContentRef = React.useRef<any>(null);

  const [indicatorTop, setIndicatorTop] = React.useState(0);
  const isFinish =
    (totalSignatures > 0 && indicatorIndex >= totalSignatures) ||
    (actionedSignatures > 0 && actionedSignatures === totalSignatures);
  const [signatureToAll, setSignatureToAll] = React.useState(false);
  const signedAll =
    actionedSignatures > 0 && actionedSignatures === totalSignatures;
  const [signatureName, setSignatureName] = React.useState('');
  const [showAddSignModal, setShowAddSignModal] = React.useState(false);
  const [containerTop, setContainerTop] = React.useState(0);
  React.useEffect(() => {
    if (signedAll) {
      setTimeout(() => setOpenConfirmation(true), 200);
    }
  }, [signedAll]);

  const annotationData = JSON.parse(annotations)?.annotations;
  const annotationHeights = annotationData
    .filter(
      (annotation: AnnotationsUnion) =>
        annotation.customData?.type === 'SIGNATURE' &&
        annotation.customData?.status === 'PENDING'
      // && annotation.isSignersAnnotation
    )
    .map((annotation: any) => {
      const bbox = annotation.bbox;
      const data = {
        height: bbox[1] + bbox[3],
        id: annotation.id,
        name: annotation.customData?.name,
        pageIndex: annotation.pageIndex,
        bbox: bbox,
      };
      return data;
    })
    .sort((a: any, b: any) => {
      if (a.pageIndex < b.pageIndex) return -1;
      if (a.pageIndex > b.pageIndex) return 1;
      if (a.height < b.height) return -1;
      if (a.height > b.height) return 1;
      return 0;
    });

  React.useEffect(() => {
    const totalSignatures = annotationData.filter(
      (annotation: any) =>
        annotation.customData?.status === 'PENDING' &&
        // annotation.isSignersAnnotation &&
        annotation.customData?.type === 'SIGNATURE'
    ).length;
    setTotalSignatures(totalSignatures);
  }, [annotations]);

  const handleMoveIndicator = async (height: number) => {
    if (isFinish) {
      setOpenConfirmation(true);
    } else {
      console.log(indicatorIndex, annotationHeights);
      const nextIndicator = Math.min(indicatorIndex + 1, totalSignatures);
      const newPageIndex = annotationHeights[indicatorIndex]?.pageIndex ?? 0;

      setIndicatorIndex(nextIndicator);
      await pdfInstanceRef.current.jumpToRect(
        newPageIndex,
        new PSPDFKit.Geometry.Rect({
          left: annotationHeights[indicatorIndex].bbox[0],
          top: annotationHeights[indicatorIndex].bbox[1], // The vertical position you want to scroll to
          width: annotationHeights[indicatorIndex].bbox[2],
          height: annotationHeights[indicatorIndex].bbox[3],
        })
      );
      const newPos =
        await pdfInstanceRef.current.transformContentPageToClientSpace(
          new PSPDFKit.Geometry.Rect({
            left: annotationHeights[indicatorIndex].bbox[0],
            top: annotationHeights[indicatorIndex].bbox[1], // The vertical position you want to scroll to
            width: annotationHeights[indicatorIndex].bbox[2],
            height: annotationHeights[indicatorIndex].bbox[3],
          }),
          newPageIndex
        );
      const indicatorTop = newPos.top - containerTop;
      setIndicatorTop(indicatorTop);
      const id = annotationHeights[indicatorIndex].id;
      const name = annotationHeights[indicatorIndex].name;
      setTimeout(async () => {
        if (id) {
          setShowAddSignModal(true);
          signingAnnotationRef.current = id;
          setSignatureName(name);
        }
      }, 300);
    }
    if (isFinish || indicatorIndex + 1 === totalSignatures) {
      return false;
    } else return true;
  };
  const disableNextButton =
    submitted ||
    (!submitted && isFinish && actionedSignatures !== totalSignatures);

  const getAnnotationData = async () => {
    const allAnnotations = await Promise.all(
      Array.from({ length: pdfInstanceRef.current?.totalPageCount }).map(
        (_, pageIndex) => pdfInstanceRef.current.getAnnotations(pageIndex)
      )
    );

    const flattenedAnnotations = allAnnotations.flat();
    if (pdfInstanceRef.current) {
      const instantJSON = await pdfInstanceRef.current.exportInstantJSON(
        flattenedAnnotations
      );
      return JSON.stringify(instantJSON);
    } else return null;
  };

  const updateAnnotations = async () => {
    try {
      setLoading(true);
      const annotations = await getAnnotationData();
      if (annotations) if (onSave) await onSave(annotations);
      pdfContentRef.current = await pdfInstanceRef?.current?.exportPDF({
        flatten: true,
      });
      setSubmitted(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleDownload = async () => {
    let blobData = pdfContentRef.current;
    if (!blobData) {
      blobData = await pdfInstanceRef?.current?.exportPDF({
        flatten: true,
      });
    }
    const blob = new Blob([blobData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = taskGuest?.reference ?? 'document.pdf';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <PdfScrollContext.Provider
      value={{
        ref: pdfInstanceRef,
        isFinish,
        indicatorIndex,
        completed,
        setContainerTop,
        setIndicatorIndex,
        actionedSignatures,
        setActionedSignatures,
        totalSignatures,
        signingAnnotationRef,
        setTotalSignatures,
        openConfirmation,
        setOpenConfirmation,
        updateAnnotations,
        setSignatureToAll,
        signatureToAll,
        submitted,
        setSubmitted,
        indicatorTop,
        signedSignatures,
        setSignedSignatures,
        disableNextButton,
        fullSigned,
        setIndicatorTop,
        loading,
        handleMoveIndicator,
        signatureName,
        setSignatureName,
        showAddSignModal,
        setShowAddSignModal,
        handleDownload,
      }}
    >
      {children}
    </PdfScrollContext.Provider>
  );
};

export const usePdfScroll = () => {
  const context = React.useContext(PdfScrollContext);
  if (!context) {
    throw new Error('usePdfScroll must be used within a PdfScrollProvider');
  }
  return context;
};
