import { useTheme, styled } from '@mui/material';
import React from 'react';
import { WBContainer, WBFlex } from '@admiin-com/ds-web';
import { PageType, TaskCreateModalProps } from './type';
import { InvoiceCreateFormContainer } from '../InvoiceCreateForm';
import { PayBill } from '../PayBill';
import ESignature from '../ESignature';
import TaskCreateHeader from './Header';
import { CreateTaskStatus, TaskDirection } from '@admiin-com/ds-graphql';
import Finish from '../ESignature/Finish';
import { useFormContext } from 'react-hook-form';
import { t } from 'i18next';
import { Finished } from './Finished';
import { Content } from './Content';
const StyledWBFlex = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  height: '100%',
  backgroundColor: theme.palette.background.default,
}));

export function TaskCreateModal({
  onClose,
  page,
  setPage,
  open,
  isEditing,
  onSignatureCreated,
  taskDirection,
  createdTask,
  selectCreateTask,
}: TaskCreateModalProps) {
  const theme = useTheme();
  return (
    open && (
      <WBFlex
        position="fixed"
        top={0}
        left={0}
        right={0}
        role="dialog"
        bottom={0}
        sx={{ zIndex: 1200 }}
      >
        <StyledWBFlex>
          <TaskCreateHeader
            open={open}
            onClose={onClose}
            isEditing={isEditing}
            setPage={setPage}
            page={page}
          />
          <ContentContainer
            maxWidth="xl"
            sx={{
              height: '100%',
              px: [1, 2],
              py: [1, 3],
              overflow: 'auto',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Content
              page={page}
              setPage={setPage}
              open={open}
              isEditing={isEditing}
              onSignatureCreated={onSignatureCreated}
              taskDirection={taskDirection}
              createdTask={createdTask}
              selectCreateTask={selectCreateTask}
              onClose={onClose}
            />
          </ContentContainer>
        </StyledWBFlex>
      </WBFlex>
    )
  );
}
const ContentContainer = styled(WBContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
  },
}));
export default TaskCreateModal;
