import {
  WBButton,
  WBFlex,
  WBForm,
  WBTypography,
  useMediaQuery,
  useSnackbar,
} from '@admiin-com/ds-web';
import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AddressInput,
  // BankAccountType,
  // BankHolderType,
  Contact,
  ContactBankAccountInput,
  ContactBpayInput,
  CreateContactInput,
  CSGetSelectedEntityId as GET_SELECTED_ENTITY_ID,
  UpdateContactInput,
} from '@admiin-com/ds-graphql';
import { useTheme } from '@mui/material';
import { useCreateContact } from '../../hooks/useCreateContact/useCreateContact';
import { useUpdateContact } from '../../hooks/useUpdateContact/useUpdateContact';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import EntityCreateForm from '../../components/EntityCreateForm/EntityCreateForm';
import ContactBankForm from '../../components/ContactBankForm/ContactBankForm';
import ContactForm from '../../components/ContactForm/ContactForm';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { findFirstErrorField } from '../../helpers/string';
export interface ContactCreateFormData {
  client:
    | CreateContactInput
    | (UpdateContactInput & {
        address: AddressInput;
      });
  entity: { name: string; taxNumber: string; companyNumber: string };
  bank: ContactBankAccountInput;
  bpay: ContactBpayInput;
}

interface ContactDetailFormProps {
  entityId?: string;
  selected?: Contact | null;
  onSubmitted?: (contact: Contact) => void;
  ocrCreate?: boolean;
}

export function ContactsCreateForm({
  selected = null,
  entityId: entityIdProps,
  onSubmitted,
  ocrCreate,
}: ContactDetailFormProps) {
  const { t } = useTranslation();
  const methods = useForm<ContactCreateFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      bank: {
        // holderType: BankHolderType.personal,
        // accountType: BankAccountType.checking,
      },
    },
    values: {
      client: {
        id: selected?.id ?? '',
        firstName: selected?.firstName ?? '',
        lastName: selected?.lastName ?? '',
        mobile: selected?.mobile ?? '',
        phone: selected?.phone ?? '',
        email: selected?.email ?? '',
        address: {
          address1: selected?.address?.address1 ?? '',
          addressId: selected?.address?.addressId ?? '',
          unitNumber: selected?.address?.unitNumber ?? '',
          postalCode: selected?.address?.postalCode ?? '',
          city: selected?.address?.city ?? '',
          state: selected?.address?.state ?? '',
          stateCode: selected?.address?.stateCode ?? '',
          streetName: selected?.address?.streetName ?? '',
          streetType: selected?.address?.streetType ?? '',
          streetNumber: selected?.address?.streetNumber ?? '',
          country: selected?.address?.country ?? '',
        },
        entityId: selected?.entityId ?? '',
      },
      entity: {
        name: selected?.name ?? '',
        taxNumber: selected?.taxNumber ?? '',
        companyNumber: selected?.companyNumber ?? '',
      },
      bank: {
        bankName: selected?.bank?.bankName ?? '',
        accountName: selected?.bank?.accountName ?? '',
        accountNumber: selected?.bank?.accountNumber ?? '',
        routingNumber: selected?.bank?.routingNumber ?? '',
        accountType: selected?.bank?.accountType ?? null,
        holderType: selected?.bank?.holderType ?? null,
      },
      bpay: {
        billerCode: selected?.bpay?.billerCode ?? null,
        referenceNumber: selected?.bpay?.referenceNumber ?? '',
      },
    },
  });
  const { handleSubmit } = methods;

  const [loading, setLoading] = useState(false);

  const [createContact, { error: createError }] = useCreateContact();
  const [updateContact, { error: updateError }] = useUpdateContact();

  const { data: selectedEntityIdData } = useQuery(gql(GET_SELECTED_ENTITY_ID));

  const entityId = entityIdProps ?? selectedEntityIdData?.selectedEntityId;

  const showSnackbar = useSnackbar();
  const onSubmit = async (data: ContactCreateFormData, event: any) => {
    event.stopPropagation();
    setLoading(true);
    try {
      const origialBank: ContactBankAccountInput | undefined | null | any = // TODO: remove any type
        selected?.bank
          ? {
              ...selected.bank,
            }
          : undefined;
      const newBank: ContactBankAccountInput | any = {
        // TODO: remove any type
        ...data.bank,
      };
      const bpay: ContactBpayInput = {
        ...data.bpay,
      };

      const isbankEmpty =
        !data.bank ||
        (data.bank &&
          Object.entries(data.bank).every(([key, value]) => {
            return (
              key === 'accountType' ||
              key === 'holderType' ||
              key === 'bankName' ||
              (key !== 'accountType' &&
                key !== 'holderType' &&
                key !== 'bankName' &&
                (value === '' || value === null))
            );
          }));
      const isbpayEmpty =
        !data.bpay ||
        (data.bpay &&
          Object.values(data.bpay).every(
            (value) => value === '' || value === undefined || value === null
          ));
      const contact: CreateContactInput = {
        email: data.client.email ?? '',
        firstName: data.client.firstName ?? '',
        lastName: data.client.lastName ?? '',
        entityId,
        address: data.client.address?.address1 ? data.client.address : null,
      };
      if (!isDeepEqual(origialBank, newBank) && !isbankEmpty)
        contact.bank = { ...newBank };
      if (!isbpayEmpty) contact.bpay = { ...bpay };
      if (data.client.phone) contact.phone = data.client.phone;
      if (data.client.mobile) contact.mobile = data.client.mobile;
      if (data.entity.name && !selected) {
        contact.name = data.entity.name;
        if (data.entity.taxNumber) {
          contact.taxNumber = data.entity.taxNumber;
        }
        if (data.entity.companyNumber) {
          contact.companyNumber = data.entity.companyNumber;
        }
      }

      if (selected) {
        const updatedContact = await updateContact({
          variables: {
            input: {
              ...contact,
              id: selected.id,
              payoutErrors: null,
            },
          },
        });
        onSubmitted && onSubmitted(updatedContact?.data.updateContact);
        showSnackbar({
          message: t('contactUpdated', { ns: 'contacts' }),
          severity: 'success',
          horizontal: 'right',
          vertical: 'bottom',
        });
      } else {
        const createdContact = await createContact({
          variables: {
            input: { ...contact },
          },
        });
        onSubmitted && onSubmitted(createdContact?.data.createContact);

        showSnackbar({
          message: t('contactCreated', { ns: 'contacts' }),
          severity: 'success',
          horizontal: 'right',
          vertical: 'bottom',
        });
      }

      setLoading(false);
    } catch (err) {
      console.log('error create contact: ', err);
      setLoading(false);
    }
  };
  const theme = useTheme();

  const islg = useMediaQuery(theme.breakpoints.down('lg'));

  const onError = (errorFields: any) => {
    // Helper function to recursively find the first error field

    const firstErrorField = findFirstErrorField(errorFields);

    if (firstErrorField) {
      methods.setFocus(firstErrorField as keyof ContactCreateFormData); // Focus the first error field
      const errorElement = document.getElementsByName(firstErrorField)?.[0];
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' }); // Smoothly scroll to the field
      }
    }
  };

  return (
    <WBFlex flexDirection="column" alignItems="center" mb={4}>
      <FormProvider {...methods}>
        <WBForm
          mt={0}
          onSubmit={(e) => {
            e.stopPropagation(); // Prevent the event from bubbling up to the outer form
            handleSubmit(onSubmit, onError)(e);
          }}
          alignSelf="stretch"
        >
          <WBTypography
            variant={islg ? 'h3' : 'h2'}
            noWrap
            component="div"
            color="dark"
            sx={{ flexGrow: 1, textAlign: 'left' }}
          >
            {t('contactsDetails', { ns: 'contacts' })}
          </WBTypography>
          <ContactForm ocrCreate={ocrCreate} />

          <WBTypography
            variant={islg ? 'h3' : 'h2'}
            noWrap
            mt={5}
            component="div"
            color="dark"
            sx={{ flexGrow: 1, textAlign: 'left' }}
          >
            {t('businessDetails', { ns: 'contacts' })}
          </WBTypography>
          <EntityCreateForm
            noType
            noAddress
            disabled={selected !== null}
            ocrCreate={ocrCreate}
          />
          <ContactBankForm selected={selected} ocrCreate={ocrCreate} />
          <WBButton
            sx={{
              mt: {
                xs: 6,
                sm: 8,
              },
            }}
            loading={loading}
          >
            {t(`${selected ? 'updateContact' : 'createContact'}`, {
              ns: 'contacts',
            })}
          </WBButton>
        </WBForm>
      </FormProvider>
      <ErrorHandler errorMessage={createError?.message} />
      <ErrorHandler errorMessage={updateError?.message} />
    </WBFlex>
  );
}
