import {
  CSGetEntity,
  CSGetRecentClients,
  Entity,
  EntityUserRole,
} from '@admiin-com/ds-graphql';
import { useUserEntities } from '../../hooks/useUserEntities/useUserEntities';
import { WBS3Avatar } from '@admiin-com/ds-amplify-web';
import { FormControl, ListSubheader, styled, useTheme } from '@mui/material';
import {
  WBListItem,
  WBListItemIcon,
  WBMenuItem,
  WBSelect,
  WBTypography,
} from '@admiin-com/ds-web';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useCurrentUser } from '../../hooks/useCurrentUser/useCurrentUser';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../navigation/paths';
import { useEntitySelect } from '../../features/hooks/useEntitySelect/useEntitySelect';
import { useCurrentEntityId } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { useNavigate } from 'react-router-dom';
import { MAX_RECENT_CLIENTS } from '../../constants/config';

export function EntitySelector() {
  const { userEntities, users } = useUserEntities({
    role: EntityUserRole.OWNER,
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const [getEntityQuery, { loading }] = useLazyQuery(gql(CSGetEntity));

  const entityId = useCurrentEntityId();

  const { data: recentClientIdsData } = useQuery(gql(CSGetRecentClients));
  const recentClientIds: string[] = React.useMemo(
    () => recentClientIdsData?.recentClients || [],
    [recentClientIdsData]
  );
  const user = useCurrentUser();
  const hasClients = !!user.firmId;

  const wrongRecentClientIds =
    user.firmId &&
    entityId &&
    entityId !== user.firmId &&
    !recentClientIds.includes(entityId);

  const updatedRecentClientIds = React.useMemo(() => {
    if (wrongRecentClientIds) {
      let updatedRecentClients = [...recentClientIds];

      updatedRecentClients = updatedRecentClients.filter(
        (id) => id !== entityId
      );

      updatedRecentClients.unshift(entityId);

      updatedRecentClients = updatedRecentClients.slice(0, MAX_RECENT_CLIENTS);
      return updatedRecentClients;
    }
    return recentClientIds;
  }, [recentClientIds, entityId, wrongRecentClientIds]);

  const [displayedClientEntities, setDisplayedClientEntities] = React.useState<
    Entity[]
  >([]);

  React.useEffect(() => {
    const fetchEntities = async () => {
      const promise = updatedRecentClientIds.map((clientId) => {
        return getEntityQuery({
          variables: { id: clientId },
        });
      });
      const values = await Promise.all(promise);
      const entities = values
        .map((value) => value.data?.getEntity)
        .filter((entity) => !!entity);
      setDisplayedClientEntities(entities);
    };

    fetchEntities();
  }, [updatedRecentClientIds, getEntityQuery]);

  const normalEntities: Entity[] = React.useMemo(
    () =>
      (users ?? [])
        .filter(
          (user) => user.entity && user.role !== EntityUserRole.ACCOUNTANT
        )
        .map((user) => user.entity as Entity)
        .filter((entity) => !!entity)
        .filter((entity) => entity),
    [users]
  );

  const onEntityChange = useEntitySelect();

  const { t } = useTranslation();

  return userEntities ? (
    <WBListItem sx={{ pr: 0 }}>
      <FormControl
        sx={{
          backgroundColor: theme.palette.common.white,
          height: '70%',
          width: '90%',
          fontSize: theme.typography.body1,
          fontWeight: 'bold',
        }}
      >
        <WBSelect
          sx={{ px: 2 }}
          value={
            userEntities.length > 0
              ? wrongRecentClientIds || loading
                ? ''
                : entityId
              : ''
          }
          SelectProps={{
            renderValue: undefined,
            SelectDisplayProps: {
              style: {
                display: 'flex',
                color: theme.palette.common.black,
                alignItems: 'center',
              },
            },
          }}
          InputProps={{
            disableUnderline: true,
          }}
        >
          {hasClients && (
            <WBListSubheader>
              {t('myEntities', { ns: 'dashboard' })}
            </WBListSubheader>
          )}
          {normalEntities.map((entity) => (
            <WBMenuItem
              value={entity.id}
              key={entity.id}
              onClick={() => onEntityChange(entity.id)}
            >
              <WBListItemIcon>
                <WBS3Avatar
                  sx={{
                    borderRadius: '3px',
                    width: '20px',
                    height: '20px',
                  }}
                  imgKey={entity.logo?.key}
                  fontSize="h4.fontSize"
                  identityId={entity.logo?.identityId}
                  level={entity.logo?.level}
                  companyName={entity.name}
                  key={entity?.id}
                />
              </WBListItemIcon>
              <WBTypography
                variant="body2"
                color="light"
                noWrap
                fontWeight="bold"
              >
                {entity.name}
              </WBTypography>
            </WBMenuItem>
          ))}
          {hasClients && displayedClientEntities?.length > 0 && (
            <WBListSubheader>
              {t('recentClients', { ns: 'dashboard' })}
            </WBListSubheader>
          )}
          {hasClients &&
            displayedClientEntities?.length > 0 &&
            displayedClientEntities.map(
              (entity) =>
                entity && (
                  <WBMenuItem
                    key={entity.id}
                    value={entity.id}
                    onClick={() => onEntityChange(entity.id)}
                  >
                    <WBListItemIcon>
                      <WBS3Avatar
                        sx={{
                          borderRadius: '3px',
                          width: '20px',
                          height: '20px',
                        }}
                        imgKey={entity.logo?.key}
                        fontSize="h4.fontSize"
                        identityId={entity.logo?.identityId}
                        level={entity.logo?.level}
                        companyName={entity.name}
                        key={entity?.id}
                      />
                    </WBListItemIcon>
                    <WBTypography
                      variant="body2"
                      color="light"
                      noWrap
                      fontWeight="bold"
                    >
                      {entity.name}
                    </WBTypography>
                  </WBMenuItem>
                )
            )}
          {hasClients && (
            <WBMenuItem
              value="newClient"
              onClick={() => {
                navigate(PATHS.clients);
              }}
              sx={{
                mt: 2,
                px: 0,
                mx: 2,
                borderTop: `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <WBTypography
                sx={{
                  textDecoration: 'underline',
                }}
                color={'primary'}
                fontWeight={'bold'}
              >
                {t('newClient', { ns: 'dashboard' })}
              </WBTypography>
            </WBMenuItem>
          )}
        </WBSelect>
      </FormControl>
    </WBListItem>
  ) : null;
}

export const EntityItem = ({
  entity,
  onClick,
}: {
  entity: Entity;
  onClick: (id: string) => void;
}) => {
  return (
    <WBMenuItem value={entity.id} onClick={() => onClick(entity.id)}>
      <WBListItemIcon>
        <WBS3Avatar
          sx={{
            borderRadius: '3px',
            width: '20px',
            height: '20px',
          }}
          imgKey={entity.logo?.key}
          fontSize="h4.fontSize"
          identityId={entity.logo?.identityId}
          level={entity.logo?.level}
          companyName={entity.name}
          key={entity?.id}
        />
      </WBListItemIcon>
      <WBTypography variant="body2" color="light" noWrap fontWeight="bold">
        {entity.name}
      </WBTypography>
    </WBMenuItem>
  );
};
export const WBListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: 'inherit',
  color: theme.palette.text.primary,
  ...theme.typography.h5,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  paddingLeft: 0,
  position: 'initial',
}));
