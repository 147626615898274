import {
  createTaskSupportDocumentUrlGuest,
  TaskGuest,
} from '@admiin-com/ds-graphql';
import { TaskSectionLayout } from './TaskSectionLayout';
import { WBFlex, WBLinkButton } from '@admiin-com/ds-web';
import { gql, useMutation } from '@apollo/client';
import { useSignedDocumentUpload } from '../../hooks/useSignedDocumentUpload';

interface TaskSupportDocumentsProps {
  taskGuest: TaskGuest;
  token: string;
}
export const TaskSuportDocumentsGuest = ({
  taskGuest,
  token,
}: TaskSupportDocumentsProps) => {
  const [createTaskSupportDocumentUrlGuestFunc] = useMutation(
    gql(createTaskSupportDocumentUrlGuest)
  );
  const { downloadDocument } = useSignedDocumentUpload();

  const downloadSupportDocument = async (key: string) => {
    const urlData = await createTaskSupportDocumentUrlGuestFunc({
      variables: {
        input: {
          token,
          key,
        },
      },
    });
    const url = urlData.data?.createTaskSupportDocumentUrlGuest?.url;
    await downloadDocument(url);
  };

  if (
    taskGuest.supportDocuments === null ||
    taskGuest.supportDocuments?.length === 0
  )
    return null;

  return (
    <TaskSectionLayout title="Supporting Documents">
      <WBFlex flexDirection="column" gap={1}>
        {taskGuest.supportDocuments?.map((doc, index) => {
          return (
            doc && (
              <WBLinkButton
                key={index}
                sx={{ color: 'primary.main', fontSize: 'body1.fontSize' }}
                onClick={() => {
                  downloadSupportDocument(doc.key);
                }}
              >
                {doc?.key.split('_')[1] ?? 'Document'}
              </WBLinkButton>
            )
          );
        })}
      </WBFlex>
    </TaskSectionLayout>
  );
};
