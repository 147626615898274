import {
  WBAlert,
  WBButton,
  WBFlex,
  WBLink,
  WBTypography,
} from '@admiin-com/ds-web';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  CreateEntityInput,
  CSGetEntity,
  InvoiceStatus,
  PaymentFrequency,
  TaskType,
  updateEntity as UPDATE_ENTITY,
} from '@admiin-com/ds-graphql';
import { styled, useTheme } from '@mui/system';
import { useOnboardingProcess } from '../../../components/OnboardingContainer/OnboadringContainer';
import { useFormContext } from 'react-hook-form';
import { ColorPicker } from '../../../components/ColorPicker/ColorPicker';
import { ColorResult } from 'react-color';
import InvoiceRenderer from '../../../components/InvoiceRenderer/InvoiceRenderer';

const OnboardingBusinessBrand = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [updateEntity, { error: updateError }] = useMutation(
    gql(UPDATE_ENTITY)
  );

  const [loading, setLoading] = useState(false);
  const {
    nextBusiness,
    onboardingEntityId,
    createEntityError,
    isNotOnboarding,
    finishOnboarding,
  } = useOnboardingProcess();

  const [error, setError] = useState<{ message: string }>();

  useEffect(() => {
    if (updateError) {
      setError(updateError);
    }
  }, [updateError]);
  const { setValue, watch } = useFormContext<{ entity: CreateEntityInput }>();

  const onSubmit = async (submit = false) => {
    // otherwise update entity
    if (loading) return;
    setLoading(true);
    try {
      if (submit)
        if (!isNotOnboarding) {
          await updateEntity({
            variables: {
              input: {
                id: onboardingEntityId,
                brandColour: color,
              },
            },
          });
        } else {
          setValue('entity.brandColour', color);
        }
      if (!isNotOnboarding) nextBusiness();
      else await finishOnboarding();
      setLoading(false);
    } catch (err: any) {
      console.log('ERROR updating entity: ', err);
      setError(err);
      setLoading(false);
    }
  };

  const [color, setColor] = useState('#000000');

  const handleChange = (color: ColorResult) => {
    setColor(color.hex);
  };

  const { data } = useQuery(gql(CSGetEntity), {
    variables: {
      id: onboardingEntityId,
    },
    skip: !onboardingEntityId || isNotOnboarding,
  });
  const onboardingEntity = data?.getEntity;

  const entity = !isNotOnboarding ? onboardingEntity : watch('entity');
  console.log('entity', entity);

  return (
    <PageContainer
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        paddingY: 6,
      }}
    >
      <WBTypography variant="h3">
        {t('onboardingBusinessBrandingTitle', { ns: 'onboarding' })}
      </WBTypography>
      <WBTypography mb={[3, 10]} textAlign="center">
        {t('onboardingBusinessBrandingDescription', { ns: 'onboarding' })}
      </WBTypography>

      <Container>
        <WBFlex
          mt={4}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            overflowY: 'scroll',
          }}
        >
          <ColorPicker color={color} onChange={handleChange} />
          <WBTypography variant="body2" color="textSecondary" mt={1}>
            {t('selectBrandColour', { ns: 'onboarding' })}
          </WBTypography>
        </WBFlex>
        <WBFlex
          width={'100%'}
          maxWidth={'450px'}
          mt={[3, 0]}
          sx={{
            overflowY: 'scroll',
          }}
        >
          <InvoiceRenderer
            values={
              {
                from: {
                  ...entity,
                  contact: { email: 'hello@samplecompany.com' },
                  paymentMethods: {
                    items: [
                      {
                        name: 'Sample Company',
                        accountName: 'Sample Company',
                        routingNumber: '012281',
                        accountNumber: '123456789',
                        number: '123456789',
                        id: 'disbursementMethodId',
                      },
                    ],
                  },
                  disbursementMethodId: 'disbursementMethodId',
                },
                to: {
                  name: 'Sample Customer',
                  taxNumber: '987654321',
                  address: {
                    address1: '178 George Street Sydney NSW 2026 Australia',
                  },
                },
                type: TaskType.SIGN_ONLY,
                paymentFrequency: PaymentFrequency.ANNUALLY,
                amount: '0',
                // Add other required properties here
                lineItems: [
                  {
                    description: 'Sample product one',
                    quantity: 1,
                    unitAmount: 3000,
                    taxType: 'GST',
                    lineAmount: 3300,
                  },
                  {
                    description: 'Sample service two',
                    quantity: 2,
                    unitAmount: 125,
                    taxType: 'GST',
                    lineAmount: 275,
                  },
                  {
                    description: 'Sample service three',
                    quantity: 5,
                    unitAmount: 60,
                    taxType: 'GST',
                    lineAmount: 330,
                  },
                ],
                // luxon date string today in iso format
                dueAt: DateTime.now().toISODate(),
                reference: 'INV-002',
                invoiceStatus: InvoiceStatus.INVOICE,
              } as any
            }
            brandColor={color}
          />
        </WBFlex>
      </Container>
      <WBFlex
        flexDirection="column"
        alignItems="center"
        width={{
          xs: '100%',
          sm: '80%',
          md: '60%',
          lg: '40%',
        }}
      >
        <WBButton
          sx={{
            mt: {
              xs: 4,
              sm: 8,
            },
          }}
          onClick={() => onSubmit(true)}
          fullWidth
          loading={loading}
        >
          {t('nextTitle', { ns: 'common' })}
        </WBButton>
        <WBLink
          variant="body2"
          sx={{ mt: 5 }}
          underline="always"
          color={theme.palette.text.primary}
          onClick={() => {
            onSubmit();
          }}
        >
          {t('doThisLater', { ns: 'common' })}
        </WBLink>

        {(error?.message ||
          createEntityError?.message ||
          updateError?.message) && (
          <WBAlert
            title={
              error?.message ??
              createEntityError?.message ??
              updateError?.message
            }
            severity="error"
            sx={{ my: 2 }}
          />
        )}
      </WBFlex>
    </PageContainer>
  );
};

const Container = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row-reverse',
  flex: 1,
  gap: theme.spacing(2),
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export default OnboardingBusinessBrand;
