import React, { useCallback } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { DialogActions, DialogContent, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  WBButton,
  WBFlex,
  WBForm,
  WBIconButton,
  WBLinkButton,
  WBTextField,
  WBTypography,
} from '@admiin-com/ds-web';
import { useCurrentEntityId } from '../../../hooks/useSelectedEntity/useSelectedEntity';
import SignerLookup from '../../AutoCompleteLookup/SignerLookup';
import { REGEX } from '@admiin-com/ds-common';
import {
  Signer,
  useTaskCreationContext,
} from '../../../pages/TaskCreation/TaskCreation';

// Types for props and form data
interface RecipientAddFormProps {
  onSubmit: (data: Signer[]) => void;
  onClose: () => void;
  signers?: Signer[];
}

// Type definition for a single signer field
interface SignerField {
  id?: string;
  name: string;
  email: string;
  signerType: string;
  secondName?: string;
  entityId?: string;
  noLookup?: boolean;
}

const NameField = React.memo(
  ({ index, entityId }: { index: number; entityId: string }) => {
    const { t } = useTranslation();

    const {
      control,
      setValue,
      formState: { errors },
    } = useFormContext<{ signers: SignerField[] }>();

    return (
      <Controller
        control={control}
        name={`signers.${index}`}
        rules={{
          validate: (value: any) => {
            if (!value || !value.name) {
              return t('signerRequired', { ns: 'taskbox' });
            }
            return true;
          },
        }}
        render={({ field }) => (
          <SignerLookup
            {...field}
            label={t('name', { ns: 'taskbox' })}
            placeholder={t('selectName', { ns: 'taskbox' })}
            type={'Signer'}
            entityId={entityId}
            noLookup={field.value?.noLookup}
            error={!!errors?.signers?.[index]}
            tabIndex={0}
            helperText={(errors?.signers?.[index]?.message as string) || ''}
            onChange={(data) => {
              console.log('Chainge', data);
              const newData: SignerField = {
                ...field.value,
                noLookup: data.noLookup ?? field.value?.noLookup,
              };
              if (data.id) {
                newData.id =
                  data.searchType === 'CONTACT' ? data.id : data.userId;
                newData.name = data.name;
                newData.email = data.email ?? '';
                newData.signerType = data.searchType;
                newData.secondName = data.secondName;
              } else {
                newData.id = uuidv4();
                newData.name = data.name ?? '';
                newData.email = data.email ?? '';
                newData.signerType = 'GUEST';
              }
              newData.entityId = data?.entityId ?? entityId;
              console.log(newData);

              setValue(`signers.${index}`, newData, {
                shouldValidate: !newData?.noLookup,
                shouldDirty: true,
              });
            }}
          />
        )}
      />
    );
  }
);

const EmailField = React.memo(
  ({
    index,
    control,
    errors,
  }: {
    index: number;
    control: any;
    errors: any;
  }) => {
    const { t } = useTranslation();
    const value = useWatch({ control, name: `signers.${index}.email` });
    return (
      <Controller
        control={control}
        name={`signers.${index}.email`}
        rules={{
          required: t('emailRequired', { ns: 'common' }),
          pattern: {
            value: REGEX.EMAIL,
            message: t('invalidEmail', { ns: 'common' }),
          },
        }}
        render={({ field }) => (
          <WBTextField
            {...field}
            value={value}
            label={`${t('email', { ns: 'contacts' })}*`}
            placeholder={t('emailPlaceholder', { ns: 'contacts' })}
            error={!!errors?.signers?.[index]?.email}
            helperText={errors?.signers?.[index]?.email?.message || ''}
            margin="dense"
          />
        )}
      />
    );
  }
);

export const RecipientAddForm: React.FC<RecipientAddFormProps> = (props) => {
  const currentEntityId = useCurrentEntityId();
  const { client } = useTaskCreationContext();
  const entityId = client?.id ?? currentEntityId;

  const theme = useTheme();
  const methods = useForm<{ signers: SignerField[] }>({
    values: {
      signers: props.signers ?? [],
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'signers',
  });
  const { t } = useTranslation();

  const onSubmit = useCallback(
    (data: { signers: SignerField[] }) => {
      props.onSubmit(data.signers as any);
    },
    [props]
  );

  const handleAdd = () => {
    if (fields.length < 5)
      append({
        email: '',
        name: '',
        signerType: 'GUEST',
        id: uuidv4(),
      });
  };

  React.useEffect(() => {
    if (fields.length === 0) {
      handleAdd();
    }
  }, [fields]);

  return (
    <FormProvider {...methods}>
      <WBForm onSubmit={handleSubmit(onSubmit)} mt={0}>
        <DialogContent sx={{ minHeight: '300px', px: 0, py: 0, zIndex: 1000 }}>
          {fields.length === 0 ? (
            <WBTypography mb={2} px={3}>
              {t('createRecepientDescription', { ns: 'taskbox' })}
            </WBTypography>
          ) : (
            fields.map((field, index) => (
              <React.Fragment key={field.id}>
                <WBFlex
                  sx={{ display: ['none', 'flex'] }}
                  alignItems={'flex-start'}
                  gap={5}
                >
                  <WBTypography>{index + 1}</WBTypography>
                  <NameField index={index} entityId={entityId} />
                  <EmailField index={index} control={control} errors={errors} />

                  <WBIconButton
                    icon="Close"
                    color={theme.palette.grey[500] as any}
                    onClick={() => remove(index)}
                  />
                </WBFlex>
                <WBFlex
                  my={1}
                  sx={{
                    bgcolor: 'background.paper',
                    display: ['flex', 'none'],
                  }}
                  gap={1}
                  width={'100%'}
                  alignItems={'start'}
                  justifyContent={'space-between'}
                >
                  <WBFlex
                    flex={1}
                    p={2}
                    mx={2}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <NameField index={index} entityId={entityId} />
                    <EmailField
                      index={index}
                      control={control}
                      errors={errors}
                    />
                  </WBFlex>
                </WBFlex>
              </React.Fragment>
            ))
          )}
          {fields.length <= 5 && (
            <WBFlex justifyContent={'end'} mt={3} color={'primary.main'}>
              <WBLinkButton onClick={() => handleAdd()}>
                {t('addRecipient', { ns: 'taskbox' })}
              </WBLinkButton>
            </WBFlex>
          )}
        </DialogContent>

        <DialogActions>
          <WBButton
            type="button"
            variant="outlined"
            sx={{ px: 4 }}
            onClick={props.onClose}
          >
            {t('cancel', { ns: 'taskbox' })}
          </WBButton>
          <WBButton sx={{ px: 4 }} type="submit">
            {t('done', { ns: 'taskbox' })}
          </WBButton>
        </DialogActions>
      </WBForm>
    </FormProvider>
  );
};
