import { useFormContext, useWatch } from 'react-hook-form';
import { CreateTaskInput } from '@admiin-com/ds-graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ESignature from './ESignature';
import PageSelector from '../PageSelector/PageSelector';
import { useUserId } from '../../hooks/useCurrentUser/useCurrentUser';
import { ESignatureConfirm } from './ESignatureConfirm';
import { useTaskCreationContext } from '../../pages/TaskCreation/TaskCreation';
import Onboarding from './Onboarding';
import { useSearchParams } from 'react-router-dom';

interface Props {
  onComplete: (task: CreateTaskInput) => void;
}

const ESignatureContainer = (props: Props) => {
  const [page, setPage] = React.useState<'Upload' | 'Signature' | 'Confirm'>(
    'Upload'
  );

  const { t } = useTranslation();

  const { control } = useFormContext();
  const annotations = useWatch({ control, name: 'annotations' });
  const documents = useWatch({ control, name: 'documents' });

  const documentUrl = documents?.[0]?.src;
  const ref = React.useRef(null);
  const userId = useUserId();
  const {
    submitESignature: handleConfirm,
    handleDraft,
    task,
  } = useTaskCreationContext();

  React.useEffect(() => {
    if (task && page === 'Upload') {
      setPage('Signature');
    }
  }, [task, page]);

  const handleNext = async () => {
    try {
      if (!task) {
        const createdTask = await handleConfirm(false);
        props.onComplete(createdTask);
      } else {
        await handleConfirm(true);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const gotoDraft = async () => {
    try {
      const createdTask = await handleDraft(false);
      props.onComplete(createdTask);
    } catch (e) {
      console.error(e);
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangeStepParams = (value: string) => {
    const params = new URLSearchParams(searchParams);
    params.set('step', value);
    setSearchParams(params);
  };
  React.useEffect(() => {
    handleChangeStepParams(page.toString());
  }, [page]);

  return (
    <PageSelector current={page}>
      <PageSelector.Page value={'Upload'}>
        <Onboarding
          onGetStarted={() => setPage('Signature')}
          title={t('eSignature', { ns: 'taskbox' })}
          description={t('oboardingMessageDescription', { ns: 'taskbox' })}
          buttonTitle={t('uploadADocument', { ns: 'taskbox' })}
        />
      </PageSelector.Page>
      <PageSelector.Page value={'Signature'}>
        {documentUrl && (
          <ESignature
            ref={ref}
            documentUrl={documentUrl}
            userId={userId}
            annotations={annotations}
            handleDraft={async () => {
              setPage('Confirm');
            }}
            handleNext={async () => {
              setPage('Confirm');
            }}
          />
        )}
      </PageSelector.Page>
      <PageSelector.Page value={'Confirm'}>
        <ESignatureConfirm
          ref={ref}
          handleBackToEdit={() => {
            setPage('Signature');
          }}
          handleNext={handleNext}
          handleDraft={gotoDraft}
        />
      </PageSelector.Page>
    </PageSelector>
  );
};
export default ESignatureContainer;
