import { daysDifference } from '@admiin-com/ds-common';
import {
  DocumentAnalysis,
  Entity,
  DocumentAnalysisContact,
  LineItemInput,
  Payment,
  PaymentFrequency,
  PaymentMethodType,
  PaymentStatus,
  PaymentType,
  Task,
  TaskCategory,
  TaskGuest,
  TaskPaymentStatus,
  TaskSettlementStatus,
  TaskSignatureStatus,
  TaskStatus,
  TaskType,
  TaxType,
  Contact,
  InvoiceStatus,
  TaskQuoteStatus,
  SubscriptionTier,
  TaskDirection,
} from '@admiin-com/ds-graphql';
import { FEE_TIERS, GST_RATE, INSTALLMENT_FEE } from '../constants/config';
import { Annotation } from 'pspdfkit';
import { PaymentDetailData } from '../components/PaymentContainer/PaymentContainer';

export function hasInstallmentTask(tasks: Task[]) {
  return tasks.some((task) => isInstallmentTask(task));
}

export function isDeclinedTask(task?: Task | null) {
  const isScheduled = isTaskScheduled(task);
  const payments = task?.payments?.items ?? [];
  return (
    isScheduled &&
    payments?.find(
      (payment: Payment | null) => payment?.status === PaymentStatus.DECLINED
    )
  );
}

export function isPayoutFailedTask(task?: Task | null) {
  const payments = task?.payments?.items ?? [];
  return payments?.find(
    (payment: Payment | null) => payment?.status === PaymentStatus.PAYOUT_FAILED
  );
}
export function getDeclinedDateForScheduled(task?: Task | null) {
  const payments = task?.payments?.items ?? [];

  const isInstallments = isInstallmentTask(task);
  if (!isInstallments && isDeclinedTask(task))
    return payments?.find(
      (payment: Payment | null) => payment?.status === PaymentStatus.DECLINED
    )?.declinedAt;
  else return null;
}

export function getDeclinedReasonForScheduled(task?: Task | null) {
  const payments = task?.payments?.items ?? [];

  const isInstallments = isInstallmentTask(task);
  if (!isInstallments && isDeclinedTask(task))
    return payments?.find(
      (payment: Payment | null) => payment?.status === PaymentStatus.DECLINED
    )?.declinedReason;
  else return null;
}

export interface Attachment {
  binary: string;
  contentType: string;
}

export interface AnnotationDocument {
  annotations: Annotation[];
  attachments: {
    [key: string]: Attachment;
  };
  format: string;
  pdfId: string | null;
}

export const isDocumentSigned = (
  annotations?: AnnotationDocument | null
): boolean => {
  if (!annotations) {
    return true;
  }

  return annotations?.annotations?.every(
    (annotation) =>
      !annotation?.customData?.status ||
      annotation.customData.status === 'ACTIONED'
  );
};

export function isSignedTask(task?: Task | null) {
  return task?.signatureStatus === TaskSignatureStatus.SIGNED;
}

export function isPendingSigantureTask(task: Task, userId?: string) {
  if (task.invoiceStatus === InvoiceStatus.QUOTE) return false;
  if (isSignedTask(task)) return false;
  if (!(isPaidTask(task) || !isPayableTask(task))) return false;
  if (isSignableTask(task, { userId })) return false;
  if (task?.signatureStatus === TaskSignatureStatus.NOT_SIGNABLE) return false;
  return true;
}

export function isSignableTask(
  task: Task | null,
  params?: { userId?: string; signerType?: string; contactId?: string }
) {
  const { userId, signerType, contactId } = params ?? {};
  if (
    !(
      task?.type !== TaskType.PAY_ONLY &&
      task?.status === TaskStatus.INCOMPLETE &&
      task.signatureStatus === TaskSignatureStatus.PENDING_SIGNATURE
    )
  )
    return false;
  if (params) {
    if (
      task.annotations &&
      JSON.parse(task?.annotations)?.annotations?.some(
        (annotation: Annotation) =>
          annotation?.customData?.status &&
          (!userId || (userId && annotation.customData.signerId === userId)) &&
          (!signerType ||
            (signerType && annotation.customData.signerType === signerType)) &&
          (!contactId ||
            (contactId && annotation.customData.contactId === contactId)) &&
          annotation.customData.status === 'PENDING'
      )
    ) {
      return true;
    } else return false;
  }

  return true;
}

export function isPaidTask(task?: Task | null) {
  if (!task) return false;
  return (
    task?.paymentStatus === TaskPaymentStatus.PAID ||
    task?.paymentStatus === TaskPaymentStatus.MARKED_AS_PAID ||
    (task?.invoiceStatus === InvoiceStatus.QUOTE &&
      (task?.quoteStatus === TaskQuoteStatus.ACCEPTED ||
        task?.quoteStatus === TaskQuoteStatus.MARKED_AS_ACCEPTED))
  );
}

export function isPendingTask(task?: Task | null) {
  if (!task) return false;

  return (
    task?.paymentStatus ===
      TaskPaymentStatus.PENDING_PAYTO_AGREEMENT_CREATION ||
    task?.paymentStatus === TaskPaymentStatus.PENDING_PAYID_TRANSFER
  );
}

export function isInstallmentTask(task?: Task | null) {
  if (!task) return false;

  return (
    ((task?.paymentFrequency === PaymentFrequency.WEEKLY ||
      task?.paymentFrequency === PaymentFrequency.FORTNIGHTLY ||
      task?.paymentFrequency === PaymentFrequency.MONTHLY ||
      task?.paymentFrequency === PaymentFrequency.QUARTERLY ||
      task?.paymentFrequency === PaymentFrequency.ANNUALLY) &&
      (task?.numberOfPayments || 0) > 1) ||
    (task?.payments?.items?.length || 0) > 1
  );
}

export function calculateFee(
  tasks: any[],
  paymentType: PaymentMethodType,
  paymentDetails?: PaymentDetailData[],
  tier: SubscriptionTier | undefined = SubscriptionTier.PRO
) {
  const fees: Record<string, number> = {};
  let tierAmount = undefined; // Subscription tier amount
  let totalAmount = 0;
  let feeTotal = 0;
  const payableName =
    tasks.length === 1 ? tasks[0]?.reference ?? 'payable' : 'payable';

  // calculate subtotal task.amount
  let subTotal = 0;
  const selectedType: any = {};

  for (const task of tasks) {
    // TODO: Skip fees for guest if checkbox enabled in entity's setting
    const metaHashParser = task?.metaHashData
      ? JSON.parse(task?.metaHashData)
      : null;
    let absorbFees = metaHashParser ? metaHashParser.absorbFees : false;
    const subscriptionTier: SubscriptionTier =
      metaHashParser && metaHashParser.subscriptionTier
        ? metaHashParser.subscriptionTier
        : tier;

    const selectedPaymentDetails = paymentDetails?.find(
      (detail) => detail && detail?.task?.id === task?.id
    );

    if (task?.id) {
      selectedType[task.id] =
        selectedPaymentDetails?.type || PaymentType.PAY_NOW;
    }

    const isInstallment =
      ((task?.payments?.items?.length || 0) > 1 &&
        task?.paymentTypes.includes(PaymentType.INSTALLMENTS)) ||
      selectedPaymentDetails?.type === PaymentType.INSTALLMENTS;
    const installments = selectedPaymentDetails?.installments || 1;

    const isTax = task?.category === TaskCategory.TAX;
    let amount = getTaskAmount(task);

    if (isInstallment) {
      const equalInstallment = Number(Number(amount / installments).toFixed(2));
      const remainder = (amount % installments) / 100;
      amount = equalInstallment + remainder;
      subTotal += equalInstallment + remainder;
    } else {
      subTotal += getTaskAmount(task) || 0;
    }

    if (!fees[payableName]) {
      fees[payableName] = 0;
    }
    fees[payableName] += amount;
    let paybleAmount = amount;

    if (isInstallment) {
      if (isTax) {
        if (!fees['atoFee']) {
          fees['atoFee'] = 0;
        }
        fees['atoFee'] += FEE_TIERS[subscriptionTier].TAX_PLAN_FEE;
        paybleAmount += FEE_TIERS[subscriptionTier].TAX_PLAN_FEE;
        feeTotal += FEE_TIERS[subscriptionTier].TAX_PLAN_FEE;
      } else {
        absorbFees = true; // TODO: only for isTax we will add processing fees
        // TODO: Double fees added for installment task
        if (!fees['installmentFee']) {
          fees['installmentFee'] = 0;
        }
        fees['installmentFee'] += (amount * INSTALLMENT_FEE) / 100;
        paybleAmount += (amount * INSTALLMENT_FEE) / 100;
        feeTotal += (amount * INSTALLMENT_FEE) / 100;
      }
    }

    if (task?.gstInclusive) {
      if (!fees['gst']) {
        fees['gst'] = 0;
      }
    }
    if (paymentType && !absorbFees) {
      if (paymentType === PaymentMethodType.CARD) {
        if (!fees['admiinFee']) {
          fees['admiinFee'] = 0;
        }
        console.log({ subscriptionTier, tier });
        fees['admiinFee'] += amount * FEE_TIERS[subscriptionTier].CARD;
        paybleAmount += amount * FEE_TIERS[subscriptionTier].CARD;
        feeTotal += amount * FEE_TIERS[subscriptionTier].CARD;
        tierAmount = `${
          Math.round(FEE_TIERS[subscriptionTier].CARD * 10000) / 100
        }%`;
      } else if (
        paymentType === PaymentMethodType.BANK ||
        paymentType === PaymentMethodType.CONNECTED_ACCOUNT_WALLET
      ) {
        if (!fees['admiinFee']) {
          fees['admiinFee'] = 0;
        }
        fees['admiinFee'] += FEE_TIERS[subscriptionTier].BANK;
        paybleAmount += FEE_TIERS[subscriptionTier].BANK;
        feeTotal += FEE_TIERS[subscriptionTier].BANK;
      }
    }
    if (task?.gstInclusive) {
      if (!fees['gst']) {
        fees['gst'] = 0;
      }
      fees['gst'] += paybleAmount / GST_RATE;
      paybleAmount += fees['gst'];
    }
    totalAmount += paybleAmount;
  }

  return { fees, subTotal, totalAmount, feeTotal, tierAmount, selectedType };
}

export function getTaskDueDate(task?: Task | null) {
  //TODO: need to update if tax bill, refundable, payable info is added
  const date =
    task?.settlementStatus === TaskSettlementStatus.REFUNDABLE
      ? task?.lodgementAt
      : task?.signatureStatus === TaskSignatureStatus.PENDING_SIGNATURE &&
        !!task?.lodgementAt
      ? task?.lodgementAt
      : task?.dueAt;
  return task ? daysDifference(date ?? new Date().toString()) : 0;
}

export function hasReOcurringTask(tasks: Task[]) {
  return tasks.some((task) => isReocurringTask(task));
}

export function isReocurringTask(task?: Task | null) {
  return (
    task?.paymentFrequency === PaymentFrequency.WEEKLY ||
    task?.paymentFrequency === PaymentFrequency.FORTNIGHTLY ||
    task?.paymentFrequency === PaymentFrequency.MONTHLY ||
    task?.paymentFrequency === PaymentFrequency.QUARTERLY ||
    task?.paymentFrequency === PaymentFrequency.ANNUALLY
  );
}

export function isPayableTask(task?: Task | null) {
  return (
    (task?.status === TaskStatus.INCOMPLETE ||
      task?.status === TaskStatus.SCHEDULED) &&
    task?.type !== TaskType.SIGN_ONLY &&
    task?.settlementStatus !== TaskSettlementStatus.REFUNDABLE &&
    (task?.paymentStatus === TaskPaymentStatus.PENDING_PAYMENT ||
      task?.paymentStatus === TaskPaymentStatus.PAYOUT_FAILED ||
      task?.paymentStatus === TaskPaymentStatus.SCHEDULED)
  );
}

export function tasksSignPayLabel(tasks: Task[]): TaskType | 'Paid' | 'Signed' {
  if (
    tasks.every(
      (task) => task.status === TaskStatus.COMPLETED && isPaidTask(task)
    )
  )
    return 'Paid';
  if (
    tasks.every(
      (task) => task.status === TaskStatus.COMPLETED && isSignedTask(task)
    )
  )
    return 'Signed';
  let isPayable = false;
  let isSignable = false;
  for (const task of tasks) {
    if (!task) continue;
    if (isPayableTask(task)) isPayable = true;
    if (isSignableTask(task)) isSignable = true;
  }
  if (isPayable && isSignable) return TaskType.SIGN_PAY;
  else if (isPayable && !isSignable) return TaskType.PAY_ONLY;
  else if (!isPayable && isSignable) return TaskType.SIGN_ONLY;

  return TaskType.SIGN_ONLY;
}

export function isTaskScheduled(task?: Task | null) {
  // return task?.paymentStatus === TaskPaymentStatus.SCHEDULED;
  return (
    task?.status === TaskStatus.SCHEDULED ||
    task?.status === TaskStatus.SCHEDULED_RECURRING
  );
}

export function hasTaskScheduled(tasks: Task[]) {
  return tasks.some((task) => isTaskScheduled(task));
}

export interface GetPaymentAmount {
  amount: number;
  paymentType: PaymentType;
  installments?: number;
  isFirstInstallment?: boolean;
  isTaxBill: boolean;
  subscriptionTier: SubscriptionTier;
  paymentMethodType: PaymentMethodType;
}

export const getPlatformFees = (
  amount: number,
  paymentMethodType: PaymentMethodType,
  subscriptionTier: SubscriptionTier
): number => {
  let fee = 0;
  if (paymentMethodType === PaymentMethodType.CARD) {
    fee = amount * FEE_TIERS[subscriptionTier].CARD; //2.2% platform fee
  } else if (
    paymentMethodType === PaymentMethodType.BANK ||
    paymentMethodType === PaymentMethodType.CONNECTED_ACCOUNT_WALLET
  ) {
    fee = FEE_TIERS[subscriptionTier].BANK; // 90 cents platform fee
  }
  return fee;
};

export const getTaskPaymentAmount = ({
  amount,
  paymentType,
  installments = 1,
  isFirstInstallment = false,
  isTaxBill,
  subscriptionTier,
  paymentMethodType,
}: GetPaymentAmount) => {
  let fee = getPlatformFees(amount, paymentMethodType, subscriptionTier);
  let totalAmount = amount + fee;

  if (paymentType === PaymentType.INSTALLMENTS && installments > 1) {
    // Calculate the equal installment amount by rounding down the division result
    totalAmount = amount;
    let equalInstallment = Number(
      Number(totalAmount / installments).toFixed(2)
    );
    const remainder = (totalAmount % installments) / 100;

    if (isFirstInstallment) {
      equalInstallment = equalInstallment + remainder;
    }

    if (isTaxBill) {
      fee = getPlatformFees(
        equalInstallment,
        paymentMethodType,
        subscriptionTier
      );
      return equalInstallment + fee + FEE_TIERS['PRO'].TAX_PLAN_FEE;
    } else {
      return equalInstallment + (equalInstallment * INSTALLMENT_FEE) / 100; // 5% fees
    }
  }

  return Math.round(totalAmount);
};

// TODO: Need to add subscriptionTier admiinfees based on that
// export const getTaskPaymentAmountWithFee = ({
//   amount,
//   paymentType,
//   installments = 1,
//   isFirstInstallment = false,
//   isTaxBill,
// }: GetPaymentAmount) => {
//   let totalAmount = amount;
//   if (
//     paymentType === PaymentType.INSTALLMENTS &&
//     !isTaxBill &&
//     installments > 1
//   ) {
//     totalAmount = totalAmount + totalAmount * 1.024; //2.4% to seller
//   }

//   if (paymentType === PaymentType.INSTALLMENTS && installments > 1) {
//     // Calculate the equal installment amount by rounding down the division result
//     const equalInstallment = Math.floor(amount / installments);
//     console.log('equalInstallment: ', equalInstallment);
//     const remainder = amount % installments;
//     console.log('remainder: ', equalInstallment);

//     if (isFirstInstallment) {
//       return Math.round((equalInstallment + remainder) * 1.022);
//     } else {
//       return Math.round(equalInstallment * 1.022);
//     }
//   }

//   return Math.round(totalAmount * 1.022);
// };

export function addFee({
  amount,
  paymentType,
  paymentMethodType,
  isTax,
  subscriptionTier,
}: {
  amount: number;
  paymentType: PaymentType;
  paymentMethodType: PaymentMethodType;
  isTax: boolean;
  subscriptionTier: SubscriptionTier;
}) {
  const fees: Record<string, number> = {};
  const payableName = 'payable';

  const isInstallment = paymentType === PaymentType.INSTALLMENTS;

  if (!fees[payableName]) {
    fees[payableName] = 0;
  }
  fees[payableName] += amount;
  let paybleAmount = amount;

  if (paymentMethodType) {
    if (paymentMethodType === PaymentMethodType.CARD) {
      if (!fees['admiinFee']) {
        fees['admiinFee'] = 0;
      }
      fees['admiinFee'] += amount * FEE_TIERS[subscriptionTier].CARD;
    } else if (
      paymentMethodType === PaymentMethodType.BANK ||
      paymentMethodType === PaymentMethodType.CONNECTED_ACCOUNT_WALLET
    ) {
      if (!fees['admiinFee']) {
        fees['admiinFee'] = 0;
      }
      fees['admiinFee'] += FEE_TIERS[subscriptionTier].BANK * 100; // in cents
    }
    paybleAmount += fees['admiinFee'];
  }

  return Math.floor(paybleAmount);
}

export const getAmountFromLineItems = (rows: LineItemInput[]) => {
  const subTotal = rows.reduce(
    (acc, row) => acc + (row?.unitAmount ?? 0) * (row.quantity ?? 0),
    0
  );

  const totalGST = rows.reduce(
    (acc, row) =>
      acc +
      (row?.unitAmount ?? 0) *
        (row.quantity ?? 0) *
        (row.taxType === TaxType.GST ? 0.1 : 0),
    0
  );
  //const totalGST = rows.reduce(
  //  (acc, row) =>
  //    acc +
  //    ((row?.unitAmount ?? 0) *
  //      (row.quantity ?? 0) *
  //      (row.taxType === TaxType.GST ? 0.1 : 0)) /
  //      100,
  //  0
  //);
  const total = subTotal + totalGST;
  return {
    subTotal,
    totalGST,
    total,
  };
};

export const getDueDateFromTask = (task: Task) => {
  let dueAt: string | undefined | null = task?.dueAt;
  const isTax = false;
  if (isTax) {
    dueAt = task?.paymentAt;
  } else if (task?.paymentFrequency !== PaymentFrequency.ONCE) {
    if (task && 'firstPaymentAt' in task)
      dueAt = task?.firstPaymentAt as string;
  }
  return dueAt;
};

export const getDocumentName = (task?: Task | TaskGuest | null) => {
  return task?.reference || 'document.pdf';
};

export const getVendorName = (documentAnalysis?: DocumentAnalysis) => {
  if (!documentAnalysis) return '';
  if (documentAnalysis.matchedEntity) {
    return documentAnalysis.matchedEntity.legalName;
  }
  if (documentAnalysis.matchedContact) {
    return (
      documentAnalysis.matchedContact.firstName +
      ' ' +
      documentAnalysis.matchedContact.lastName
    );
  }
  if (documentAnalysis.newContact) {
    return documentAnalysis.newContact.name;
  }
  return '';
};

export const getFromName = (
  from?: Contact | Entity | null | DocumentAnalysisContact
) => {
  if (!from) return '';
  if ('legalName' in from && from.legalName) {
    return from.legalName;
  }
  if ('name' in from && from.name) {
    return from.name;
  }
  if ('label' in from && from.label && typeof from.label === 'string') {
    return from.label;
  }
  if (
    'firstName' in from &&
    from.firstName &&
    'lastName' in from &&
    from.lastName
  ) {
    return from.firstName + ' ' + from.lastName;
  }
  return '';
};

export const isBillLoading = (documentAnalysis?: DocumentAnalysis) => {
  return (
    documentAnalysis?.queryStatus !== 'SCANNED' &&
    documentAnalysis?.expenseStatus !== 'SCANNED'
  );
};

export const getTaskDirection = (task: Task, currentEntityId: string) => {
  if (currentEntityId === task.toId) return TaskDirection.RECEIVING;
  if (currentEntityId === task.fromId) return TaskDirection.SENDING;
};

export function getTaskAmount(task?: Task | null) {
  const payments = task?.payments?.items ?? [];
  const remainedAmount =
    payments
      ?.filter((payment) => payment?.status !== PaymentStatus.SUCCEEDED)
      .reduce(
        (sum, payment) =>
          sum + (payment && payment.netAmount ? payment.netAmount / 100 : 0),
        0
      ) ?? 0;
  return task?.paymentStatus !== TaskPaymentStatus.PAID &&
    payments?.length > 0 &&
    !task?.isUnlimitedRecurring
    ? remainedAmount
    : (task?.futureLinkedTask
        ? (task?.futureLinkedTask?.amount ?? 0) / 100
        : task?.amount) ?? 0;
}

export function getDefaultGST(from: Entity) {
  const isTax = (from as any)?.metadata?.subCategory === 'TAX';
  const defaultGST = isTax
    ? TaxType.NO_GST
    : from?.gstRegistered
    ? TaxType.GST
    : TaxType.NO_GST;
  return defaultGST;
}
