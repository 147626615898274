import { IconButton, styled, Toolbar } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import HamburgetIcon from 'apps/react-app/src/assets/icons/hamburger.svg';
import LoadSvgIcon from 'apps/react-app/src/app/component/LoadSvgIcon/LoadSvgIcon';
import { TopBarProps } from '.';
import RightItems from './RightItems';
import { CreateTaskButton } from '../CreateTaskButton';

export const TopBarMobile = (props: TopBarProps) => {
  return (
    <AppBar position="fixed">
      <Toolbar sx={{ height: '80px' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.onChange}
          edge="start"
        >
          <LoadSvgIcon component={HamburgetIcon} width={18} height={16} />
          {/* <WBIcon name="Menu" /> */}
        </IconButton>
        <CreateTaskButton />
        <RightItems />
      </Toolbar>
    </AppBar>
  );
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));
