import {
  CSGetRecentClients,
  CSGetSelectedEntityId as GET_SELECTED_ENTITY_ID,
  recentClientsInVar,
  selectedEntityIdInVar,
} from '@admiin-com/ds-graphql';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { MAX_RECENT_CLIENTS } from '../../../constants/config';
import { CSGetEntity as GET_ENTITY } from '@admiin-com/ds-graphql';
import { useCurrentUser } from '../../../hooks/useCurrentUser/useCurrentUser';

export function useEntitySelect() {
  // const { userEntities, users } = useUserEntities();
  const { data: selectedEntityIdData } = useQuery(gql(GET_SELECTED_ENTITY_ID));
  const entityId = selectedEntityIdData?.selectedEntityId || '';

  const { data: recentClientIdsData } = useQuery(gql(CSGetRecentClients));
  const recentClientIds: string[] = useMemo(
    () => recentClientIdsData?.recentClients || [],
    [recentClientIdsData]
  );

  const { error } = useQuery(gql(GET_ENTITY), {
    variables: { id: entityId },
    skip: !entityId,
  });

  const user = useCurrentUser();

  useEffect(() => {
    if (
      !recentClientIds.includes(entityId) &&
      entityId &&
      user.firmId &&
      user.firmId !== entityId
    )
      updateRecentClients(entityId);
  }, [recentClientIds, entityId, user.firmId]);

  useEffect(() => {
    if (!entityId || error) changeEntityId(user?.onboardingEntity ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, error]);

  const changeEntityId = async (selectedId: string) => {
    selectedEntityIdInVar(selectedId);
    localStorage.setItem('selectedEntityId', selectedId);

    const isClient = user.firmId && user.firmId !== selectedId;
    if (isClient) {
      updateRecentClients(selectedId);
    }
  };

  const updateRecentClients = (selectedEntityId: string) => {
    let updatedRecentClients = [...recentClientIds];

    updatedRecentClients = updatedRecentClients.filter(
      (id) => id !== selectedEntityId
    );

    updatedRecentClients.unshift(selectedEntityId);

    updatedRecentClients = updatedRecentClients.slice(0, MAX_RECENT_CLIENTS);
    recentClientsInVar(updatedRecentClients);
    localStorage.setItem('recentClients', JSON.stringify(updatedRecentClients));
  };

  return changeEntityId;
}
