import { PaymentFrequency, Task } from '@admiin-com/ds-graphql';
import { Bill } from './PayBillCreateForm';
import { transformLineItemToDocumnetAnalysisInput } from './useBillCreate/transformer';
import { useTaskToName } from '../../hooks/useTaskToName/useTaskToName';
import { DateTime } from 'luxon';
import { DATE_FORMATS } from '@admiin-com/ds-common';

export const useConvertTaskToBill = () => {
  const getTaskName = useTaskToName();
  const convertTaskToBill = async (task: Task): Promise<Bill> => {
    const lineItems = (task.lineItems ?? []).map(
      transformLineItemToDocumnetAnalysisInput
    );
    const { data: to } = await getTaskName(task?.toId, true);
    const { data: from } = await getTaskName(task?.fromId, true);

    return {
      from: from,
      to: to,
      amount: task.amount ?? 0,
      dueAt: task.dueAt ?? '',
      issuedAt: DateTime.now().toFormat(DATE_FORMATS.BACKEND_DATE),
      lineItems: lineItems,
      reference: task.reference ?? '',

      paymentFrequency: task.paymentFrequency ?? PaymentFrequency.ONCE,
      checked: true,
      document: task.documents?.[0] ?? null,
    };
  };

  return convertTaskToBill;
};
