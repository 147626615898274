import { gql, useMutation } from '@apollo/client';
import React from 'react';
import {
  createTaskDocumentUrl as CREATE_TASK_DOCUMENT_URL,
  createTaskDocumentUrlGuest as CREATE_TASK_DOCUMENT_URL_GUEST,
  Task,
  TaskGuest,
  TaskStatus,
} from '@admiin-com/ds-graphql';
import { useSnackbar } from '@admiin-com/ds-web';

const POLLING_INTERVAL = 1000; // 10 seconds
const MAX_POLLING_ATTEMPTS = 15; // Optional: limit maximum polling attempts
// Add this type for better error handling
type PollingError = Error & { code?: string };

const checkUrlStatus = async (url: string): Promise<boolean> => {
  try {
    // Try HEAD request first (faster, but might fail due to CORS)
    try {
      // If HEAD fails, try GET request with range header (minimizes data transfer)
      const getResponse = await fetch(url, {
        method: 'GET',
        headers: {
          Range: 'bytes=0-0', // Request only first byte
        },
      });

      // Check specific status codes
      if (getResponse.status === 404) {
        return false;
      }

      if (getResponse.status === 403) {
        // If forbidden, the URL might be expired
        throw new Error('URL_EXPIRED');
      }

      return getResponse.ok;
    } catch (headError) {
      // If HEAD fails, try GET request with range header (minimizes data transfer)
      const getResponse = await fetch(url, {
        method: 'GET',
        headers: {
          Range: 'bytes=0-0', // Request only first byte
        },
      });

      // Check specific status codes
      if (getResponse.status === 404) {
        return false;
      }

      if (getResponse.status === 403) {
        // If forbidden, the URL might be expired
        throw new Error('URL_EXPIRED');
      }

      return getResponse.ok;
    }
  } catch (error) {
    console.error('Error checking URL status:', error);
    throw error;
  }
};

export const useDocumentUrl = (task?: Task | TaskGuest | null) => {
  const [createTaskDocumentUrl] = useMutation(gql(CREATE_TASK_DOCUMENT_URL));
  const [documentUrl, setDocumentUrl] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const showSnackbar = useSnackbar();

  const fetchAndValidateUrl = React.useCallback(
    async (attemptCount = 1): Promise<string> => {
      try {
        const documentUrlData = await createTaskDocumentUrl({
          variables: {
            input: {
              taskId: task?.id,
              entityId: task?.entityId,
            },
          },
        });

        const url = documentUrlData?.data?.createTaskDocumentUrl?.url;

        if (!url) {
          throw new Error('No URL returned from server');
        }
        // Validate S3 pre-signed URL format
        if (!url.includes('X-Amz-Signature')) {
          throw new Error('Invalid S3 URL format');
        }

        // Check if the URL is accessible
        const isUrlValid = await checkUrlStatus(url);

        if (!isUrlValid) {
          if (attemptCount >= MAX_POLLING_ATTEMPTS) {
            const error = new Error(
              'Document not found after maximum attempts'
            ) as PollingError;
            error.code = 'MAX_POLLING_REACHED';
            throw error;
          }

          // Wait before retrying
          await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL));
          return fetchAndValidateUrl(attemptCount + 1);
        }

        return url;
      } catch (err) {
        if ((err as Error).message === 'URL_EXPIRED') {
          // If URL expired, try getting a new one
          if (attemptCount < MAX_POLLING_ATTEMPTS) {
            await new Promise((resolve) =>
              setTimeout(resolve, POLLING_INTERVAL)
            );
            return fetchAndValidateUrl(attemptCount + 1);
          }
        }
        throw err;
      }
    },
    [createTaskDocumentUrl, task?.id, task?.entityId]
  );

  React.useEffect(() => {
    const getDocumentUrl = async () => {
      if (!task?.documents?.[0]?.key) {
        setDocumentUrl('');
        setLoading(false);
        setError(null);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        setDocumentUrl('');

        const url = await fetchAndValidateUrl();
        setDocumentUrl(url);
      } catch (err) {
        console.error('ERROR CREATING DOCUMENT URL', err);
        if ((err as PollingError).code === 'MAX_POLLING_REACHED') {
          setError(null);
        } else {
          setError('Failed to load document URL');
        }
      } finally {
        setLoading(false);
      }
    };

    if (task?.status !== TaskStatus.DRAFT) {
      getDocumentUrl();
    }
  }, [fetchAndValidateUrl, task?.documents, task?.status]);

  // Show error in snackbar if there is one
  React.useEffect(() => {
    if (error) {
      showSnackbar({
        message: error,
        severity: 'error',
        horizontal: 'center',
        vertical: 'bottom',
      });
    }
  }, [error, showSnackbar]);

  return { documentUrl, loading, error };
};

export const useDocumentGuestUrl = (token?: string | null) => {
  const [createTaskDocumentUrlGuest] = useMutation(
    gql(CREATE_TASK_DOCUMENT_URL_GUEST)
  );
  const [documentUrl, setDocumentUrl] = React.useState<string>('');
  const fetchDocumentUrl = React.useCallback(async () => {
    if (token) {
      try {
        const documentUrlData = await createTaskDocumentUrlGuest({
          variables: {
            input: {
              token,
            },
          },
        });
        setDocumentUrl(documentUrlData?.data?.createTaskDocumentUrlGuest?.url);
      } catch (err) {
        console.log('ERROR CREATING DOCUMENT URL', err);
      }
    } else setDocumentUrl('');
  }, [createTaskDocumentUrlGuest, token]);

  React.useEffect(() => {
    fetchDocumentUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const showSnackbar = useSnackbar();

  const fetchAndValidateUrl = React.useCallback(
    async (attemptCount = 1): Promise<string> => {
      try {
        const documentUrlData = await createTaskDocumentUrlGuest({
          variables: {
            input: {
              token,
            },
          },
        });
        const url = documentUrlData?.data?.createTaskDocumentUrlGuest?.url;

        if (!url) {
          throw new Error('No URL returned from server');
        }
        // Validate S3 pre-signed URL format
        if (!url.includes('X-Amz-Signature')) {
          throw new Error('Invalid S3 URL format');
        }

        // Check if the URL is accessible
        const isUrlValid = await checkUrlStatus(url);

        if (!isUrlValid) {
          if (attemptCount >= MAX_POLLING_ATTEMPTS) {
            const error = new Error(
              'Document not found after maximum attempts'
            ) as PollingError;
            error.code = 'MAX_POLLING_REACHED';
            throw error;
          }

          // Wait before retrying
          await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL));
          return fetchAndValidateUrl(attemptCount + 1);
        }

        return url;
      } catch (err) {
        if ((err as Error).message === 'URL_EXPIRED') {
          // If URL expired, try getting a new one
          if (attemptCount < MAX_POLLING_ATTEMPTS) {
            await new Promise((resolve) =>
              setTimeout(resolve, POLLING_INTERVAL)
            );
            return fetchAndValidateUrl(attemptCount + 1);
          }
        }
        throw err;
      }
    },
    [createTaskDocumentUrlGuest, token]
  );

  const getDocumentUrl = async () => {
    if (!token) {
      setDocumentUrl('');
      setLoading(false);
      setError(null);
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setDocumentUrl('');

      const url = await fetchAndValidateUrl();
      setDocumentUrl(url);
    } catch (err) {
      console.error('ERROR CREATING DOCUMENT URL', err);
      if ((err as PollingError).code === 'MAX_POLLING_REACHED') {
        setError(null);
      } else {
        setError('Failed to load document URL');
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getDocumentUrl();
  }, [fetchAndValidateUrl, token]);

  // Show error in snackbar if there is one
  React.useEffect(() => {
    if (error) {
      showSnackbar({
        message: error,
        severity: 'error',
        horizontal: 'center',
        vertical: 'bottom',
      });
    }
  }, [error, showSnackbar]);

  return { documentUrl, loading, error, refetchUrl: fetchAndValidateUrl };
};
