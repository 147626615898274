import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRowModesModel,
  GridSlotsComponentsProps,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { DocumentAnalysisLineItemInput, TaxType } from '@admiin-com/ds-graphql';
import {
  WBFlex,
  WBIconButton,
  WBLinkButton,
  WBTypography,
} from '@admiin-com/ds-web';
import { useTheme } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import { GridRowsProp } from '@mui/x-data-grid';
import { deepEqual, numberToCurrency } from '@admiin-com/ds-common';
import { useSingleClickTableParams } from '../../InvoiceLineItems/useSingleClickTableParams';

interface Props {
  setRows: (rows: DocumentAnalysisLineItemInput[]) => void;
  rows: DocumentAnalysisLineItemInput[];
  defaultGST?: TaxType;
}

export default function DocumentAnalysisLineItemInputsTable({
  defaultGST = TaxType.NO_GST,
  ...props
}: Props) {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const columns: GridColDef<(typeof initialRows)[number]>[] = [
    {
      field: 'id',
      headerName: '#',
      flex: 0.1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'description',
      headerName: t('description', { ns: 'taskbox' }),
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
    },
    {
      field: 'qty',
      headerName: t('qty', { ns: 'taskbox' }),
      type: 'number',
      sortable: false,
      flex: 0.1,
      disableColumnMenu: true,
      editable: true,
    },
    {
      field: 'price',
      headerName: t('price', { ns: 'taskbox' }),
      type: 'number',
      flex: 0.3,
      editable: true,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      field: 'taxType',
      headerName: t('GST', { ns: 'taskbox' }),
      sortable: false,
      type: 'singleSelect',
      editable: true,
      align: 'right',
      headerAlign: 'right',
      flex: 0.2,
      disableColumnMenu: true,
      valueOptions: Object.values(TaxType).map((value) => ({
        value,
        label: t(value, { ns: 'common' }),
      })),
    },
    {
      field: 'amount',
      headerName: t('amount', { ns: 'taskbox' }),
      sortable: false,
      editable: true,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      flex: 0.3,
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      flex: 0.2,
      sortable: false,
      renderCell: (params) => (
        <WBIconButton
          onClick={() => handleDelete(params.id as number)}
          icon="Trash"
          size="small"
          sx={{ display: 'none' }}
          className="delete-icon"
        />
      ),
    },
  ];
  const handleDelete = (id: number) => {
    const newRows = rows.filter((row) => row.id !== id);
    updatePropsRows(newRows);
  };
  const initialRows: GridRowsProp = [
    { id: 1, description: '', qty: 1, price: 0, taxType: defaultGST },
  ];
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const [rows, setRows] = React.useState(initialRows);
  const processRowUpdate = (newRow: GridRowModel) => {
    const existingRow = rows.find((row) => row.id === newRow.id);
    if (!existingRow) return newRow;

    let { price, qty, amount } = newRow;
    const { taxType } = newRow;

    qty = qty <= 0 ? 1 : qty;

    if (newRow.price !== existingRow.price || newRow.qty !== existingRow.qty) {
      amount = price * qty * (taxType === TaxType.GST ? 1.1 : 1);
    } else if (newRow.amount !== existingRow.amount) {
      price =
        Math.round((amount / qty / (taxType === TaxType.GST ? 1.1 : 1)) * 100) /
        100;
    }

    const updatedRow = { ...newRow, price, qty, amount, isNew: false };
    const newRows = rows.map((row) =>
      row.id === newRow.id ? updatedRow : row
    );

    setRows(newRows);
    updatePropsRows(newRows);

    return updatedRow;
  };

  const handleAddNew = () => {
    const newRow = {
      id: rows.length + 1,
      description: '',
      qty: 1,
      price: 0,
      amount: 0,
      taxType: defaultGST,
    };
    const newRows = rows.concat(newRow);
    updatePropsRows(newRows);

    apiRef.current.setCellFocus(newRow.id, 'description');
  };

  const updatePropsRows = (rows: GridRowModel[]) => {
    props.setRows(
      rows.map((row) => ({
        description: row.description,
        quantity: row.qty,
        unitPrice: row.price,
        price: row.price * row.qty * (row.taxType === TaxType.GST ? 1.1 : 1),
        taxType: row.taxType ?? defaultGST,
      }))
    );
  };

  React.useEffect(() => {
    const newRows = props.rows.map((row, index) => ({
      description: row.description,
      price: row.unitPrice,
      amount: row.price,
      taxType: row.taxType ?? defaultGST,
      qty: row.quantity,
      id: index + 1,
    }));
    if (newRows.length > 0) {
      if (!deepEqual(newRows, rows)) {
        setRows(newRows);
      }
    } else {
      setRows([]);
    }
  }, [props.rows]);

  const theme = useTheme();
  const singleClickTableParams = useSingleClickTableParams();
  return (
    <DataGrid
      {...singleClickTableParams}
      apiRef={apiRef}
      sx={{
        borderWidth: 0,
        '.MuiDataGrid-row:hover .delete-icon': {
          display: 'inline-flex',
        },
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: theme.palette.background.default,
        },
      }}
      rows={rows}
      // experimentalFeatures={{ : true }}
      columns={columns}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      // initialState={{
      //   pagination: {
      //     paginationModel: {
      //       pageSize: 10,
      //     },
      //   },
      // }}
      processRowUpdate={processRowUpdate}
      slots={{
        footer: AddNewButton,
      }}
      slotProps={{
        footer: {
          onAddNew: handleAddNew,
        },
      }}
      onCellKeyDown={(params, event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevents default Enter behavior
          // apiRef.current.stopEditing(); // Stops editing and saves changes
        }
      }}
      disableRowSelectionOnClick
      showCellVerticalBorder={false}
    />
  );
}

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    onAddNew: () => void;
  }
}

function AddNewButton(props: NonNullable<GridSlotsComponentsProps['footer']>) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <WBFlex mb={4} mt={2} ml={2}>
      <WBLinkButton
        onClick={props.onAddNew}
        color="primary.main"
        sx={{ ...theme.typography.button }}
      >
        {t('addNewItem', { ns: 'taskbox' })}
      </WBLinkButton>
    </WBFlex>
  );
}
