import { t } from 'i18next';
import OnboardingMessage from '../InvoiceCreateForm/OnboardingMessage';

export const SignedBox = (props: {
  downloadDocument: () => Promise<void>;
  disabledDownload?: boolean;
  onRedirect?: () => void;
}) => {
  return (
    <OnboardingMessage
      lottiePath={'/tick.lottie'}
      title={t('documentSigned', { ns: 'taskbox' })}
      description={
        <>
          <span>
            {t('documentSignedDescription', {
              ns: 'taskbox',
            })}
          </span>{' '}
          <br />
          <span>
            {props.onRedirect
              ? `${t('Redirecting you to payment...', { ns: 'taskbox' })}`
              : ''}
          </span>
        </>
      }
      loading={props.disabledDownload}
      onGetStarted={() => {
        props.downloadDocument();
      }}
      buttonTitle={t('downloadDocument', { ns: 'taskbox' })}
      onClose={props.onRedirect ? props.onRedirect : undefined}
      closeButtonText={t('Go to invoice', { ns: 'payments' })}
    />
  );
};
