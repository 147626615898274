import { Service, TaxType } from '@admiin-com/ds-graphql';
import { WBSelect, WBTextField } from '@admiin-com/ds-web';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import ServicesLookup from '../ServicesLookup';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';

export default function LineItemsCreateForm(props: { defaultGST: TaxType }) {
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();
  const { t } = useTranslation();

  const { from } = watch();

  const preFillFormFields = (service: Service) => {
    setValue('unitAmount', Math.round(service.amount / 100).toFixed(2));
    setValue('taxType', service.taxType);
    setValue('lineAmount', Math.round(service.amount / 100).toFixed(2));
  };

  const [quantity, unitAmount, taxType] = useWatch({
    control,
    name: ['quantity', 'unitAmount', 'taxType', 'lineAmount'],
    defaultValue: { quantity: 1, unitAmount: 0, taxType: '' },
  });

  const changeAmountByQuantity = (quantity: number) => {
    const lnAmount =
      quantity * unitAmount * (taxType === TaxType.GST ? 1.1 : 1);
    setValue('lineAmount', lnAmount ? Math.round(lnAmount * 100) / 100 : 0);
  };

  const changeAmountByUnitAmount = (unitAmount: number) => {
    const lnAmount =
      quantity * unitAmount * (taxType === TaxType.GST ? 1.1 : 1);
    setValue('lineAmount', lnAmount ? Math.round(lnAmount * 100) / 100 : 0);
  };

  const changeAmountByTaxType = (taxType: TaxType) => {
    const lnAmount =
      quantity * unitAmount * (taxType === TaxType.GST ? 1.1 : 1);
    setValue('lineAmount', lnAmount ? Math.round(lnAmount * 100) / 100 : 0);
  };

  const changePriceByLineAmount = (lineAmount: number) => {
    const newPrice =
      lineAmount / (taxType === TaxType.GST ? 1.1 : 1) / quantity;
    setValue('unitAmount', newPrice ? Math.round(newPrice * 100) / 100 : 0);
  };

  return (
    <>
      <Controller
        name="description"
        control={control}
        defaultValue={''}
        rules={{
          validate: (value) => {
            if (!value || !value?.description)
              return t('descriptionRequired', { ns: 'taskbox' });
            return true;
          },
        }}
        render={({ field }) => (
          <ServicesLookup
            entityId={from?.id}
            isFormField
            {...field}
            onChange={(value) => {
              field.onChange(value);
              if (value.id) {
                preFillFormFields(value);
              }
            }}
            helperText={(errors[field.name]?.message as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />
      <Controller
        name="quantity"
        defaultValue={1}
        control={control}
        render={({ field }) => (
          <WBTextField
            {...field}
            onChange={(e) => {
              field.onChange(e);
              changeAmountByQuantity(Number(e.target.value));
            }}
            label={t('qty', { ns: 'taskbox' })}
            type="number"
            helperText={(errors[field.name]?.message as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />
      <Controller
        name="unitAmount"
        defaultValue={0}
        control={control}
        render={({ field }) => (
          <WBTextField
            {...field}
            onChange={(e) => {
              field.onChange(e);
              changeAmountByUnitAmount(Number(e.target.value));
            }}
            label={t('price', { ns: 'taskbox' })}
            type="number"
            leftIcon={<InputAdornment position="start">$</InputAdornment>}
            helperText={(errors[field.name]?.message as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />

      <Controller
        name="taxType"
        defaultValue={props.defaultGST ?? ''}
        control={control}
        rules={{ required: t('gstRequired', { ns: 'taskbox' }) }}
        render={({ field }) => (
          <WBSelect
            {...field}
            onChange={(e) => {
              field.onChange(e);
              changeAmountByTaxType(e.target.value as TaxType);
            }}
            placeholder={t('selectGST', { ns: 'taskbox' })}
            label={t('gst', { ns: 'taskbox' })}
            options={Object.keys(TaxType)
              .map((key) => ({
                value: key,
                label: t(key, { ns: 'taskbox' }),
              }))
              .filter((item) => item.value !== TaxType.GST_INCLUSIVE)}
            helperText={(errors[field.name]?.message as string as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />

      <Controller
        name="lineAmount"
        defaultValue={0}
        control={control}
        render={({ field }) => (
          <WBTextField
            {...field}
            onChange={(e) => {
              field.onChange(e);
              changePriceByLineAmount(Number(e.target.value));
            }}
            label={t('amount', { ns: 'taskbox' })}
            type="number"
            leftIcon={<InputAdornment position="start">$</InputAdornment>}
            helperText={(errors[field.name]?.message as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />
    </>
  );
}
