import { ReactNode } from 'react';
import {
  WBBadge,
  WBListItem,
  WBListItemIcon,
  WBListItemText,
} from '@admiin-com/ds-web';
import { ListItemLinkButton } from 'apps/react-app/src/app/components';
import { styled, useTheme } from '@mui/material';
import LoadSvgIcon from 'apps/react-app/src/app/component/LoadSvgIcon/LoadSvgIcon';
import { useLocation } from 'react-router-dom';

export interface NavItemProps {
  title: string | ReactNode;
  icon: ReactNode;
  path: string;
  onClick?: () => void;
  hasBadge?: boolean;
  badgeColor?: string;
}

const StyledNavItem = styled(WBListItem)(({ theme }) => ({
  '& .MuiListItemButton-root': {
    // paddingLeft: 24,
    // paddingRight: 24,
  },
}));

export const NavItem = ({
  path,
  icon,
  title,
  onClick,
  badgeColor,
  hasBadge,
}: NavItemProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const isActive = pathname.startsWith(path ?? '');
  return (
    <StyledNavItem>
      <ListItemLinkButton
        to={path}
        onNavigated={onClick}
        sx={{
          padding: 1,
          bgcolor: isActive ? 'primary.light' : 'transparent',
          borderRadius: '8px',
        }}
      >
        <WBListItemIcon>
          <WBBadge
            color={badgeColor as any}
            variant="dot"
            invisible={!hasBadge}
          >
            <LoadSvgIcon
              component={icon}
              fill={
                isActive
                  ? theme.palette.primary.main
                  : theme.palette.text.primary
              }
            />
          </WBBadge>
        </WBListItemIcon>
        <WBListItemText
          primary={title}
          primaryTypographyProps={{
            sx: {
              fontWeight: isActive ? 700 : 400,
              color: isActive ? 'primary.main' : 'text.primary',
            },
          }}
        />
      </ListItemLinkButton>
    </StyledNavItem>
  );
};
