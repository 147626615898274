import { useCurrentyEntityUser } from '../useCurrentUser/useCurrentUser';
import { useSelectedEntity } from '../useSelectedEntity/useSelectedEntity';

export const usePaymentEnabled = () => {
  const user = useCurrentyEntityUser();
  const { entity } = useSelectedEntity();

  //const isOwner = user?.role === 'OWNER';
  //if (isOwner) {
  //  return true;
  //}
  return (user?.paymentsEnabled && !!entity?.taxNumber) ?? false;
};
