import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { TopBarMobile } from './TopBarMobile';
import { TopBarDesktop } from './TopBarDesktop';

export interface TopBarProps {
  open: boolean;
  onChange: () => void;
}

const TopBar = (props: TopBarProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return isMobile ? <TopBarMobile {...props} /> : <TopBarDesktop />;
};

export default TopBar;
