import { WBS3Avatar } from '@admiin-com/ds-amplify-web';

import { frontDateFromBackendDate } from '@admiin-com/ds-common';
import { InvoiceStatus } from '@admiin-com/ds-graphql';
import { WBBox, WBFlex, WBSkeleton, WBTypography } from '@admiin-com/ds-web';
import { Paper, Skeleton, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyNumber } from '../../components/CurrencyNumber/CurrencyNumber';
import PdfPotraitContainer from '../../components/PdfPotraitContainer/PdfPotraitContainer';
import TaskInstallmentsTimeline from '../../components/TaskInstallmentsTimeline/TaskInstallmentsTimeline';
import TaskPdfSignature from '../../components/TaskPdfSignature/TaskPdfSignature';
import { useTaskProperty } from '../../hooks/useTaskProperty/useTaskProperty';
import { useTaskToContactName } from '../../hooks/useTaskToName/useTaskToName';
import { useTaskBoxContext } from '../TaskBox/TaskBox';
import { TaskDraft } from './TaskDraft';
import TaskMenu from './TaskMenu';
import { TaskPayoutDetails } from './TaskPayoutDetails';
import { TaskProperty } from './TaskProperty';
import { getTaskAmount } from '../../helpers/tasks';

export default function TaskDetail() {
  const taskboxContext = useTaskBoxContext();
  const { selectedTask: task, loading } = taskboxContext ?? {};
  const { contactName, contactLoading } = useTaskToContactName(task);

  const { isInstallments, isPayable, invoiceStatus } = useTaskProperty(task);
  const theme = useTheme();
  const { t } = useTranslation();

  // const amount = task?.amount ?? 0;
  // const paymentContext = usePaymentContext();
  // const { getFees } = paymentContext ?? {};
  // const { totalAmount: payableAmount = 0 } = getFees ? getFees([task]) : {};
  // const totalAmount = isPaid ? payableAmount : amount;
  return (
    <WBFlex
      height="100%"
      //minHeight={task?.documents?.length > 0 ? '100vh' : undefined}
      mt={3}
      key={task?.id}
      flexDirection={'column'}
    >
      <WBFlex
        height="100%"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <WBFlex justifyContent={'center'} alignItems={'center'}>
          {contactName ? (
            <WBS3Avatar
              sx={{ width: '52px', height: '52px' }}
              companyName={contactName}
              fontSize="h6.fontSize"
            />
          ) : contactLoading || task === null ? (
            <WBSkeleton
              animation={loading ? 'wave' : false}
              variant="rectangular"
              width={52}
              height={52}
            />
          ) : null}
          <WBBox ml={3}>
            <>
              {contactName && task ? (
                <WBTypography
                  fontSize={{ xs: 'h5.fontSize', sm: 'h3.fontSize' }}
                  fontWeight="bold"
                  mb={0}
                >
                  {contactName}
                </WBTypography>
              ) : (
                <WBSkeleton
                  sx={{ borderRadius: '10px' }}
                  width={100}
                  animation={loading ? 'wave' : false}
                  height={25}
                />
              )}
              {contactName && task ? (
                <WBTypography fontWeight={'medium'}>
                  {task?.reference}
                </WBTypography>
              ) : (
                <WBSkeleton
                  sx={{ borderRadius: '10px' }}
                  width={150}
                  height={25}
                  animation={loading ? 'wave' : false}
                />
              )}
            </>
          </WBBox>
        </WBFlex>
        {task && <TaskMenu task={task} />}
      </WBFlex>
      {task &&
      task.payments &&
      //(isInstallments || task.numberOfPayments > 1) ? (
      (isInstallments || task?.payments?.items?.length >= 1) ? (
        <TaskInstallmentsTimeline task={task} />
      ) : null}
      <WBBox
        sx={{
          mt: { xs: 3, sm: 4 },
          mb: 5,
        }}
      >
        {task ? (
          <Paper
            sx={{
              p: { xs: 2, md: 3, lg: 3, xl: 9 },
              fontWeight: 'bold',
              fontSize: 'body2.fontSize',
              bgcolor: theme.palette.background.default,
              boxShadow: '0 41px 45px -35.5px #636363',
            }}
          >
            <WBFlex
              justifyContent={'space-between'}
              flexDirection={['column', 'column', 'row']}
              alignItems={'start'}
              width={'100%'}
            >
              <WBFlex
                mt={{ xs: 1, sm: 0 }}
                gap={2}
                alignSelf={{ xs: 'start', md: 'end' }}
              >
                <TaskProperty
                  title={t('dueAt', { ns: 'taskbox' })}
                  value={frontDateFromBackendDate(task.dueAt)}
                />
                {isPayable && task.paymentFrequency && (
                  <TaskProperty
                    title={t('frequency', { ns: 'taskbox' })}
                    value={`${t(task.paymentFrequency, { ns: 'taskbox' })} ${
                      task.numberOfPayments ? `(${task.numberOfPayments})` : ''
                    }`}
                  />
                )}
              </WBFlex>
              <WBBox sx={{ display: ['block', 'block', 'none'] }}>
                <TaskPayoutDetails task={task} />
              </WBBox>
              {isPayable ? (
                <WBBox
                  ml={2}
                  mt={{ xs: 3, md: 0, textAlign: { xs: 'start', md: 'end' } }}
                >
                  <WBTypography
                    textAlign={{ xs: 'start', md: 'end' }}
                    fontWeight={'inherit'}
                    textTransform={'uppercase'}
                  >
                    {t(
                      invoiceStatus === InvoiceStatus.QUOTE
                        ? 'quoteAmount'
                        : 'billAmount',
                      { ns: 'taskbox' }
                    )}
                  </WBTypography>
                  <CurrencyNumber
                    sup={false}
                    number={getTaskAmount(task)}
                    textAlign={{ xs: 'start', md: 'end' }}
                    fontSize={{ xs: 'h2.fontSize' }}
                  />
                  {/*{isPaid && (*/}
                  {/*  <WBFlex*/}
                  {/*    justifyContent={{ xs: 'start', md: 'end' }}*/}
                  {/*    width={'100%'}*/}
                  {/*  >*/}
                  {/*    <BreakDownContainer.Link*/}
                  {/*      title={t('showBreakDown', {*/}
                  {/*        ns: 'taskbox',*/}
                  {/*      })}*/}
                  {/*    />*/}
                  {/*  </WBFlex>*/}
                  {/*)}*/}
                </WBBox>
              ) : null}
            </WBFlex>
            <WBBox sx={{ display: ['none', 'none', 'block'] }}>
              <TaskPayoutDetails task={task} />
            </WBBox>
            <WBBox mt={4}>
              {task?.documents?.length > 0 && (
                <PdfPotraitContainer>
                  <TaskPdfSignature task={task} />
                </PdfPotraitContainer>
              )}
            </WBBox>
            {/* <TaskPdfSignature task={task} /> */}
          </Paper>
        ) : (
          <WBBox
            height={'800px'}
            width={'100%'}
            variant="rectangular"
            sx={{
              bgcolor: theme.palette.background.default,
              position: 'relative', // Add this to make positioning absolute elements inside easier
            }}
          >
            <Skeleton
              animation={loading ? 'wave' : false}
              variant="rectangular"
              width="100%"
              height="100%"
            />
            {!loading && (
              <WBFlex
                width={'100%'}
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  position: 'absolute', // Make the text container absolute
                  top: 0,
                  left: 0,
                  px: 4,
                }}
              >
                <WBTypography textAlign={'center'}>
                  {t('noTaskDetailTitle', { ns: 'taskbox' })}
                </WBTypography>
              </WBFlex>
            )}
          </WBBox>
        )}
      </WBBox>
      {/* task.status===TaskStatus.DRAFT? */}
      {task ? <TaskDraft task={task} /> : null}
    </WBFlex>
  );
}
