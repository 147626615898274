import { gql, useQuery } from '@apollo/client';
import {
  ContactType,
  Entity,
  EntityUser,
  EntityUserRole,
  entityUsersByUser as LIST_ENTITY_USERS_BY_USER,
} from '@admiin-com/ds-graphql';
import React, { useMemo } from 'react';
import { mergeUniqueItems } from '@admiin-com/ds-common';

interface UseEntitiesProps {
  searchName?: string;
  isClient?: boolean;
  limit?: number;
  role?: EntityUserRole;
}

interface UserEntitiesReturn {
  userEntities: Entity[];
  clients: any[];
  users: EntityUser[];
  error: Error | undefined;
  hasNextPage: boolean;
  handleLoadMore: () => void;
  loading: boolean;
}
export const useUserEntities = (
  props?: UseEntitiesProps
): UserEntitiesReturn => {
  const { searchName } = props ?? {};
  const pageSize = props?.limit ?? 20;

  const {
    data: entityUsersData,
    fetchMore,
    error: listEntitiesError,
    loading,
  } = useQuery(gql(LIST_ENTITY_USERS_BY_USER), {
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    fetchPolicy: 'cache-first', // Used for first execution
    variables: {
      searchName,
      limit: pageSize,
      role: props?.role,
    },
  });
  const hasNextPage =
    entityUsersData?.entityUsersByUser?.total >
    (entityUsersData?.entityUsersByUser?.items?.length ?? 0);

  const handleLoadMore = async () => {
    if (!hasNextPage || loading) return;
    console.log('Loading more');
    await fetchMore({
      variables: {
        from: entityUsersData?.entityUsersByUser?.items?.length,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        return {
          ...fetchMoreResult,
          entityUsersByUser: {
            ...fetchMoreResult.entityUsersByUser,
            items: mergeUniqueItems(
              prevResult.entityUsersByUser?.items ?? [],
              fetchMoreResult.entityUsersByUser?.items ?? [],
              ['id', 'entityId'] // Assuming 'id' is the unique key
            ),
          },
        };
      },
    });
  };
  const users = useMemo(() => {
    return (
      entityUsersData?.entityUsersByUser?.items?.filter(
        (user: EntityUser) =>
          !props?.isClient ||
          (!!props?.isClient && user.role === EntityUserRole.ACCOUNTANT)
      ) ?? []
    );
  }, [entityUsersData, props?.isClient]);

  const userEntities = useMemo(() => {
    return (
      users
        ?.map((users: EntityUser) => users.entity)
        .filter((entity: Entity) => entity !== null) ?? []
    );
  }, [users]);

  const clients = React.useMemo(() => {
    const filterdContacts: any[] = [];
    if (users)
      for (const client of users) {
        if (client.entity)
          filterdContacts.push({
            __typename: 'Contact',
            contactType: ContactType.CLIENT,
            id: client?.entity?.id,
            userId: client?.userId,
            entityId: client?.entity?.id,
            firstName: client?.entity?.contact?.firstName || '',
            phone: client?.entity?.contact?.phone || '',
            lastName: client?.entity?.contact?.lastName || '',
            email: client?.entity?.contact?.email || '',
            name: client?.entity?.name,
            entity: client?.entity,
          });
      }
    return filterdContacts;
  }, [users]);

  return {
    userEntities,
    users,
    clients,
    handleLoadMore,
    error: listEntitiesError,
    hasNextPage,
    loading,
  };
};
