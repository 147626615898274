import {
  WBBox,
  WBCheckbox,
  WBFlex,
  WBTextField,
  WBToggleButtonGroup,
  WBTypography,
} from '@admiin-com/ds-web';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AutoCompleteLookup from '../../AutoCompleteLookup/AutoCompleteLookup';
import DueDateSelector from '../../DueDateSelector/DueDateSelector';
import React from 'react';
import { useSelectedEntity } from '../../../hooks/useSelectedEntity/useSelectedEntity';
import { DATE_FORMATS, isValidPRN } from '@admiin-com/ds-common';
import { DateTime } from 'luxon';
import { Bill } from '../PayBillCreateForm';
import { isEntityOrContact } from '../../../helpers/entities';
import {
  DocumentAnalysisLineItemInput,
  PaymentFrequency,
  PayoutMethod,
  TaxType,
} from '@admiin-com/ds-graphql';
import {
  InputLabel,
  styled,
  ToggleButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BillLineItems } from '../BillLineItems';
import { LineItemsMobile } from './Scanning/Mobile/LineItemsMobile';

interface Props {
  bill: Bill;
  index?: number | undefined | null;
  noBank?: boolean;
  isManualBillInput?: boolean;
}

export const BillForm = ({
  bill,
  index,
  noBank = false,
  isManualBillInput,
}: Props) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    formState: { isDirty },
  } = useFormContext();
  const { entity } = useSelectedEntity();
  const from = watch(index === undefined ? `from` : `bills.${index}.from`);

  const isTax = React.useMemo(
    () => (from as any)?.metadata?.subCategory === 'TAX',
    [from]
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isBpay =
    Boolean(watch(index === undefined ? `bpay` : `bills.${index}.bpay`)) &&
    Boolean(
      watch(
        index === undefined
          ? `bpay.billerCode`
          : `bills.${index}.bpay.billerCode`
      )
    ) &&
    !!bill.documentAnalysis;

  const bpayReferenceNumber = useWatch({
    control,
    name:
      index === undefined
        ? `bpay.referenceNumber`
        : `bills.${index}.bpay.referenceNumber`,
  });

  const hasBank = Boolean(
    watch(index === undefined ? `bank` : `bills.${index}.bank`)
  );

  const isbankEmpty = !hasBank;

  const getAmountFromLineItems = (
    lineItems: DocumentAnalysisLineItemInput[]
  ) => {
    const amount = lineItems.reduce(
      (acc: number, item: DocumentAnalysisLineItemInput) =>
        acc + (item.price ?? 0),
      0
    );
    if (amount === 0)
      return (bill.documentAnalysis?.task?.amount || 0) * 100 || 0;
    return amount;
  };

  const billerCode =
    from?.bpay?.billerCode || from?.billerCode || from?.metadata?.billerCode;

  const isFromBpay =
    (!isTax && (from as any)?.metadata?.payoutMethod === PayoutMethod.BPAY) ||
    !!billerCode;

  const inputs = React.useMemo(
    () => ({
      invoiceId: {
        label: t('invoiceId', { ns: 'taskbox' }),
        name: index === undefined ? `reference` : `bills.${index}.reference`,
        type: 'text',
        placeholder: t('invoiceIdPlaceholder', { ns: 'taskbox' }),
        defaultValue: bill?.reference ?? '',
        rules: { required: t('referenceRequired', { ns: 'taskbox' }) },
      },
      saveBpayRefForFuture: {
        label: t('saveBpayRefForFuture', { ns: 'taskbox' }),
        name:
          index === undefined
            ? `saveBpayRefForFuture`
            : `bills.${index}.saveBpayRefForFuture`,
        type: 'checkbox',
        defaultValue: true,
        rules: {},
      },
      from: {
        label: t('from', { ns: 'taskbox' }),
        name: index === undefined ? `from` : `bills.${index}.from`,
        type: 'text',
        defaultValue: bill?.from ?? undefined,
        placeholder: t('atoOptional', { ns: 'taskbox' }),
        rules: { required: t('fromRequired', { ns: 'taskbox' }) },
      },
      to: {
        label: t('to', { ns: 'taskbox' }),
        name: index === undefined ? `to` : `bills.${index}.to`,
        type: 'text',
        placeholder: t('entitySearchPlaceholder', { ns: 'taskbox' }),
        defaultValue: entity,
        rules: { required: t('toRequired', { ns: 'taskbox' }) },
      },
      dueAt: {
        label: t('dueAt', { ns: 'taskbox' }),
        name: index === undefined ? `dueAt` : `bills.${index}.dueAt`,
        type: 'date',
        placeholder: DATE_FORMATS.USER_DATE,
        defaultValue: bill?.dueAt ?? '',
        rules: { required: t('dueAtRequired', { ns: 'taskbox' }) },
      },
      issuedAt: {
        label: t('issueAt', { ns: 'taskbox' }),
        name: index === undefined ? `issuedAt` : `bills.${index}.issuedAt`,
        type: 'date',
        placeholder: DATE_FORMATS.USER_DATE,
        defaultValue: DateTime.now().toFormat(DATE_FORMATS.BACKEND_DATE),
        rules: { required: t('issueAtRequired', { ns: 'taskbox' }) },
      },

      routingNumber: {
        label: t('bsb', { ns: 'contacts' }),
        name:
          index === undefined
            ? `bank.routingNumber`
            : `bills.${index}.bank.routingNumber`,
        type: 'text',
        placeholder: t('bsbPlaceholder', { ns: 'contacts' }),
        defaultValue: bill?.bank?.routingNumber ?? '',
        rules: !isbankEmpty
          ? {
              required: t('bsbRequired', { ns: 'contacts' }),
              pattern: {
                value: /^\d{6}$/,
                message: t('bsbPattern', { ns: 'contacts' }),
              },
            }
          : {},
      },
      accountNumber: {
        label: t('accountNumber', { ns: 'contacts' }),
        name:
          index === undefined
            ? `bank.accountNumber`
            : `bills.${index}.bank.accountNumber`,
        type: 'text',
        placeholder: t('accountNumberPlaceholder', { ns: 'contacts' }),
        defaultValue: bill?.bank?.accountNumber ?? '',
        rules: !isbankEmpty
          ? {
              required: t('accountNumberRequired', { ns: 'contacts' }),
              minLength: {
                value: 6,
                message: t('accountNumberMinLength', {
                  ns: 'contacts',
                  count: 6,
                }),
              },
              maxLength: {
                value: 12,
                message: t('accountNumberMaxLength', {
                  ns: 'contacts',
                  count: 12,
                }),
              },
            }
          : {},
      },
      bpay: {
        billerCode: {
          label: t('billerCode', { ns: 'contacts' }),
          name:
            index === undefined
              ? `bpay.billerCode`
              : `bills.${index}.bpay.billerCode`,
          type: 'number',
          placeholder: t('billerCodePlaceholder', { ns: 'contacts' }),
          defaultValue: bill.bpay?.billerCode ?? '',
          rules: {
            validate: {
              required: (value: number | undefined) => {
                if (bpayReferenceNumber && !value) {
                  return t('billerCodeRequired', { ns: 'contacts' });
                }
                return true;
              },
              format: (value: number | undefined) => {
                if (value && !/^\d+$/.test(String(value))) {
                  return t('billerCodeMustBeNumbers', { ns: 'contacts' });
                }
                return true;
              },
            },
          },
        },

        referenceNumber: {
          label: isTax
            ? t('paymentReferenceNumber', { ns: 'taskbox' })
            : t('bpayReferenceNumber', { ns: 'contacts' }),
          name:
            index === undefined
              ? `bpay.referenceNumber`
              : `bills.${index}.bpay.referenceNumber`,
          type: 'text',
          placeholder: isTax
            ? t('paymentReferenceNumberPlaceholder', { ns: 'taskbox' })
            : t('bpayReferenceNumber', { ns: 'contacts' }),
          defaultValue: bill?.bpay?.referenceNumber ?? '',
          rules: {
            validate: {
              isATOorBpay: (value: string | undefined) => {
                if (!value && isTax) {
                  return t('prnRequired', { ns: 'taskbox' });
                }
                if (isTax && value && !isValidPRN(value)) {
                  return t('prnInvalid', { ns: 'taskbox' });
                }
                if (!value) {
                  return t('bpayReferenceRequired', { ns: 'taskbox' });
                }
                if (value && !/^\d{2,20}$/.test(value)) {
                  return t('bpayReferenceInvalid', { ns: 'taskbox' });
                }
                return true;
              },
            },
          },
        },
      },
    }),
    [t, bill, isTax, entity, isbankEmpty, bpayReferenceNumber, index]
  );

  const isfromNotHasBank =
    !!from &&
    isEntityOrContact(from) === 'CONTACT' &&
    (!from.bank?.accountNumber || !from.bank?.routingNumber);

  const isFromHasBank =
    !!from &&
    isEntityOrContact(from) === 'CONTACT' &&
    !!from.bank?.accountNumber &&
    !!from.bank?.routingNumber;

  console.log(isFromHasBank);

  const entityId = watch(
    index === undefined ? 'to.id' : `bills.${index}.to.id`
  );

  const getNestedError = (errors: any, fieldName: string) => {
    const path = fieldName.split('.');
    return path.reduce((error, key) => {
      if (key.includes('[')) {
        // Handle array notation, e.g., 'bills[0]'
        const arrayKey = key.split('[')[0];
        const index = parseInt(key.split('[')[1]);
        return error?.[arrayKey]?.[index];
      }
      return error?.[key];
    }, errors);
  };

  const paymentFrequency = useWatch({
    control,
    name:
      index === undefined
        ? `paymentFrequency`
        : `bills.${index}.paymentFrequency`,
  });

  React.useEffect(() => {
    if (!isBpay)
      if (isFromBpay) {
        if (
          !watch(
            index === undefined
              ? 'bpay.billerCode'
              : `bills.${index}.bpay.billerCode`
          )
        ) {
          setValue(
            index === undefined
              ? 'bpay.billerCode'
              : `bills.${index}.bpay.billerCode`,
            billerCode
          );
        }
        if (
          !watch(
            index === undefined
              ? 'bpay.referenceNumber'
              : `bills.${index}.bpay.referenceNumber`
          )
        ) {
          setValue(
            index === undefined
              ? 'bpay.referenceNumber'
              : `bills.${index}.bpay.referenceNumber`,
            from.bpay?.referenceNumber ?? null
          );
        }
      } else if (!isFromBpay) {
        console.log('reset bpay', isFromBpay);
        setValue(index === undefined ? 'bpay' : `bills.${index}.bpay`, null);
      }
  }, [isFromBpay, isBpay]);

  const changeBankField = () => {
    console.log('changing bank', isfromNotHasBank, from?.bank, index);
    if (isFromHasBank) {
      setValue(
        index === undefined ? 'bank' : `bills.${index}.bank`,
        from?.bank,
        { shouldValidate: true, shouldDirty: true }
      );
    } else if (isDirty && !bill.documentAnalysis?.bank) {
      setValue(
        index === undefined ? 'bank' : `bills.${index}.bank`,
        { accountNumber: '', routingNumber: '' },
        { shouldValidate: true, shouldDirty: true }
      );
    }
  };

  React.useEffect(() => {
    changeBankField();
  }, [isFromHasBank, isDirty]);

  React.useEffect(() => {
    if (isTax) {
      const lineItems = watch(
        index === undefined ? 'lineItems' : `bills.${index}.lineItems`
      );
      const newLineItems = lineItems.map((item: any) => {
        if (item.taxType === TaxType.GST_INCLUSIVE) {
          return {
            ...item,
            taxType: 'NO_GST',
          };
        }
        return item;
      });
      setValue(
        index === undefined ? 'lineItems' : `bills.${index}.lineItems`,
        newLineItems
      );
    }
  }, [isTax]);

  const updateLineItems = (lineItems: DocumentAnalysisLineItemInput[]) => {
    setValue(
      index === undefined ? 'lineItems' : `bills.${index}.lineItems`,
      lineItems
    );
    setValue(
      index === undefined ? 'amount' : `bills.${index}.amount`,
      getAmountFromLineItems(lineItems)
    );
  };
  return (
    <>
      <Controller
        control={control}
        name={inputs.invoiceId.name}
        rules={inputs.invoiceId.rules}
        defaultValue={inputs.invoiceId.defaultValue}
        render={({ field, formState: { errors } }) => (
          <WBTextField
            {...field}
            type={inputs.invoiceId.type}
            placeholder={inputs.invoiceId.placeholder}
            label={inputs.invoiceId.label}
            key={field.name}
            error={!!getNestedError(errors, field.name)}
            helperText={
              (getNestedError(errors, field.name)?.message as string) ?? ''
            }
            fullWidth
          />
        )}
      />
      <WBFlex flexDirection={['column', 'row']} gap={[0, 4]}>
        <Controller
          control={control}
          name={inputs.from.name}
          rules={inputs.from.rules}
          defaultValue={inputs.from.defaultValue}
          render={({ field, formState: { errors } }) => (
            <AutoCompleteLookup
              {...field}
              value={field.value === undefined ? bill.from : field.value}
              noPopupIcon
              label={inputs.from.label}
              placeholder={inputs.from.placeholder}
              ocrCreate
              entityId={entityId}
              type={'ContactsAndVerifiedEntity'}
              helperText={
                (getNestedError(errors, field.name)?.message as string) ?? ''
              }
              error={!!getNestedError(errors, field.name)}
            />
          )}
        />

        <Controller
          control={control}
          name={inputs.to.name}
          rules={inputs.to.rules}
          defaultValue={inputs.to.defaultValue}
          render={({ field, formState: { errors } }) => (
            <AutoCompleteLookup
              {...field}
              noPopupIcon
              label={inputs.to.label}
              placeholder={inputs.to.placeholder}
              type={'Entity'}
              helperText={
                (getNestedError(errors, field.name)?.message as string) ?? ''
              }
              error={!!getNestedError(errors, field.name)}
            />
          )}
        />
      </WBFlex>
      <WBFlex flexDirection={['column', 'row']} gap={[0, 4]}>
        <Controller
          control={control}
          name={inputs.issuedAt.name}
          rules={inputs.issuedAt.rules}
          defaultValue={inputs.issuedAt.defaultValue}
          render={({ field, formState: { errors } }) => (
            <DueDateSelector
              {...field}
              type={inputs.issuedAt.type}
              placeholder={inputs.issuedAt.placeholder}
              label={inputs.issuedAt.label}
              fullWidth
              sx={{ flex: 1 }}
              helperText={
                (getNestedError(errors, field.name)?.message as string) ?? ''
              }
              error={!!getNestedError(errors, field.name)}
            />
          )}
        />
        <Controller
          control={control}
          name={inputs.dueAt.name}
          rules={inputs.dueAt.rules}
          defaultValue={inputs.dueAt.defaultValue}
          render={({ field, formState: { errors } }) => (
            <DueDateSelector
              {...field}
              type={inputs.dueAt.type}
              placeholder={inputs.dueAt.placeholder}
              label={inputs.dueAt.label}
              fullWidth
              sx={{ flex: 1 }}
              helperText={
                (getNestedError(errors, field.name)?.message as string) ?? ''
              }
              error={!!getNestedError(errors, field.name)}
            />
          )}
        />
      </WBFlex>
      {isBpay || isTax || isFromBpay ? (
        <>
          <WBFlex flexDirection={['column', 'row']} gap={[0, 4]}>
            <Controller
              control={control}
              name={inputs.bpay.referenceNumber.name}
              rules={inputs.bpay.referenceNumber.rules}
              defaultValue={inputs.bpay.referenceNumber.defaultValue}
              render={({ field, formState: { errors } }) => (
                <WBTextField
                  {...field}
                  type={inputs.bpay.referenceNumber.type}
                  placeholder={inputs.bpay.referenceNumber.placeholder}
                  label={inputs.bpay.referenceNumber.label}
                  key={field.name}
                  fullWidth
                  error={!!getNestedError(errors, field.name)}
                  helperText={
                    (getNestedError(errors, field.name)?.message as string) ??
                    ''
                  }
                />
              )}
            />
          </WBFlex>
          {(isBpay || isFromBpay) && (
            <Controller
              control={control}
              name={inputs.saveBpayRefForFuture.name}
              rules={inputs.saveBpayRefForFuture.rules}
              defaultValue={inputs.saveBpayRefForFuture.defaultValue}
              render={({ field, formState: { errors } }) => (
                <WBCheckbox
                  {...field}
                  checked={field.value ?? false}
                  label={inputs.saveBpayRefForFuture.label}
                  key={field.name}
                />
              )}
            />
          )}
        </>
      ) : (
        // ((hasMissingBankField && !!bill.documentAnalysis) ||
        //   isfromNotHasBank) &&
        !!from &&
        !noBank && (
          <WBFlex flexDirection={['column', 'row']} gap={[0, 4]}>
            <Controller
              control={control}
              name={inputs.routingNumber.name}
              rules={inputs.routingNumber.rules}
              defaultValue={inputs.routingNumber.defaultValue}
              render={({ field, formState: { errors } }) => (
                <WBTextField
                  {...field}
                  type={inputs.routingNumber.type}
                  placeholder={inputs.routingNumber.placeholder}
                  label={inputs.routingNumber.label}
                  key={field.name}
                  error={!!getNestedError(errors, field.name)}
                  helperText={
                    (getNestedError(errors, field.name)?.message as string) ??
                    ''
                  }
                  fullWidth
                />
              )}
            />
            <Controller
              control={control}
              name={inputs.accountNumber.name}
              rules={inputs.accountNumber.rules}
              defaultValue={inputs.accountNumber.defaultValue}
              render={({ field, formState: { errors } }) => (
                <WBTextField
                  {...field}
                  type={inputs.accountNumber.type}
                  placeholder={inputs.accountNumber.placeholder}
                  label={inputs.accountNumber.label}
                  key={field.name}
                  fullWidth
                  error={!!getNestedError(errors, field.name)}
                  helperText={
                    (getNestedError(errors, field.name)?.message as string) ??
                    ''
                  }
                />
              )}
            />
          </WBFlex>
        )
      )}
      <WBBox mt={2}>
        <Controller
          name={
            index === undefined
              ? `paymentFrequency`
              : `bills.${index}.paymentFrequency`
          }
          control={control}
          defaultValue={PaymentFrequency.ONCE}
          render={({ field }) => (
            <WBToggleButtonGroup
              {...field}
              size="medium"
              fullWidth={isMobile ? true : false}
              exclusive
              sx={{ marginTop: 2, gap: 1 }}
              label={t('paymentFrequency', { ns: 'taskbox' })}
            >
              {[PaymentFrequency.ONCE, PaymentFrequency.MONTHLY].map(
                (key: PaymentFrequency) => {
                  return (
                    <RoundedToggleButton
                      value={key}
                      key={key}
                      selected={
                        key === PaymentFrequency.ONCE
                          ? key === paymentFrequency
                          : paymentFrequency !== 'ONCE'
                      }
                    >
                      {t(key === PaymentFrequency.ONCE ? key : 'RECURRING', {
                        ns: 'taskbox',
                      })}
                    </RoundedToggleButton>
                  );
                }
              )}
            </WBToggleButtonGroup>
          )}
        />
      </WBBox>

      {paymentFrequency !== PaymentFrequency.ONCE && (
        <WBBox mt={2}>
          <Controller
            name={
              index === undefined
                ? `paymentFrequency`
                : `bills.${index}.paymentFrequency`
            }
            control={control}
            defaultValue={PaymentFrequency.ONCE}
            render={({ field }) => (
              <WBToggleButtonGroup
                {...field}
                size="medium"
                fullWidth={isMobile ? true : false}
                exclusive
                sx={{
                  marginTop: 2,
                  gap: 1,
                  display: 'flex',
                  padding: 0,
                  flexWrap: 'wrap',
                }}
              >
                {Object.keys(PaymentFrequency)
                  .filter((key) => key !== PaymentFrequency.ONCE)
                  .map((key) => {
                    return (
                      <RoundedToggleButton
                        value={key}
                        key={key}
                        size="small"
                        sx={{
                          padding: 1,
                          fontSize: theme.typography.body2.fontSize,
                        }}
                      >
                        {t(key, {
                          ns: 'taskbox',
                        })}
                      </RoundedToggleButton>
                    );
                  })}
              </WBToggleButtonGroup>
            )}
          />
        </WBBox>
      )}

      {!isMobile && index !== undefined && (
        <LineItemsContainer>
          <WBTypography
            component={'span'}
            sx={{ opacity: 0.3 }}
            fontSize={'inherit'}
            fontWeight={'bold'}
          >
            {t('lineItems', { ns: 'taskbox' })}
          </WBTypography>
          <BillLineItems
            lineItems={bill?.lineItems ?? []}
            setLineItems={updateLineItems}
          />
        </LineItemsContainer>
      )}
      {isMobile && isManualBillInput && (
        <WBBox mt={2}>
          <InputLabel>{t('lineItems', { ns: 'taskbox' })}</InputLabel>
          <LineItemsMobile
            lineItems={bill?.lineItems ?? []}
            changeLineItems={updateLineItems}
            index={index ?? 0}
            opened={index ?? 0}
            setOpened={() => {
              console.log('setOpened');
            }}
          />
        </WBBox>
      )}
    </>
  );
};

const LineItemsContainer = styled(WBFlex)(({ theme }) => ({
  marginTop: theme.spacing(4),
  flexDirection: 'column',
  width: '100%',
}));

const RoundedToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: '50px !important',
  borderColor: theme.palette.primary.main,
  fontWeight: 500,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  borderWidth: '1px',
  borderLeftColor: `${theme.palette.primary.main} !important`,
  padding: theme.spacing(1.5, 2),
}));
