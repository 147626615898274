import { Task } from '@admiin-com/ds-graphql';
import { WBBox, WBFlex, WBLinkButton } from '@admiin-com/ds-web';
import { t } from 'i18next';
import { TaskProperty } from './TaskProperty';
import { getTaskDirection, isPayoutFailedTask } from '../../helpers/tasks';
import { useCurrentEntityId } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { TaskPayoutDetailModal } from './TaskPayoutDetailModal';
import React from 'react';
import { useTaskPayoutDetails } from './useTaskPayoutDetails';
import { usePaymentContext } from '../../components/PaymentContainer/PaymentContainer';

interface Props {
  task: Task;
}
export const TaskPayoutDetails = (props: Props) => {
  const payoutErrors = props.task.payoutErrors ?? [];

  const { contact, showBankFields, showBpayFields } = useTaskPayoutDetails(
    props.task
  );

  const [showModal, setShowModal] = React.useState(false);
  const { onSubmit } = usePaymentContext();

  const entityId = useCurrentEntityId();
  if (getTaskDirection(props.task, entityId) === 'SENDING') {
    return null;
  }

  return (
    <WBFlex
      alignItems={['start', 'start', 'center']}
      gap={2}
      flexDirection={['column', 'column', 'row']}
    >
      <WBFlex
        gap={2}
        mt={2}
        alignSelf={{ xs: 'start', md: 'end' }}
        alignItems={'center'}
      >
        {contact && contact.bank && showBankFields && (
          <>
            <TaskProperty
              title="BSB"
              value={contact.bank.routingNumber}
              error={payoutErrors.includes('contact.bank.routingNumber')}
            />
            <TaskProperty
              title="Account Number"
              value={contact.bank.accountNumber}
              error={payoutErrors.includes('contact.bank.accountNumber')}
            />
          </>
        )}
        {contact && showBpayFields && (
          <>
            {contact.bpay && (
              <TaskProperty
                title="Biller Code"
                value={contact.bpay.billerCode?.toString() ?? ''}
                error={payoutErrors.includes('contact.bpay.billerCode')}
              />
            )}
            <TaskProperty
              title="Reference"
              value={
                props.task.bpayReferenceNumber ??
                props.task.futureLinkedTask?.bpayReferenceNumber ??
                contact?.bpay?.referenceNumber ??
                ''
              }
              error={
                payoutErrors.includes('task.bpayReferenceNumber') ||
                payoutErrors.includes('contact.bpay.referenceNumber')
              }
            />
          </>
        )}
      </WBFlex>
      {contact && (showBankFields || showBpayFields) && (
        <WBBox sx={{ mt: [0, 0, 6] }}>
          <WBLinkButton color="primary.main" onClick={() => setShowModal(true)}>
            {t(showBankFields ? 'editBankDetails' : 'editBpayDetails', {
              ns: 'taskbox',
            })}
          </WBLinkButton>
        </WBBox>
      )}
      <TaskPayoutDetailModal
        open={showModal}
        onClose={() => setShowModal(false)}
        task={props.task}
        onSubmit={() => {
          setShowModal(false);
          if (isPayoutFailedTask(props.task)) onSubmit(false);
        }}
      />
    </WBFlex>
  );
};
