import React from 'react';
import { TaskCreateModalProps } from './type';
import { InvoiceCreateFormContainer } from '../InvoiceCreateForm';
import { PayBill } from '../PayBill';
import { Finished } from './Finished';
import ESignature from '../ESignature';

export const Content = ({
  onClose,
  page,
  setPage,
  open,
  isEditing,
  onSignatureCreated,
  taskDirection,
  createdTask,
  selectCreateTask,
}: TaskCreateModalProps) => {
  const content = React.useMemo(() => {
    switch (page) {
      case 'INVOICE&QUOTES':
        return <InvoiceCreateFormContainer />;
      case 'PAY_BILL':
        return <PayBill />;
      case 'E_SIGNATURE':
        return <ESignature onComplete={onSignatureCreated} />;
      case 'FINISH':
        return (
          <Finished
            onClose={onClose}
            page={page}
            setPage={setPage}
            open={open}
            isEditing={isEditing}
            onSignatureCreated={onSignatureCreated}
            taskDirection={taskDirection}
            createdTask={createdTask}
            selectCreateTask={selectCreateTask}
          />
        );
    }
  }, [
    page,
    onSignatureCreated,
    setPage,
    taskDirection,
    createdTask,
    selectCreateTask,
  ]);

  return content;
};
