import {
  WBBox,
  WBDialog,
  WBDrawer,
  WBIconButton,
  useMediaQuery,
  useTheme,
} from '@admiin-com/ds-web';
import { DialogProps } from '@mui/material';

export interface SimpleDrawDlgProps extends DialogProps {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  noPadding?: boolean;
  defaultZIndex?: boolean;
  PaperPropsSx?: any;
  dialogSx?: any;
}

export function SimpleDrawDlg({
  children,
  open,
  handleClose,
  defaultZIndex,
  noPadding,
  PaperPropsSx = {},
  dialogSx = {},
  ...props
}: SimpleDrawDlgProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const content = (
    <>
      <WBBox overflow={'auto'} width="100%">
        {children}
      </WBBox>
      <WBIconButton
        aria-label="close"
        icon="Close"
        onClick={(e) => {
          e.stopPropagation();
          handleClose();
        }}
        color={'grey' as any}
        sx={{
          position: 'absolute',
          right: noPadding ? 1 : 0,
          top: noPadding ? 1 : 0,
        }}
      />
    </>
  );
  return !isMobile ? (
    <WBDialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        sx: { padding: noPadding ? 0 : 4 },
        // zIndex: 200
        overflow: 'auto',
      }}
      sx={{
        '& .MuiPaper-root': { overflow: 'auto' },
        display: { xs: 'none', sm: 'block' },
        // zIndex: 200
      }}
      {...props}
    >
      {content}
    </WBDialog>
  ) : (
    <WBDrawer
      anchor={'bottom'}
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: !defaultZIndex ? 1300 : '500',
        display: { xs: 'block', sm: 'none' },
        pointerEvents: 'auto', // Allow events to pass through
        height: '100%',
      }}
      PaperProps={{
        sx: {
          width: '100%',
          display: 'flex',
          p: noPadding ? 0 : 2,
          overflow: 'auto',
          pointerEvents: 'auto', // Ensure content get events
          //minHeight: { xs: '100vh !important' },
          ...PaperPropsSx,
        },
      }}
    >
      {content}
    </WBDrawer>
  );
}

export default SimpleDrawDlg;
