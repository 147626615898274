import { useFormContext } from 'react-hook-form';
import { useTaskCreationContext } from '../../pages/TaskCreation/TaskCreation';
import React from 'react';
import { PageType } from './type';
import {
  CreateTaskInput,
  TaskDirection,
  TaskStatus,
} from '@admiin-com/ds-graphql';
import { useSearchParams } from 'react-router-dom';

export const useTaskCreateModal = () => {
  const context = useTaskCreationContext();

  const { setValue } = useFormContext();

  const [createdTask, setCreatedTask] = React.useState<CreateTaskInput | null>(
    null
  );

  const {
    handleClose,
    open = false,
    task,
    taskDirection,
    selectCreateTask,
  } = context ?? {};
  const [page, setPage] = React.useState<PageType>('INVOICE&QUOTES');

  React.useEffect(() => {
    if (task && task.status === TaskStatus.DRAFT) {
      if (task.type === 'PAY_ONLY') {
        if (task.direction === TaskDirection.SENDING) setPage('INVOICE&QUOTES');
        else {
          setPage('PAY_BILL');
        }
      }
      if (task.type === 'SIGN_ONLY') setPage('E_SIGNATURE');
    } else if (task && task.type === 'SIGN_ONLY') {
      setPage('E_SIGNATURE');
      setValue('annotations', task.annotations);
    }
  }, [task]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangePageParams = (page: PageType) => {
    const params = new URLSearchParams(searchParams);
    params.set('taskCreation', page);
    setSearchParams(params);
  };

  const removePageParams = () => {
    const params = new URLSearchParams(searchParams);
    params.delete('taskCreation'); // Remove the specific parameter
    setSearchParams(params); // Update the URL with the modified parameters
  };

  React.useEffect(() => {
    if (page) {
      handleChangePageParams(page);
    }
  }, [page]);

  React.useEffect(() => {
    if (!open) {
      removePageParams();
    }
  }, [open]);

  const onSignatureCreated = (task: CreateTaskInput) => {
    setPage('FINISH');
    setCreatedTask(task);
  };

  return {
    open,
    onClose: handleClose,
    page: page,
    setPage,
    onSignatureCreated,
    isEditing: !!task,
    selectCreateTask,
    taskDirection,
    createdTask,
    setCreatedTask,
  };
};
