import { TaskDirection } from '@admiin-com/ds-graphql';
import {
  WBBox,
  WBFlex,
  WBList,
  useMediaQuery,
  useTheme,
} from '@admiin-com/ds-web';
import ToolBoxIcon from '../../../../../../assets/icons/task.svg';
import ClientsIcon from '../../../../../../assets/icons/client.svg';
import { useTranslation } from 'react-i18next';
import { useTasks } from 'apps/react-app/src/app/hooks/useTasks/useTasks';
import { useFirmId } from 'apps/react-app/src/app/hooks/useClientWorkflow/useClientWorkflow';
import { PATHS } from 'apps/react-app/src/app/navigation/paths';
import { CreateTaskButton } from '../CreateTaskButton';
import { NavItem } from './NavItem';
import HomeIcon from '../../../../../../assets/icons/home.svg';
import { Theme } from '@mui/system';
import { Zoom } from '@mui/material';

export interface NavListProps {
  onNavigated?: () => void;
  onCreateTask?: () => void;
  opened?: boolean;
}

export function NavList({ onCreateTask, onNavigated, opened }: NavListProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const { hasDeclinedPayment, hasPendingWalletTransfer } = useTasks({
    direction: TaskDirection.RECEIVING,
  });

  const { firmId } = useFirmId();
  const isClient = !!firmId;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <WBBox
      sx={{
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        height: { xs: undefined, sm: '100%' },
        py: 2,
        flex: 1,
        px: opened ? [0, 1] : 'inherit',
        borderRight: `1px solid ${theme.palette.divider}`,
      }}
    >
      {opened && !isMobile && (
        <Zoom
          in={opened && !isMobile}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              opened && !isMobile ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          <CreateTaskButton />
        </Zoom>
      )}

      <WBList sx={{ flexGrow: 1, marginTop: [0, 2] }}>
        <NavItem
          onClick={() => {
            onNavigated && onNavigated();
          }}
          title={opened ? t('Home', { ns: 'common' }) : ''}
          icon={HomeIcon}
          path={PATHS.home}
        />
        <NavItem
          onClick={() => {
            onNavigated && onNavigated();
          }}
          hasBadge={hasDeclinedPayment || hasPendingWalletTransfer}
          badgeColor={hasDeclinedPayment ? 'error' : 'warning'}
          title={
            <WBFlex alignItems={'center'}>
              {opened ? t('taskboxTitle', { ns: 'common' }) : ''}
            </WBFlex>
          }
          icon={ToolBoxIcon}
          path={PATHS.tasks}
        />
        {isClient ? (
          <NavItem
            onClick={() => {
              onNavigated && onNavigated();
            }}
            title={opened ? t('clientsTitle', { ns: 'common' }) : ''}
            icon={ClientsIcon}
            path={PATHS.clients}
          />
        ) : null}
      </WBList>
    </WBBox>
  );
}

export default NavList;
