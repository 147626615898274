import {
  deleteDocumentAnalysis,
  DocumentAnalysis,
} from '@admiin-com/ds-graphql';
import { DateTime } from 'luxon';
import { useSnackbar, WBFlex, WBList, WBTypography } from '@admiin-com/ds-web';
import { useTranslation } from 'react-i18next';
import ForwardBillsItem from './ForwardBillsItem';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

type GroupedBills = {
  [key: string]: DocumentAnalysis[];
};

export default function ForwardBillsList(props: {
  documentAnalysis: DocumentAnalysis[];
  setCheckedItems: (checkedItems: DocumentAnalysis[]) => void;
}) {
  const { t } = useTranslation();
  const today = DateTime.now().startOf('day');

  const daysToString = (diff: number) => {
    if (diff === 0) return t('today', { ns: 'taskbox' });
    if (diff === 1) return t('yesterday', { ns: 'taskbox' });
    return t('days', { ns: 'taskbox', day: diff });
  };

  const renderDate = (diff: number) => {
    if (diff === 0) return daysToString(0);
    else if (diff === 1) return daysToString(1);
    else if (diff <= 7) return daysToString(7);
    else if (diff <= 14) return daysToString(14);
    else return daysToString(30);
  };

  // Group items by date
  const groupedBills = props.documentAnalysis.reduce(
    (acc: GroupedBills, item) => {
      const itemDate = DateTime.fromISO(item.createdAt ?? '').startOf('day');
      const diff = Math.ceil(today.diff(itemDate, 'days').days);

      const group = diff;

      if (!acc[group]) {
        acc[group] = [];
      }

      acc[group].push(item);
      return acc;
    },
    {}
  );

  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const handleCheck = (checked: boolean, item: DocumentAnalysis) => {
    const newCheckedItems = checked
      ? [...checkedItems, item.id]
      : checkedItems.filter((id) => id !== item.id);
    setCheckedItems(newCheckedItems);

    props.setCheckedItems(
      props.documentAnalysis.filter((item) => newCheckedItems.includes(item.id))
    );
  };

  const [deleteDocumentAnalysisMutation] = useMutation(
    gql(deleteDocumentAnalysis),
    {
      update(cache, { data }) {
        if (data?.deleteDocumentAnalysis) {
          const deletedId = data.deleteDocumentAnalysis.id;
          cache.modify({
            fields: {
              documentAnalysisByEntityStatus(existingRefs = [], { readField }) {
                return {
                  ...existingRefs,
                  items: existingRefs.items.filter(
                    (ref: any) => readField('id', ref) !== deletedId
                  ),
                };
              },
            },
          });
        }
      },
    }
  );

  const showSnackbar = useSnackbar();
  const handleRemove = async (item: DocumentAnalysis) => {
    try {
      await deleteDocumentAnalysisMutation({
        variables: { input: { id: item.id } },
      });
    } catch (error: any) {
      showSnackbar({
        message: t(error?.message || 'error', { ns: 'errors' }),
        severity: 'error',
      });
    }
  };
  return (
    <>
      {Object.entries(groupedBills).map(
        ([group, items]) =>
          items.length > 0 && (
            <div key={group}>
              <WBFlex alignItems={'center'} gap={1}>
                <WBTypography>{renderDate(Number(group))}</WBTypography>
                <WBFlex alignItems={'center'} gap={1}>
                  {Ellipse}
                  <WBTypography variant="body1" color={'text.secondary'}>
                    {today
                      .minus({ days: Number(group) })
                      .toFormat('dd MMM yyyy')}
                  </WBTypography>
                </WBFlex>
              </WBFlex>
              <WBList>
                {items.map((item) => (
                  <ForwardBillsItem
                    key={item.id}
                    onRemove={() => handleRemove(item)}
                    documentAnalysis={item}
                    checked={checkedItems.includes(item.id)}
                    onCheck={(checked) => handleCheck(checked, item)}
                  />
                ))}
              </WBList>
            </div>
          )
      )}
    </>
  );
}
const Ellipse = (
  <svg
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="3" cy="3" r="3" fill="#D9D9D9" />
  </svg>
);
