import { useTheme, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  WBBox,
  WBFlex,
  WBIcon,
  WBLink,
  WBTab,
  WBTabs,
  WBTooltip,
  WBTypography,
} from '@admiin-com/ds-web';
import { PageType } from './type';
import ConfirmationDlg from '../ConfirmationDlg/ConfirmationDlg';
import { useFormState } from 'react-hook-form';
import { useIsClient } from 'usehooks-ts';
import { useFirmId } from '../../hooks/useClientWorkflow/useClientWorkflow';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';
import useSelectedFirm from '../../hooks/useSelectedFirm/useSelectedFirm';

const HeaderFlex = styled(WBFlex)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
  padding: theme.spacing(3, 8),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#151515' : '#151515',
}));
const LeftToolbarFlex = styled(WBFlex)({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
});
const StyledWBLink = styled(WBLink)(({ theme }) => ({
  underline: 'always',
  alignContent: 'center',
  display: 'flex',
  color: theme.palette.common.black,
}));
const CenteredTypography = styled(WBTypography)(({ theme }) => ({
  flex: 2,
  textAlign: 'center',
  marginBottom: 0,
}));
const FlexSpacer = styled(WBBox)({
  flex: 1,
});
const Tabs = styled(WBTabs)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  paddingRight: theme.spacing(40),
  paddingLeft: theme.spacing(40),
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));
const Tab = styled(WBTab)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
  fontWeight: 'medium',
}));
interface Props {
  open: boolean;
  onClose: () => void;
  page: PageType;
  setPage: (page: PageType) => void;
  isEditing: boolean;
}
const TABS = ['INVOICE&QUOTES', 'PAY_BILL', 'E_SIGNATURE'];
export function TaskCreateHeader({ onClose, page, setPage, isEditing }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const headerColor = theme.palette.mode === 'dark' ? 'white' : 'white';
  const handleChangePage = (page: PageType) => {
    setPage(page);
  };
  const { isDirty } = useFormState();

  const [showCloseConfirmation, setShowCloseConfirmation] =
    React.useState(false);

  const { isNotFirm } = useFirmId();
  const { entity } = useSelectedEntity();
  const disableTabs = isNotFirm && !entity?.taxNumber;

  if (disableTabs && page !== 'E_SIGNATURE') setPage('E_SIGNATURE');

  return (
    <>
      <ConfirmationDlg
        open={showCloseConfirmation}
        onClose={() => setShowCloseConfirmation(false)}
        onOK={onClose}
        title={t('closeConfirmationTitle', { ns: 'taskbox' })}
      >
        <WBTypography variant="body1" color="textPrimary">
          {t('closeConfirmationSubTitle', { ns: 'taskbox' })}
        </WBTypography>
      </ConfirmationDlg>
      <HeaderFlex>
        <LeftToolbarFlex>
          <StyledWBLink
            onClick={() => {
              if (isDirty) {
                setShowCloseConfirmation(true);
              } else {
                onClose();
              }
            }}
          >
            <WBIcon color={headerColor} name="ArrowBack" size="small" />
            <WBTypography
              variant="body1"
              fontWeight="medium"
              noWrap
              ml={1}
              color={headerColor}
              component="div"
            >
              {t('back', { ns: 'common' })}
            </WBTypography>
          </StyledWBLink>
        </LeftToolbarFlex>
        <CenteredTypography
          variant="h5"
          fontWeight="medium"
          color={headerColor}
        >
          {t(isEditing ? 'editTaskTitle' : 'createTaskTitle', {
            ns: 'taskbox',
          })}
        </CenteredTypography>
        <FlexSpacer />
      </HeaderFlex>
      {page !== 'FINISH' && (
        <StyledTabs
          value={TABS.indexOf(page)}
          onChange={(_e: React.SyntheticEvent, v: PageType) => {
            if (disableTabs && (v === 'INVOICE&QUOTES' || v === 'PAY_BILL'))
              return;
            handleChangePage(v);
          }}
          variant="fullWidth"
          centered
        >
          {TABS.map((tab, index) => (
            <WBTooltip
              title={
                disableTabs && (index === 0 || index === 1)
                  ? t(
                      'Must have a business with an ABN to send and receive payments',
                      { ns: 'taskbox' }
                    )
                  : ''
              }
              key={tab}
              onClick={() => {
                if (disableTabs && (index === 0 || index === 1)) return;
                handleChangePage(tab as PageType);
              }}
            >
              <StyledTab
                value={tab}
                // disabled={disableTabs && (index === 0 || index === 1)}
                label={t(tab, { ns: 'taskbox' })}
                sx={{
                  ...(disableTabs &&
                    (index === 0 || index === 1) && {
                      '&:hover': {
                        color: 'text.secondary',
                      },
                    }),
                }}
              />
            </WBTooltip>
          ))}
        </StyledTabs>
      )}
    </>
  );
}
const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));
const StyledTab = styled(Tab)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(0, 1),
  },
}));
export default TaskCreateHeader;
