import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { WBButton, WBForm, WBTelInput, WBTypography } from '@admiin-com/ds-web';
import SimpleDrawDlg from '../SimpleDrawDlg/SimpleDrawDlg';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { matchIsValidTel } from 'mui-tel-input';

export interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onOK: (number: string) => Promise<void>;
  defaultNumber?: string;
  name: string;
  disabledEdit?: boolean;
}

function SMSNumberModal(props: ConfirmationDialogProps) {
  const { onClose, open, onOK, defaultNumber, name } = props;
  const [loading, setLoading] = React.useState(false);
  const handleCancel = () => {
    onClose();
  };

  const isCreate = Boolean(defaultNumber) === false;

  const title = isCreate
    ? t('addContactNumberTitle', { ns: 'taskbox', contact: name })
    : t('editContactNumberTitle', { ns: 'taskbox', contact: name });
  const { control, handleSubmit } = useForm();
  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await onOK(data.phone);
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleDrawDlg open={open} handleClose={onClose}>
      <WBForm onSubmit={handleSubmit(onSubmit)} mt={0}>
        <DialogTitle
          variant="h3"
          textAlign={'center'}
          sx={{ p: 0, color: 'text.primary' }}
        >
          {title}
          <WBTypography textAlign={'center'} color={'text.primary'}>
            {t('contactNumberDescription', { ns: 'taskbox' })}
          </WBTypography>
        </DialogTitle>
        <DialogContent sx={{ px: [2, 10] }}>
          <Controller
            control={control}
            name="phone"
            defaultValue={defaultNumber}
            rules={{
              required: t('phoneRequired', { ns: 'common' }),
              validate: (value: string | null | undefined) =>
                value === null ||
                value === undefined ||
                value === '' ||
                matchIsValidTel(value) ||
                t('invalidPhone', { ns: 'common' }),
            }}
            render={({ field, fieldState }) => (
              //@ts-ignore - value shouldn't be null but is possible by react-form-hooks
              <WBTelInput
                {...field}
                variant="standard"
                helperText={
                  fieldState.invalid ? t('invalidPhone', { ns: 'common' }) : ''
                }
                error={fieldState.invalid}
                focusOnSelectCountry
                defaultCountry="AU"
                label={''}
                margin="dense"
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ width: '100%', px: [2, 10] }}>
          <WBButton
            autoFocus
            variant="outlined"
            onClick={handleCancel}
            fullWidth
            type="button"
          >
            {t('cancel', { ns: 'common' })}
          </WBButton>
          {!props.disabledEdit && (
            <WBButton type="submit" loading={loading} fullWidth>
              {t(isCreate ? 'add' : 'Edit', { ns: 'common' })}
            </WBButton>
          )}
        </DialogActions>
      </WBForm>
    </SimpleDrawDlg>
  );
}
export default SMSNumberModal;
