import { Task } from '@admiin-com/ds-graphql';
import { useTaskToContactName } from '../../hooks/useTaskToName/useTaskToName';

export const useTaskPayoutDetails = (task: Task | null) => {
  const { data, futureLinkedContact } = useTaskToContactName(task);
  const contact = task?.type === 'SIGN_PAY' ? futureLinkedContact : data;
  if (task === null)
    return { contact: null, showBankFields: false, showBpayFields: false };
  const showBankFields =
    contact &&
    contact?.bank &&
    !(task.bpayReferenceNumber ?? task.futureLinkedTask?.bpayReferenceNumber);
  const showBpayFields =
    contact &&
    (!contact.bank ||
      (task.bpayReferenceNumber ?? task.futureLinkedTask?.bpayReferenceNumber));
  const hasBpayContact = !!contact?.bpay;
  return { contact, hasBpayContact, showBankFields, showBpayFields };
};
