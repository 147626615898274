import { useFormContext } from 'react-hook-form';
import Finish from '../ESignature/Finish';
import { TaskCreateModalProps } from './type';
import { CreateTaskStatus, TaskType } from '@admiin-com/ds-graphql';
import { t } from 'i18next';

export const Finished = ({
  setPage,
  taskDirection,
  createdTask,
  selectCreateTask,
}: TaskCreateModalProps) => {
  const { reset } = useFormContext();

  return (
    <Finish
      onGetStarted={() => {
        reset({
          documents: [],
          lineItems: [],
          signers: [],
        });
        setPage('E_SIGNATURE');
      }}
      title={t(
        createdTask?.status === CreateTaskStatus.DRAFT
          ? 'draftCreatedTitle'
          : taskDirection === 'SENDING'
          ? 'documentSent'
          : 'documentCreated',
        { ns: 'taskbox' }
      )}
      description={t(
        createdTask?.status === CreateTaskStatus.DRAFT
          ? 'draftCreatedDescription'
          : taskDirection === 'SENDING'
          ? 'documentSentDescription'
          : createdTask?.type === TaskType.SIGN_PAY
          ? 'documentCreatedDescription'
          : 'signedDocumentCreatedDescription',
        { ns: 'taskbox' }
      )}
      buttonTitle={t('uploadAnotherDocument', { ns: 'taskbox' })}
      onBack={() => selectCreateTask(createdTask)}
    />
  );
};
