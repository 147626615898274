import { Outlet, useParams } from 'react-router-dom';
import React from 'react';
import { useEntitySelect } from '../../features/hooks/useEntitySelect/useEntitySelect';

export function EntityTaskbox() {
  const { entityId } = useParams();
  const changeEntityId = useEntitySelect();

  React.useEffect(() => {
    console.log('entityId: ', entityId);
    if (entityId) {
      changeEntityId(entityId);
    }
  }, [entityId]);
  return <Outlet />;
}

export default EntityTaskbox;
