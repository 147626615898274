import React from 'react';
import { AlertColor, DialogContent, DialogTitle } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import SimpleDrawDlg from '../SimpleDrawDlg/SimpleDrawDlg';
import {
  useSnackbar,
  WBButton,
  WBFlex,
  WBTypography,
} from '@admiin-com/ds-web';
import { CurrencyNumber } from '../CurrencyNumber/CurrencyNumber';
import {
  createEntityUser as CREATE_ENTITY_USER,
  Payment,
  resendRemittence as RESEND_REMITTENCE,
} from '@admiin-com/ds-graphql';
import { gql, useMutation } from '@apollo/client';

interface RemittanceDialogProps {
  open: boolean;
  handleClose: () => void;
  payment: Payment | null | undefined;
}

export const RemittanceDialog: React.FC<RemittanceDialogProps> = ({
  open,
  handleClose,
  payment,
}) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();
  const [resendRemittence, { loading }] = useMutation(gql(RESEND_REMITTENCE), {
    variables: {
      input: {
        id: payment?.id,
      },
    },
  });

  const onResend = async () => {
    try {
      await resendRemittence();
      showSnackbar({
        message: 'Remittance resent',
        severity: 'success' as AlertColor,
        horizontal: 'right',
        vertical: 'bottom',
      });
    } catch (err: any) {
      console.error('Error resending remittance:', err);
      showSnackbar({
        message: err?.message ?? 'Error resending remittance',
        severity: 'error' as AlertColor,
        horizontal: 'right',
        vertical: 'bottom',
      });
    }
  };

  return (
    <SimpleDrawDlg open={open} handleClose={handleClose}>
      <DialogTitle variant="h3" fontWeight={'bold'} color="text.primary">
        {t('remittance', { ns: 'taskbox' })}

        <WBTypography variant="body1" mt={1}>
          {t(!payment?.paidOutAt ? 'fundsWillBePaidOut' : 'fundsPaidOut', {
            ns: 'taskbox',
          })}
        </WBTypography>
      </DialogTitle>
      <DialogContent>
        <WBTypography variant="body2">
          {payment?.payoutDetails?.bank?.accountName && (
            <>
              <WBTypography variant="h5" mt={3}>
                {t('payoutBankAccountName', { ns: 'taskbox' })}
              </WBTypography>
              <WBTypography mr={1}>
                {payment?.payoutDetails?.bank?.accountName}
              </WBTypography>
            </>
          )}

          {payment?.payoutDetails?.bank?.routingNumber && (
            <>
              <WBTypography variant="h5" mt={3}>
                {t('payoutBankRoutingNumber', { ns: 'taskbox' })}
              </WBTypography>
              <WBTypography mr={1}>
                {payment?.payoutDetails?.bank?.routingNumber}
              </WBTypography>
            </>
          )}

          {payment?.payoutDetails?.bank?.accountNumber && (
            <>
              <WBTypography variant="h5" mt={3}>
                {t('payoutBankAccountNumber', { ns: 'taskbox' })}
              </WBTypography>
              <WBTypography mr={1}>
                {payment?.payoutDetails?.bank?.accountNumber}
              </WBTypography>
            </>
          )}

          {payment?.payoutDetails?.bpay?.billerCode && (
            <>
              <WBTypography variant="h5" mt={3}>
                {t('payoutBpayBillerCode', { ns: 'taskbox' })}
              </WBTypography>
              <WBTypography mr={1}>
                {payment?.payoutDetails?.bpay?.billerCode}
              </WBTypography>
            </>
          )}
          {payment?.payoutDetails?.bpay?.referenceNumber && (
            <>
              <WBTypography variant="h5" mt={3}>
                {t('payoutBpayReference', { ns: 'taskbox' })}
              </WBTypography>
              <WBTypography mr={1}>
                {payment?.payoutDetails?.bpay?.referenceNumber}
              </WBTypography>
            </>
          )}
          {payment?.netAmount && (
            <>
              <WBTypography variant="h5" mt={3}>
                {t('amount', { ns: 'taskbox' })}
              </WBTypography>
              <CurrencyNumber
                fontWeight="initial"
                number={(payment?.netAmount ?? 0) / 100}
                sup={false}
              />
            </>
          )}
          {payment?.paidOutAt && (
            <>
              <WBTypography variant="h5" mt={3}>
                {t('payoutDateTime', { ns: 'taskbox' })}
              </WBTypography>
              <WBTypography mr={1}>
                {DateTime.fromISO(payment?.paidOutAt).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </WBTypography>
            </>
          )}

          <WBFlex mt={5} justifyContent="center">
            <WBButton loading={loading} onClick={() => onResend()}>
              {t('resendRemittance', { ns: 'taskbox' })}
            </WBButton>
          </WBFlex>
        </WBTypography>
      </DialogContent>
    </SimpleDrawDlg>
  );
};

export default RemittanceDialog;
