import { DateTime } from 'luxon';

export const removeSpaces = (input: string): string => {
  return input.replace(/\s+/g, '');
};

export function truncateString(
  input: string,
  maxLength?: number | undefined
): string {
  if (!maxLength || input.length <= maxLength) {
    return input;
  }
  return input.slice(0, maxLength) + '...';
}

export function formatCurrency(value: number | null): string {
  return value
    ? `$${value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : '';
}

export function formatNumberWithCommas(
  value?: number,
  withDecimals = false
): string {
  if (!value) return '';

  return withDecimals
    ? value?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : value?.toLocaleString('en-US');
}

export function formatDateWithDaysDifference(
  dateString: string | null | undefined
): string {
  if (!dateString) return 'Add manually';

  // Create dates in Sydney timezone
  const sydneyTZ = 'Australia/Sydney';

  // Convert input date to Sydney timezone
  const dueDate = DateTime.fromISO(dateString).setZone(sydneyTZ);

  // Get today's date in Sydney timezone
  const todaySydney = DateTime.now().setZone(sydneyTZ).startOf('day');

  // Calculate difference in days
  const dayDifference = Math.ceil(dueDate.diff(todaySydney, 'days').days);

  // Format the date
  const formattedDate = dueDate.toLocaleString({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return dayDifference === 0
    ? `${formattedDate} (Today)`
    : `${formattedDate} (${dayDifference} Days)`;
}

export function getInitials(name: string): string {
  if (!name) return '';

  const words = name.trim().split(/\s+/);

  return words.map((word) => word[0].toUpperCase()).join('');
}

export function toTitleCase(str: string): string {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

export const findFirstErrorField = (errors: any, path = ''): string | null => {
  for (const key in errors) {
    const fieldPath = path ? `${path}.${key}` : key;
    if (errors[key]?.ref) {
      return fieldPath;
    }
    if (typeof errors[key] === 'object') {
      const nestedField = findFirstErrorField(errors[key], fieldPath);
      if (nestedField) {
        return nestedField;
      }
    }
  }
  return null;
};

export const getNestedError = (errors: any, fieldName: string) => {
  const path = fieldName.split('.');
  return path.reduce((error, key) => {
    if (key.includes('[')) {
      // Handle array notation, e.g., 'bills[0]'
      const arrayKey = key.split('[')[0];
      const index = parseInt(key.split('[')[1]);
      return error?.[arrayKey]?.[index];
    }
    return error?.[key];
  }, errors);
};
