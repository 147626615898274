import { S3Upload as S3UploadCommon } from '@admiin-com/ds-common';
import {
  DocumentAnalysisLineItemInput,
  LineItem,
  LineItemInput,
  S3UploadInput,
  S3UploadLevel,
  S3UploadType,
  TaxType,
} from '@admiin-com/ds-graphql';

export const transformS3UploadInput = (
  s3UploadInput: S3UploadCommon | null
): S3UploadInput => {
  if (!s3UploadInput)
    return {
      level: S3UploadLevel.private,
      key: '',
      identityId: null,
      type: S3UploadType.IMAGE,
    };
  return {
    level: s3UploadInput.level as S3UploadLevel,
    key: s3UploadInput.key,
    identityId: s3UploadInput.identityId,
    type: s3UploadInput.type as S3UploadType,
  };
};

export const transformDocumentAnalysisLineItemInput = (
  lineItem: DocumentAnalysisLineItemInput
): LineItemInput => {
  return {
    description: lineItem.description ?? 'no description',
    quantity: lineItem.quantity,
    unitAmount: lineItem.unitPrice,
    lineAmount: lineItem.price,
    taxType: lineItem.taxType ?? TaxType.NO_GST,
  };
};

export const transformLineItemToDocumnetAnalysisInput = (
  lineItem: LineItem | null
): DocumentAnalysisLineItemInput => {
  if (!lineItem) {
    return {
      description: '',
      quantity: 0,
      unitPrice: 0,
      price: 0,
      taxType: TaxType.NO_GST,
    };
  }
  return {
    description: lineItem.description,
    quantity: lineItem.quantity,
    unitPrice: lineItem.unitAmount,
    price: lineItem.lineAmount,
    taxType: lineItem.taxType,
  };
};
