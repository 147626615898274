import {
  daysDifference,
  frontDateFromBackendDate,
  frontDateFromISO,
  numberWithCommasDecimals,
} from '@admiin-com/ds-common';
import {
  CSGetSub as GET_SUB,
  InvoiceStatus,
  PaymentStatus,
  Task,
  TaskPaymentStatus,
  TaskSettlementStatus,
  TaskSignatureStatus,
  TaskType,
  TaskStatus,
  TaskDirection,
} from '@admiin-com/ds-graphql';
import { useTheme, WBBox, WBTypography } from '@admiin-com/ds-web';
import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sortPayments } from '../../helpers/payments';
import { signedAndPendingUserNames } from '../../helpers/signature';
import {
  getDeclinedDateForScheduled,
  getDeclinedReasonForScheduled,
  getTaskDueDate,
  isDeclinedTask,
  isPaidTask,
  isPayoutFailedTask,
  isPendingSigantureTask,
  isPendingTask,
  isSignedTask,
  isTaskScheduled,
} from '../../helpers/tasks';

export const useTaskProperty = (task: Task | null | undefined) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSigned = isSignedTask(task);
  const isPaid = isPaidTask(task);

  const isScheduled = isTaskScheduled(task);
  const { data: subData } = useQuery(gql(GET_SUB));
  const userId = subData?.sub;
  const dueDate = React.useMemo(() => getTaskDueDate(task), [task]);

  const isPayment =
    task?.type === TaskType.PAY_ONLY ||
    task?.type === TaskType.SIGN_PAY ||
    task?.invoiceStatus === InvoiceStatus.QUOTE;

  const payments = sortPayments((task?.payments?.items as any) ?? []);

  const totalInstallments = payments?.length ?? 0;
  const paidInstallments = payments?.filter(
    (payment) =>
      payment?.status === PaymentStatus.SUCCEEDED ||
      (payment?.status.startsWith('PAYOUT_') &&
        payment?.status !== PaymentStatus.PAYOUT_FAILED)
  ).length;

  const getTaskPendingSignatureStatus = React.useCallback(
    (task: Task) => {
      return isPendingSigantureTask(task, userId);
    },
    [userId]
  );

  const isPendingSiganture = React.useMemo(() => {
    if (!task) return false;
    return isPendingSigantureTask(task, userId);
  }, [userId, task]);

  const scheduledDate =
    totalInstallments > 0
      ? daysDifference(payments?.[0]?.scheduledAt ?? new Date().toString())
      : 0;

  const scheduledAt = new Date(payments?.[0]?.scheduledAt ?? new Date());
  const remainedAmount =
    payments
      ?.filter((payment) => payment?.status !== PaymentStatus.SUCCEEDED)
      .reduce(
        (sum, payment) =>
          sum + (payment && payment.amount ? payment.amount : 0),
        0
      ) ?? 0;

  const scheduledToolTip =
    scheduledDate === 0 ? (
      task?.invoiceStatus === InvoiceStatus.QUOTE ? (
        t('quoteIsDue', { ns: 'taskbox' })
      ) : (
        t('invoiceIsDue', { ns: 'taskbox' })
      )
    ) : (
      <>
        {task?.invoiceStatus === InvoiceStatus.QUOTE
          ? t('quoteWillExcutedIn', { ns: 'taskbox' })
          : t('invoiceWillExcutedIn', { ns: 'taskbox' })}{' '}
        <b>
          {t(
            scheduledDate === 0
              ? 'today'
              : scheduledDate === 1
              ? 'day'
              : 'days',
            {
              ns: 'taskbox',
              day: scheduledDate,
            }
          )}
        </b>{' '}
        {t('usingYourPaymentMethod', { ns: 'taskbox' })}{' '}
        {/* {maskCreditCardNumberSimple(task)} */}
      </>
    );

  const dueToolTip = (
    <WBBox>
      {task?.lodgementAt && (
        <WBTypography
          fontSize={'inherit'}
          color={'inherit'}
          fontWeight={'inherit'}
        >
          {`${t('lodgementDate', {
            ns: 'taskbox',
          })}`}
          <b> {frontDateFromBackendDate(task?.lodgementAt ?? '')}</b>
        </WBTypography>
      )}
      <WBTypography
        fontSize={'inherit'}
        color={'inherit'}
        fontWeight={'inherit'}
      >
        {`${t(
          task?.type === TaskType.SIGN_ONLY
            ? 'signatureDueOn'
            : task?.invoiceStatus === InvoiceStatus.QUOTE
            ? 'quoteDueOn'
            : 'paymentDueOn',
          {
            ns: 'taskbox',
          }
        )}`}
        <b> {frontDateFromBackendDate(task?.dueAt ?? '')}</b>
      </WBTypography>
    </WBBox>
  );

  const paidToolTip = (
    <WBBox>
      {task?.lodgementAt && (
        <WBTypography
          fontSize={'inherit'}
          color={'inherit'}
          fontWeight={'inherit'}
        >
          {`${t('lodgementDate', {
            ns: 'taskbox',
          })}`}
          <b> {frontDateFromBackendDate(task?.lodgementAt ?? '')}</b>
        </WBTypography>
      )}
      <WBTypography
        fontSize={'inherit'}
        color={'inherit'}
        fontWeight={'inherit'}
      >
        {`${t(
          task?.type === TaskType.SIGN_ONLY
            ? 'signedOn'
            : task?.invoiceStatus === InvoiceStatus.QUOTE
            ? 'acceptedOn'
            : 'paidOn',
          {
            ns: 'taskbox',
          }
        )}`}
        <b> {frontDateFromISO(task?.paidAt ?? task?.quoteAcceptedAt ?? '')}</b>
      </WBTypography>
    </WBBox>
  );

  const declinedToolTip = (
    <>
      <span style={{ color: theme.palette.error.main }}>{`${t(
        'paymentDeclined',
        {
          ns: 'taskbox',
        }
      )}`}</span>{' '}
      {` ${t('on', { ns: 'taskbox' })} `}
      {frontDateFromISO(getDeclinedDateForScheduled(task) ?? '')}
      {` ${t('because', { ns: 'taskbox' })} `}
      <span style={{ color: theme.palette.error.main }}>{`${t(
        getDeclinedReasonForScheduled(task) ?? ''
      )}`}</span>
    </>
  );

  const installmentTooltip = (
    <WBBox>
      {payments?.map((payment, index) => (
        <WBTypography
          fontSize={'inherit'}
          color={'inherit'}
          fontWeight={'inherit'}
          key={payment?.id}
        >
          {t('installment', { ns: 'taskbox' })} <b>{index + 1}</b>
          <b>
            {' '}
            {` - `}
            {`$${numberWithCommasDecimals((payment?.amount ?? 0) / 100)}`}{' '}
            <span
              style={{
                color:
                  payment?.status === PaymentStatus.SUCCEEDED
                    ? theme.palette.success.main
                    : payment?.status === PaymentStatus.VOIDED
                    ? theme.palette.grey['500']
                    : payment?.status === PaymentStatus.SCHEDULED ||
                      payment?.status ===
                        PaymentStatus.PENDING_USER_CONFIRMATION ||
                      payment?.status === PaymentStatus.USER_CONFIRMED
                    ? theme.palette.warning.main
                    : payment?.status === PaymentStatus.DECLINED ||
                      payment?.status === PaymentStatus.PAYOUT_FAILED
                    ? theme.palette.error.main
                    : 'inherit',
              }}
            >
              {t(
                payment?.status === PaymentStatus.COMPLETED &&
                  task?.invoiceStatus === InvoiceStatus.QUOTE
                  ? 'ACCEPTED'
                  : payment.status,
                { ns: 'taskbox' }
              )}
            </span>
          </b>{' '}
          {payment?.status === PaymentStatus.SCHEDULED
            ? t('toBePaid', { ns: 'taskbox' })
            : ''}
          {` ${t('on', { ns: 'taskbox' })} `}
          <b>
            {' '}
            {frontDateFromBackendDate(
              payment?.status === PaymentStatus.DECLINED ||
                payment?.status === PaymentStatus.PAYOUT_FAILED
                ? payment.declinedAt ?? ''
                : payment?.scheduledAt ?? ''
            )}
          </b>
        </WBTypography>
      ))}
    </WBBox>
  );

  const { signedUsersName, pendingUsersName } = signedAndPendingUserNames(
    task?.annotations
  );
  const signatureRequiredTooltip = (
    <WBBox>
      {signedUsersName?.map((name, index) => (
        <WBTypography
          fontSize={'inherit'}
          color={'inherit'}
          fontWeight={'inherit'}
          key={name}
        >
          <b>{name}</b> {t('has', { ns: 'taskbox' })}{' '}
          <WBBox component="span" fontWeight="bold" color="success.main">
            {t('signedLower', { ns: 'taskbox' })}
          </WBBox>
        </WBTypography>
      ))}
      {pendingUsersName?.map((name, index) => (
        <WBTypography
          fontSize={'inherit'}
          color={'inherit'}
          fontWeight={'inherit'}
          key={name}
        >
          <b>{name}</b>
          {t('hasPendingSignature', { ns: 'taskbox' })}{' '}
          <WBBox component="span" fontWeight="bold" color="warning.main">
            {t('pending', { ns: 'taskbox' })}
          </WBBox>
        </WBTypography>
      ))}
      {signedUsersName?.length === 0 && pendingUsersName?.length === 0 && (
        <WBTypography
          fontSize={'inherit'}
          color={'inherit'}
          fontWeight={'inherit'}
        >
          {t('noSignedOrPendingSignature', { ns: 'taskbox' })}
        </WBTypography>
      )}
    </WBBox>
  );

  const isInstallments = totalInstallments > 1;

  const isAchivableByStatus =
    (task?.paymentStatus === TaskPaymentStatus.NOT_PAYABLE ||
      task?.paymentStatus === TaskPaymentStatus.PENDING_PAYMENT) &&
    (task?.signatureStatus === TaskSignatureStatus.NOT_SIGNABLE ||
      task?.signatureStatus === TaskSignatureStatus.PENDING_SIGNATURE);

  //TODO: improve logic - so client firm can delete tasks.

  // const isAchivableByEntity = task?.createdBy === subData?.sub;
  // const isCreatedBy = task?.createdBy === subData?.sub;
  const isCreatedBy = true;
  const isAchivableByEntity = true;

  const isAchivable = isAchivableByStatus && isAchivableByEntity;

  const isPending = isPendingTask(task);
  const isDeclined = isDeclinedTask(task);
  const isPayoutFailed = isPayoutFailedTask(task);
  const isPayID =
    payments?.[0]?.status === PaymentStatus.PENDING_PAYID_TRANSFER;

  const isPayable =
    task?.type !== TaskType.SIGN_ONLY &&
    task?.settlementStatus !== TaskSettlementStatus.REFUNDABLE;

  const isDownloadable = task?.status !== TaskStatus.DRAFT;

  const tooltip = isInstallments
    ? installmentTooltip
    : isDeclined
    ? declinedToolTip
    : isScheduled
    ? scheduledToolTip
    : isPaid
    ? paidToolTip
    : dueToolTip;

  const canCopyLink =
    task?.status !== TaskStatus.SCHEDULED &&
    task?.status !== TaskStatus.DRAFT &&
    (task?.direction === TaskDirection.SENDING ||
      (task?.direction === TaskDirection.RECEIVING &&
        task.type === TaskType.SIGN_ONLY)) &&
    task?.status !== TaskStatus.COMPLETED;

  const isDraft = task?.status === TaskStatus.DRAFT;

  return {
    isPaid,
    isPayID,
    isPending,
    isScheduled,
    signatureRequiredTooltip,
    isDeclined,
    isInstallments,
    dueDate,
    getTaskPendingSignatureStatus,
    tooltip,
    isAchivable,
    isPendingSiganture,
    isDownloadable,
    isPayment,
    remainedAmount,
    totalInstallments,
    isPayoutFailed,
    isSigned,
    paidInstallments,
    isCreatedBy,
    scheduledAt,
    isPayable,
    canCopyLink,
    scheduledDate,
    invoiceStatus: task?.invoiceStatus,
    isDraft,
  };
};
