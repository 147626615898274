import { TaskDirection, TaskGuest } from '@admiin-com/ds-graphql';
import { WBFlex, WBTypography } from '@admiin-com/ds-web';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { HeaderSection } from './HeaderSection';

interface Props {
  taskGuest: TaskGuest;
  token?: string;
  children?: React.ReactNode;
}
export const GuestLayout = (props: Props) => {
  const theme = useTheme();
  const isQuote = props.taskGuest?.invoiceStatus === 'QUOTE';
  const isInvoice = props.taskGuest?.invoiceStatus === 'INVOICE';
  const { t } = useTranslation();
  const headerColor = theme.palette.mode === 'dark' ? 'white' : 'white';

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isSignPay = props.taskGuest.type === 'SIGN_PAY';

  return (
    <Container>
      <StyledWBFlex>
        <HeaderFlex>
          <CenteredTypography
            variant="h5"
            fontWeight="medium"
            color={headerColor}
          >
            {isSignPay
              ? `${
                  props.taskGuest.direction === TaskDirection.RECEIVING
                    ? 'Bill'
                    : 'Invoice'
                } from ${
                  props.taskGuest.futureLinkedTask?.from?.name ??
                  props.taskGuest.fromContact?.name ??
                  props.taskGuest.fromEntity?.name ??
                  ''
                }`
              : isQuote
              ? `Quote from ${props.taskGuest.fromEntity?.name}`
              : isInvoice
              ? `Invoice from ${props.taskGuest.fromEntity?.name}`
              : t('requestForSignature', { ns: 'taskbox' })}
          </CenteredTypography>
        </HeaderFlex>
        <WBFlex flexDirection="column" width="100%">
          <HeaderSection
            taskGuest={props.taskGuest}
            isQuote={isQuote}
            mobile={isMobile}
          />
        </WBFlex>
        {props.children}
      </StyledWBFlex>
    </Container>
  );
};

const Container = styled(WBFlex)(({ theme }) => ({
  background: theme.palette.background.default,
  height: '100vh',
}));

const StyledWBFlex = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  height: '100%',
  backgroundColor: theme.palette.background.default,
}));

const HeaderFlex = styled(WBFlex)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
  padding: theme.spacing(3, 8),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#151515' : '#151515',
}));

const CenteredTypography = styled(WBTypography)(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
  marginBottom: 0,
}));

export default GuestLayout;
