import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRowModesModel,
  GridSlotsComponentsProps,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Entity, LineItem, TaxType } from '@admiin-com/ds-graphql';
import {
  WBFlex,
  WBIconButton,
  WBLinkButton,
  WBTypography,
} from '@admiin-com/ds-web';
import { useTheme } from '@mui/material';
import { ServicesLookupEditCell } from '../ServicesLookup/ServicesLookupEditCell';
import { GridRowModel } from '@mui/x-data-grid';
import { GridRowsProp } from '@mui/x-data-grid';
import { useFormContext } from 'react-hook-form';
import { useSingleClickTableParams } from './useSingleClickTableParams';
import { numberToCurrency } from '@admiin-com/ds-common';
import { getDefaultGST } from '../../helpers/tasks';

interface Props {
  setRows: (rows: LineItem[]) => void;
  rows: LineItem[];
}

export default function LineItemsTable(props: Props) {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const theme = useTheme();

  const columns: GridColDef<(typeof initialRows)[number]>[] = [
    {
      field: 'id',
      headerName: '#',
      disableColumnMenu: true,
      flex: 0.1,
      sortable: false,
      renderHeader(params) {
        return (
          <WBTypography fontWeight={'bold'}>
            {params.colDef.headerName}
          </WBTypography>
        );
      },
    },
    {
      field: 'description',
      headerName: t('description', { ns: 'taskbox' }),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      description: 'Description of the service',
      renderHeader(params) {
        return (
          <WBTypography fontWeight={'bold'}>
            {params.colDef.headerName}
          </WBTypography>
        );
      },
      renderEditCell(params) {
        return (
          <ServicesLookupEditCell
            entityId={from?.id}
            {...params}
            value={params.value}
            onChange={(value) => {
              if (value.id) {
                apiRef.current.updateRows([
                  {
                    id: params.id,
                    description: value?.name || '',
                    qty: 1,
                    price: (value?.amount || 0) / 100,
                    GST: value?.taxType || '',
                    serviceId: value?.id || '',
                    newService: false,
                  },
                ]);
              } else
                apiRef.current.updateRows([
                  {
                    ...params,
                    newService: true,
                    serviceId: '',
                  },
                ]);
            }}
          />
        );
      },
    },
    {
      field: 'qty',
      headerName: t('qty', { ns: 'taskbox' }),
      type: 'number',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      editable: true,
      renderHeader(params) {
        return (
          <WBTypography fontWeight={'bold'}>
            {params.colDef.headerName}
          </WBTypography>
        );
      },
    },
    {
      field: 'price',
      headerName: t('price', { ns: 'taskbox' }),
      type: 'number',
      flex: 0.5,
      editable: true,
      disableColumnMenu: true,
      sortable: false,
      // valueGetter: (params) =>
      //   params.row.amount / (params.row.qty ?? 1) /
      //     (params.row.GST === TaxType.GST ? 1.1 : 1),
      renderHeader(params) {
        return (
          <WBTypography fontWeight={'bold'}>
            {params.colDef.headerName}
          </WBTypography>
        );
      },
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      field: 'GST',
      headerName: t('GST', { ns: 'taskbox' }),
      sortable: false,
      disableColumnMenu: true,
      type: 'singleSelect',
      align: 'right',
      headerAlign: 'right',
      editable: true,
      flex: 0.5,
      valueOptions: Object.values(TaxType)
        .map((value) => ({
          value,
          label: t(value, { ns: 'common' }),
        }))
        .filter((value) => value.value !== TaxType.GST_INCLUSIVE),
      renderHeader(params) {
        return (
          <WBTypography fontWeight={'bold'}>
            {params.colDef.headerName}
          </WBTypography>
        );
      },
    },
    {
      field: 'amount',
      headerName: t('amount', { ns: 'taskbox' }),
      sortable: false,
      editable: true,
      disableColumnMenu: true,
      flex: 0.5,
      align: 'right',
      headerAlign: 'right',
      renderHeader(params) {
        return (
          <WBTypography fontWeight={'bold'}>
            {params.colDef.headerName}
          </WBTypography>
        );
      },
      // valueGetter: (params) =>
      //   params.row.qty *
      //   params.row.price *
      //   (params.row.GST === TaxType.GST ? 1.1 : 1),
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      flex: 0.2,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <WBIconButton
          onClick={() => handleDelete(params.id as number)}
          icon="Trash"
          size="small"
          sx={{ display: 'none' }}
          className="delete-icon"
        />
      ),
    },
  ];

  const handleDelete = (id: number) => {
    const newRows = rows.filter((row) => row.id !== id);

    setRows(newRows);
    updatePropsRows(newRows);

    // Ensure DataGrid is aware of removed rows
    apiRef.current.updateRows([{ id, _action: 'delete' }]);
  };

  const { watch } = useFormContext();
  const from = watch('from') as Entity;

  const defaultGST = React.useMemo(() => getDefaultGST(from), [from]);
  const initialRows: GridRowsProp = [
    { id: 1, description: '', qty: 1, price: 0, GST: defaultGST, amount: 0 },
  ];
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const [rows, setRows] = React.useState(initialRows);
  const processRowUpdate = (newRow: GridRowModel) => {
    const existingRow = rows.find((row) => row.id === newRow.id);
    if (!existingRow) return newRow;

    let { price, qty, amount } = newRow;
    const { GST } = newRow;

    qty = qty <= 0 ? 1 : qty;

    if (newRow.price !== existingRow.price || newRow.qty !== existingRow.qty) {
      amount = price * qty * (GST === TaxType.GST ? 1.1 : 1);
    } else if (newRow.amount !== existingRow.amount) {
      price =
        Math.round((amount / qty / (GST === TaxType.GST ? 1.1 : 1)) * 100) /
        100;
    }

    const updatedRow = { ...newRow, price, qty, amount, isNew: false };
    const newRows = rows.map((row) =>
      row.id === newRow.id ? updatedRow : row
    );

    setRows(newRows);
    updatePropsRows(newRows);

    return updatedRow;
  };

  const handleAddNew = () => {
    const newRow = {
      id: rows.length + 1,
      description: '',
      qty: 1,
      price: 0,
      GST: defaultGST,
      amount: 0,
      newService: true,
    };
    const newRows = rows.concat(newRow);
    setRows(newRows);
    updatePropsRows(newRows);

    apiRef.current.setCellFocus(newRow.id, 'description');
  };

  const updatePropsRows = (rows: GridRowModel[]) => {
    props.setRows(
      rows.map((row) => ({
        description: row.description,
        quantity: row.qty,
        unitAmount: row.price,
        taxType: row.GST ?? defaultGST,
        lineAmount: row.price * row.qty * (row.GST === TaxType.GST ? 1.1 : 1),
        id: row.id,
        serviceId: row.serviceId,
        __typename: 'LineItem',
        newService: row.newService,
      }))
    );
  };

  React.useEffect(() => {
    if (props.rows.length > 0)
      setRows(
        props.rows.map((row, index) => ({
          description: row.description,
          qty: row.quantity,
          price: row.unitAmount,
          GST: row.taxType ?? defaultGST,
          amount: row.lineAmount,
          id: index + 1,
          serviceId: row.serviceId,
          newService: row.newService,
        }))
      );
  }, [props.rows, defaultGST]);

  const singleClickTableParams = useSingleClickTableParams();
  return (
    <DataGrid
      apiRef={apiRef}
      sx={{
        borderWidth: 0,
        '.MuiDataGrid-row:hover .delete-icon': {
          display: 'inline-flex',
        },
        '.MuiDataGrid-columnHeaders': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#464646' : '#EAEAEA',
        },
      }}
      rows={rows}
      // experimentalFeatures={{ newEditingApi: true }}
      columns={columns}
      rowModesModel={rowModesModel}
      onProcessRowUpdateError={(error) => {
        console.log('error', error);
      }}
      onRowModesModelChange={handleRowModesModelChange}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      processRowUpdate={processRowUpdate}
      slots={{
        footer: AddNewButton,
      }}
      slotProps={{
        footer: {
          onAddNew: handleAddNew,
        },
      }}
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      {...singleClickTableParams}
    />
  );
}

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    onAddNew: () => void;
  }
}

function AddNewButton(props: NonNullable<GridSlotsComponentsProps['footer']>) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <WBFlex mb={4} mt={2} ml={2}>
      <WBLinkButton
        onClick={props.onAddNew}
        color="primary.main"
        sx={{ ...theme.typography.button }}
      >
        {t('addNewItem', { ns: 'taskbox' })}
      </WBLinkButton>
    </WBFlex>
  );
}
