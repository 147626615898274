import {
  BankAccountType,
  BankHolderType,
  CSGetSelectedEntityId as GET_SELECTED_ENTITY_ID,
  deleteEntity as DELETE_ENTITY,
  Entity,
  CSGetEntity as GET_ENTITY,
  PaymentConsentStatus,
  PaymentMethod,
  TaskDirection,
} from '@admiin-com/ds-graphql';

import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useEntitySelect } from '../../features/hooks/useEntitySelect/useEntitySelect';

export const useSelectedEntity = () => {
  const entityId = useCurrentEntityId();
  const { data, loading, refetch } = useQuery(gql(GET_ENTITY), {
    variables: { id: entityId },
    skip: !entityId,
    notifyOnNetworkStatusChange: true,
  });

  // delete entity
  const [deleteEntity, { loading: deleteEntityLoading }] = useMutation(
    gql(DELETE_ENTITY),
    {
      variables: { input: { id: entityId } },
    }
  );

  const entity: Entity | undefined = React.useMemo(
    () => data?.getEntity,
    [data]
  );

  const apolloClient = useApolloClient();

  const addPaymentMethodToCache = React.useCallback(
    (newPaymentMethod: PaymentMethod) => {
      const mockPaymentMethod: Partial<PaymentMethod> = {
        __typename: 'PaymentMethod',
        card: '{}',
        applepay: '{}',
        googlepay: '{}',
        holderType: BankHolderType.business,
        consentStatus: PaymentConsentStatus.VERIFIED,
        consentId: uuidv4(),
        routingNumber: '',
        accountNumber: '',
        bankName: '',
        accountName: '',
        accountType: BankAccountType.checking,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        expiresAt: new Date().toISOString(),
      };

      const paymentMethod: PaymentMethod = {
        ...mockPaymentMethod,
        ...newPaymentMethod,
      };
      const updatedEntity = cloneDeep(entity);
      if (!updatedEntity) return;
      updatedEntity.paymentMethods?.items?.push(paymentMethod);
      updatedEntity.paymentMethodId = paymentMethod.id;
      apolloClient.writeQuery({
        query: gql(GET_ENTITY),
        variables: { id: entityId },
        data: { getEntity: updatedEntity },
      });
    },
    [entity]
  );

  return {
    entity,
    deleteEntity,
    deleteEntityLoading,
    loading,
    refetch,
    addPaymentMethodToCache,
  };
};

export const useCurrentEntityId = () => {
  const { data: selectedEntityIdData } = useQuery(gql(GET_SELECTED_ENTITY_ID));
  const entityId = selectedEntityIdData?.selectedEntityId;

  return entityId;
};

export const useGotoTaskBox = () => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const selectEntity = useEntitySelect();

  const gotoTaskBox = React.useCallback(
    (id: string, direction: TaskDirection = TaskDirection.RECEIVING) => {
      apolloClient.cache.evict({ fieldName: 'selectedEntityId' });
      apolloClient.cache.gc();
      selectEntity(id);
      navigate(`/taskbox?direction=${direction}&status=Due`);
    },
    []
  );
  return gotoTaskBox;
};
