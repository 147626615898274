import { AccountStatus } from '@admiin-com/ds-graphql';
import {
  useSnackbar,
  WBBox,
  WBButton,
  WBFlex,
  WBTooltip,
  WBTypography,
} from '@admiin-com/ds-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../components';
import BusinessForm from '../../components/BusinessForm/BusinessForm';
import ConfirmationDlg from '../../components/ConfirmationDlg/ConfirmationDlg';
import { ConnectDisconnectXero } from '../../components/ConnectDisconnectXero';
import { useFeatureFlags } from '../../hooks/useFeatureFlags/useFeatureFlags';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { useUserId } from '../../hooks/useCurrentUser/useCurrentUser';
import { AlertColor } from '@mui/material';
import { ContactsCreateForm } from '../ContactDetail/ContactsCreateForm';
import ContactForm from '../../components/ContactForm/ContactForm';

const Business = () => {
  const { t } = useTranslation();
  const { featureFlags } = useFeatureFlags();
  const { entity, deleteEntity, deleteEntityLoading } = useSelectedEntity();
  const showSnackbar = useSnackbar();
  const [showConfirmDelete, setShowConfirmDelete] =
    React.useState<boolean>(false);
  const userId = useUserId();

  const handleDeleteEntity = async () => {
    try {
      await deleteEntity();
      showSnackbar({
        message: t('entityDeleted', { ns: 'settings' }),
        severity: 'success',
        horizontal: 'right',
        vertical: 'bottom',
      });
    } catch (err: any) {
      console.error(err);
      showSnackbar({
        message: err?.message,
        severity: 'error' as AlertColor,
        horizontal: 'right',
        vertical: 'bottom',
      });
    }
  };

  return (
    <>
      <ConfirmationDlg
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onOK={handleDeleteEntity}
        title={t('deleteEntity', { ns: 'settings' })}
        loading={deleteEntityLoading}
      >
        <WBTypography variant="body1" mt={1}>
          {t('deleteEntityDescription', {
            ns: 'settings',
            entityName: entity?.name,
          })}
        </WBTypography>
      </ConfirmationDlg>
      <PageContainer sx={{ p: 0 }}>
        <WBFlex
          flexDirection={'column'}
          justifyContent={'space-between'}
          minHeight="100%"
          p={[3, 5, 8]}
          pt={{ xs: 10, sm: 10, md: 10, lg: 8 }}
          sx={{
            position: 'relative',
            backgroundColor: 'background.paper',
            maxWidth: '100%',
          }}
        >
          <WBTypography variant="h2">
            {t('businessInformationTitle', { ns: 'settings' })}
          </WBTypography>
          <BusinessForm />
          <WBTooltip
            title={
              entity?.owner === userId
                ? t('onlyOwnerDeleteEntity', { ns: 'settings' })
                : entity?.accountStatus !== AccountStatus.CREATED
                ? t('contactToDeleteEntity', { ns: 'settings' })
                : ''
            }
          >
            <WBBox alignSelf="flex-start">
              <WBButton
                sx={{ mt: 3 }}
                color="error"
                disabled={entity?.accountStatus !== AccountStatus.CREATED}
                onClick={() => setShowConfirmDelete(true)}
              >
                {t('deleteEntity', { ns: 'settings' })}
              </WBButton>
            </WBBox>
          </WBTooltip>
          {featureFlags?.xero && (
            <>
              <WBTypography variant="h2" pt={{ xs: 10, lg: 8 }}>
                {t('integrations', { ns: 'settings' })}
              </WBTypography>
              <WBBox>
                <ConnectDisconnectXero />
              </WBBox>
            </>
          )}
        </WBFlex>
      </PageContainer>
    </>
  );
};

export default Business;
