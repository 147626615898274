import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { WBDrawer, WBFlex, WBIcon, WBIconButton } from '@admiin-com/ds-web';
import NavList from './components/Sidebar/NavList';
import TopBar from './components/TopBar';

interface Props {
  children: React.ReactNode;
}
function MainDrawerLayout({ children }: Props) {
  const theme = useTheme();
  const [minimized, setMinimized] = React.useState(false);

  const handleDrawerToggle = () => {
    setMinimized((open) => !open);
  };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpenToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
      <TopBar open={open} onChange={() => handleDrawerOpenToggle()} />
      <WBFlex marginTop={10} flexGrow={1}>
        <Drawer
          variant="permanent"
          open={minimized}
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <DrawerButton onClick={handleDrawerToggle}>
            {!minimized ? (
              <WBIcon
                name="ChevronForward"
                color={theme.palette.common.white}
              />
            ) : (
              <WBIcon name="ChevronBack" color={theme.palette.common.white} />
            )}
          </DrawerButton>
          <NavList opened={minimized} />
        </Drawer>
        <WBDrawer
          variant="temporary"
          ModalProps={{ keepMounted: true }}
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { pt: 10 },
          }}
        >
          <NavList opened onNavigated={() => setOpen(false)} />
        </WBDrawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          {children}
        </Box>
      </WBFlex>
    </Box>
  );
}

export default MainDrawerLayout;
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowY: 'visible',
  position: 'relative',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowY: 'visible',
  position: 'relative',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerButton = styled(WBIconButton)(({ theme }) => ({
  // necessary for content to be below app bar
  width: theme.spacing(4),
  height: theme.spacing(4),
  position: 'absolute',
  top: 15,
  right: 0,
  transform: 'translateX(50%)',
  backgroundColor: theme.palette.primary.main,
  zIndex: theme.zIndex.modal + 1,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflowX: 'visible',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));
