import { fetchUserAttributes } from 'aws-amplify/auth';
import React from 'react';
import { useCurrentUser } from '../useCurrentUser/useCurrentUser';

export const useFirmId = () => {
  const [firmId, setFirmId] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const checkFirmIdFromCognito = async () => {
    setLoading(true);
    const attributes = await fetchUserAttributes();
    if (attributes?.['custom:firmId']) {
      setFirmId(attributes?.['custom:firmId']);
    } else setFirmId('');
    setLoading(false);
  };

  const user = useCurrentUser();

  // React.useEffect(() => {
  //   checkFirmIdFromCognito();
  // }, []);

  return {
    loading: Object.keys(user).length === 0,
    firmId: user?.firmId,
    isNotFirm: Object.keys(user).length !== 0 && !user?.firmId,
  };
  // return { loading: loading || firmId === null, firmId };
};
