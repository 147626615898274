import { useSnackbar } from '@admiin-com/ds-web';
import { useMediaQuery, useTheme } from '@mui/material';
import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import ESignatureDesktop from './ESignatureDesktop';
import ESignatureMobile from './ESignatureMobile';
import { checkAnnotationDirection } from '../../helpers/signature';
import { useTaskCreationContext } from '../../pages/TaskCreation/TaskCreation';

export interface ESignatureProps {
  documentUrl: string;
  annotations?: any;
  userId?: string;
  onPdfLoad?: () => void;
  handleDraft?: () => Promise<void>;
  handleNext?: () => Promise<void>;
}

export const ESignature = forwardRef(
  (
    {
      documentUrl,
      onPdfLoad,
      annotations,
      userId,
      handleDraft,
      handleNext,
    }: ESignatureProps,
    instanceRef: any
  ) => {
    const { setTaskDirection, taskDirection, task } = useTaskCreationContext();
    const { setValue } = useFormContext();

    const saveAnnotations = async () => {
      try {
        const allAnnotations = await Promise.all(
          Array.from({ length: instanceRef.current?.totalPageCount }).map(
            (_, pageIndex) => instanceRef.current.getAnnotations(pageIndex)
          )
        );

        const flattenedAnnotations = allAnnotations.flat();
        const instantJSON = await instanceRef.current.exportInstantJSON(
          flattenedAnnotations
        );
        const annotationsString = JSON.stringify(instantJSON);
        setValue('annotations', annotationsString);

        const newTaskDirection = checkAnnotationDirection(annotationsString);
        setTaskDirection(newTaskDirection);
        if (taskDirection !== newTaskDirection && !task) {
          setValue('from', null);
        }

        return annotationsString;
      } catch (e: any) {
        showSnackbar({
          message: e.message,
          severity: 'error',
          horizontal: 'right',
          vertical: 'bottom',
        });
        throw e;
      }
    };
    const [dropped, setDropped] = React.useState(false);

    const updateDropped = async () => {
      const allAnnotations = await Promise.all(
        Array.from({ length: instanceRef.current?.totalPageCount }).map(
          (_, pageIndex) => instanceRef.current.getAnnotations(pageIndex)
        )
      );

      const flattenedAnnotations = allAnnotations.flat();
      // console.log(flattenedAnnotations);
      const instantJSON = await instanceRef.current.exportInstantJSON(
        flattenedAnnotations
      );
      console.log(instantJSON?.annotations?.length);
      if (instantJSON?.annotations?.length > 0) {
        setDropped(true);
      } else {
        setDropped(false);
      }
    };

    const showSnackbar = useSnackbar();
    const onDraft = async () => {
      try {
        await saveAnnotations();
        if (handleDraft) await handleDraft();
      } catch (e: any) {
        console.log(e?.message);
      }
    };

    const onNext = async () => {
      try {
        await saveAnnotations();
        if (handleNext) await handleNext();
      } catch (e: any) {
        console.log(e?.message);
      }
    };

    const [droppedSignature, setDroppedSignature] = React.useState<any>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return !isMobile ? (
      <ESignatureDesktop
        handleDraft={onDraft}
        annotations={annotations}
        userId={userId}
        onPdfLoad={updateDropped}
        handleNext={onNext}
        droppedSignature={droppedSignature}
        onDropped={setDroppedSignature}
        ref={instanceRef}
        documentUrl={documentUrl}
        onAnnotationUpdated={updateDropped}
        disableNext={!dropped}
      />
    ) : (
      <ESignatureMobile
        handleDraft={onDraft}
        annotations={annotations}
        userId={userId}
        onPdfLoad={updateDropped}
        handleNext={onNext}
        droppedSignature={droppedSignature}
        onDropped={setDroppedSignature}
        ref={instanceRef}
        documentUrl={documentUrl}
        onAnnotationUpdated={updateDropped}
        disableNext={!dropped}
      />
    );
  }
);
export default ESignature;
