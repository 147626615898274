import { DialogActions, DialogTitle, useTheme } from '@mui/material';
import Gleap from 'gleap';
import { useTranslation } from 'react-i18next';
import {
  WBButton,
  WBFlex,
  WBLink,
  WBSvgIcon,
  WBTypography,
} from '@admiin-com/ds-web';
import SecurityIcon from '../../../assets/icons/security.svg';
import VerificationIcon from '../../../assets/icons/verfication.svg';
import VerificationGreyIcon from '../../../assets/icons/verfication-grey.svg';
import VerificationWarningIcon from '../../../assets/icons/verfication-warning.svg';
import React from 'react';
import { AccountStatus, Entity } from '@admiin-com/ds-graphql';

export interface VerificationStartProps {
  entity?: Entity | null;
  onSuccess: () => void;
  onClose: () => Promise<void>;
  isOnboarding?: boolean;
  isClients?: boolean;
}

export function VerificationStart({
  entity,
  onSuccess,
  isOnboarding,
  onClose,
  isClients,
}: VerificationStartProps) {
  const { t } = useTranslation();

  const [closeLoading, setCloseLoading] = React.useState(false);

  const handleClose = async () => {
    if (closeLoading) return;
    setCloseLoading(true);
    await onClose();
    setCloseLoading(false);
  };

  const theme = useTheme();

  return (
    <WBFlex flex={1} flexDirection="column">
      <WBFlex justifyContent="center">
        <WBSvgIcon
          fontSize="large"
          viewBox="0 0 40 40"
          sx={{ width: '2em', height: '2em' }}
        >
          {entity?.accountStatus === AccountStatus.CREATED && <SecurityIcon />}
          {entity?.accountStatus === AccountStatus.SUBMITTED && (
            <VerificationGreyIcon />
          )}
          {entity?.accountStatus === AccountStatus.SUSPENDED && (
            <VerificationWarningIcon />
          )}
          {entity?.accountStatus === AccountStatus.ACTION_REQUIRED && (
            <VerificationWarningIcon />
          )}
          {entity?.accountStatus === AccountStatus.ACTIVE && <SecurityIcon />}
        </WBSvgIcon>
      </WBFlex>
      <DialogTitle
        variant="h3"
        fontWeight={'bold'}
        component={'div'}
        textAlign="center"
      >
        {t(
          isOnboarding ? 'KYCOnboardingTitle' : `KYC${entity?.accountStatus}`,
          {
            ns: 'common',
          }
        )}
        <WBTypography
          variant="body1"
          mt={3}
          textAlign="center"
          sx={{ whiteSpace: 'pre-line' }}
        >
          {t(
            isOnboarding
              ? 'KYCOnboardingSubtitle'
              : `KYC${entity?.accountStatus}Subtitle`,
            {
              ns: 'common',
            }
          )}
        </WBTypography>
      </DialogTitle>
      <DialogActions sx={{ flexDirection: 'column' }}>
        {entity?.accountStatus === AccountStatus.CREATED && (
          <WBButton
            fullWidth
            color="success"
            sx={{ color: 'black', py: 1.5, fontWeight: 'bold', mb: 2 }}
            onClick={() => {
              onSuccess();
            }}
          >
            <WBSvgIcon fontSize="small" sx={{ mr: 1 }}>
              <VerificationIcon />
            </WBSvgIcon>
            {t('startVerificationProcess', { ns: 'common' })}
          </WBButton>
        )}
        {entity?.accountStatus === AccountStatus.SUSPENDED && (
          <WBButton
            fullWidth
            color="warning"
            sx={{ color: 'black', py: 1.5, fontWeight: 'bold', mb: 2 }}
            onClick={() => {
              const isOpened = Gleap.isOpened();
              if (!isOpened) Gleap.open();
              else Gleap.close();
              Gleap.showFeedbackButton(true);
            }}
          >
            {t('contactSupport', { ns: 'common' })}
          </WBButton>
        )}
        {!isClients && (
          <>
            {!isOnboarding ? (
              <WBButton
                fullWidth
                variant="text"
                sx={{ color: 'text.primary' }}
                onClick={handleClose}
                loading={closeLoading}
              >
                {t('close', { ns: 'common' })}
              </WBButton>
            ) : (
              <WBLink
                variant="body2"
                sx={{ mt: 5 }}
                underline="always"
                color={theme.palette.text.primary}
                onClick={() => {
                  handleClose();
                }}
              >
                {t('doThisLater', { ns: 'common' })}
              </WBLink>
            )}
          </>
        )}
      </DialogActions>
    </WBFlex>
  );
}

export default VerificationStart;
