import {
  AutocompleteResult,
  autocompleteResultsByType,
  AutocompleteType,
} from '@admiin-com/ds-graphql';
import { gql, useQuery } from '@apollo/client';

export const useAto = () => {
  const { data: autoCompelets } = useQuery(gql(autocompleteResultsByType), {
    variables: {
      type: AutocompleteType.ENTITY,
      searchName: 'australian tax office (ato)',
    },
  });

  const results = autoCompelets?.autocompleteResultsByType?.items || [];
  const ato = results.find(
    (item: AutocompleteResult) => item.metadata?.billerCode === 75556
  );
  return ato;
};
