import { WBS3Avatar } from '@admiin-com/ds-amplify-web';
import {
  WBBox,
  WBFlex,
  WBIcon,
  WBIconButton,
  WBTypography,
} from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import { getInitials } from 'apps/react-app/src/app/helpers/string';

export const AvatarMenu = ({
  showName,
  name,
}: {
  showName?: boolean;
  name?: string;
}) => {
  const userName = name || 'Johne Doe';

  return (
    <Button>
      <Avatar>{getInitials(userName)}</Avatar>
      {showName ? (
        <WBFlex alignItems={'center'}>
          <WBTypography
            ml={1}
            mr={2}
            fontWeight={700}
            color="text.primary"
            noWrap
          >
            {userName}
          </WBTypography>
          <WBIcon name="ChevronDown" size={'small'} />
        </WBFlex>
      ) : null}
    </Button>
  );
};

const Avatar = styled(WBTypography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  padding: theme.spacing(1, 1.2),
  borderRadius: '100%',
  fontWeight: 700,
}));

const Button = styled(WBFlex)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0),
  },
}));
