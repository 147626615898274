import { WBBox, WBTypography } from '@admiin-com/ds-web';

interface Props {
  title: string;
  value: string;
  error?: boolean;
}
export const TaskProperty = (props: Props) => {
  return (
    <WBBox>
      <WBTypography fontWeight={'inherit'} fontSize={'inherit'}>
        {props.title}
      </WBTypography>
      <WBTypography
        noWrap
        fontWeight={'inherit'}
        fontSize={'inherit'}
        bgcolor={'background.paper'}
        p={1}
        px={2}
        mt={2}
        minWidth={115}
        sx={{
          borderWidth: 2,
          borderColor: props.error ? 'error.main' : 'transparent',
          borderStyle: 'solid',
        }}
      >
        {props.value}
      </WBTypography>
    </WBBox>
  );
};
