import VerificationDlg from '../../pages/VerificationDlg/VerificationDlg';
import LottieAlert from '../LottieAlert/LottieAlert';
import { InvoiceCreateForm } from './InvoiceCreateForm';
import OnboardingMessage from './OnboardingMessage';
import { useTasks } from '../../hooks/useTasks/useTasks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskCreationContext } from '../../pages/TaskCreation/TaskCreation';
import { useFormContext } from 'react-hook-form';
import { AccountStatus, TaskType } from '@admiin-com/ds-graphql';
import { WBLink } from '@admiin-com/ds-web';
import { PATHS } from '../../navigation/paths';

export const InvoiceCreateFormContainer = () => {
  const { t } = useTranslation();
  const { client } = useTaskCreationContext();
  const { tasks } = useTasks({
    entityId: client?.id,
  });
  const { watch } = useFormContext();
  const { from } = watch();
  const [showVerify, setShowVerify] = useState<boolean>(false);

  const { task } = useTaskCreationContext();

  const [isOnboarding, setIsOnboarding] = React.useState(true);

  React.useEffect(() => {
    if (tasks.length === 0) {
      setIsOnboarding(true);
    } else setIsOnboarding(false);
  }, [tasks]);

  React.useEffect(() => {
    if (task && task.status === 'DRAFT') setIsOnboarding(false);
  }, [task]);

  const { setTaskDirection } = useTaskCreationContext();
  const { setValue } = useFormContext();

  React.useEffect(() => {
    setTaskDirection('SENDING');
    setValue('type', TaskType.PAY_ONLY);
  }, [setValue, setTaskDirection]);

  return isOnboarding ? (
    <OnboardingMessage
      segment={[0, 30]}
      lottiePath={'/lottie/send-invoice.lottie'}
      onGetStarted={() => setIsOnboarding(false)}
      title={t('sendInvoiceTitle', { ns: 'taskbox' })}
      description={
        <>
          {t('sendInvoiceMessage1', { ns: 'taskbox' })}
          <WBLink
            href={PATHS.admiinAccount}
            target="_blank"
            color="primary.main"
          >
            {t('admiinAccount', { ns: 'taskbox' })}
          </WBLink>
          {t('sendInvoiceMessage2', { ns: 'taskbox' })}
        </>
      }
      buttonTitle={t('getStarted', { ns: 'taskbox' })}
    />
  ) : (
    <>
      {from?.accountStatus === AccountStatus.CREATED ||
      from?.accountStatus === AccountStatus.ACTION_REQUIRED ? (
        <VerificationDlg
          entity={from}
          onSuccess={() => {
            console.log('success');
          }}
          open={showVerify}
          onClose={() => setShowVerify(false)}
        />
      ) : null}
      {from &&
        from?.taxNumber &&
        from?.accountStatus !== AccountStatus.ACTIVE && (
          <LottieAlert
            lottiePath="/lottie/exclamation.lottie"
            title={t(`KYC${from.accountStatus}`, { ns: 'common' })}
            message={t(`INVOICE_QUOTE_${from?.accountStatus}_Description`, {
              ns: 'common',
            })}
            btnText={
              from?.accountStatus === AccountStatus.CREATED ||
              from?.accountStatus === AccountStatus.ACTION_REQUIRED
                ? t('completeVerification', { ns: 'common' })
                : undefined
            }
            onClick={
              from?.accountStatus === AccountStatus.CREATED ||
              from?.accountStatus === AccountStatus.ACTION_REQUIRED
                ? () => setShowVerify(true)
                : undefined
            }
            isIn={true}
          />
        )}
      <InvoiceCreateForm />
    </>
  );
};
