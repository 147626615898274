import {
  AutocompleteResult,
  Contact,
  CreateContactInput,
  CreateTaskInput,
  CreateTaskStatus,
  Entity,
  FromToType,
  PaymentFrequency,
  PaymentType,
  selectedEntityIdInVar,
  Task,
  TaskDirection,
  TaskType,
  UpdateContactInput,
} from '@admiin-com/ds-graphql';
import { useSnackbar } from '@admiin-com/ds-web';
import { useApolloClient } from '@apollo/client';
import { useMediaQuery, useTheme } from '@mui/material';
import { FieldErrors, FieldValues, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreateContact } from '../../../hooks/useCreateContact/useCreateContact';
import { useCreateTask } from '../../../hooks/useCreateTask';
import { useUpdateContact } from '../../../hooks/useUpdateContact/useUpdateContact';
import { Bill } from '../PayBillCreateForm';
import { BillDraftSchema, BillSchema } from './schema';
import {
  transformDocumentAnalysisLineItemInput,
  transformS3UploadInput,
} from './transformer';
import { useEntitySelect } from '../../../features/hooks/useEntitySelect/useEntitySelect';

export const useBillCreate = ({
  onErrorRow,
}: {
  onErrorRow: ({
    index,
    errors,
  }: {
    index: number;
    errors: { path: string; message: string }[];
    fieldErrors?: FieldErrors<FieldValues>;
  }) => void;
}) => {
  // const { taskProp } = useTaskCreationContext();

  // const drafted = !!taskProp;
  const [createContact] = useCreateContact();
  const {
    trigger,
    formState: { errors },
  } = useFormContext();

  const [updateContact] = useUpdateContact();
  const [createTask] = useCreateTask();

  const billAdapter = async (bill: Bill): Promise<CreateTaskInput> => {
    const {
      from,
      to,
      amount,
      dueAt,
      paymentFrequency,
      issuedAt,
      lineItems,
      reference,
      document,
      documentAnalysis,
      saveBpayRefForFuture,
      bpay,
      bank,
    } = bill;
    console.log(bill);
    let fromType: FromToType = FromToType.ENTITY;

    let newFrom: Contact | Entity | null | AutocompleteResult | undefined =
      null;
    if (from && to)
      if (!('id' in from)) {
        const createContactInput: CreateContactInput = {
          firstName: from.firstName ?? '',
          lastName: from.lastName ?? '',
          email: from.email ?? null,
          phone: from.phone ?? null,
          name: from.name ?? '',
          taxNumber: from.taxNumber,
          entityId: to.id,
          bank:
            bank && bank.accountNumber && bank.routingNumber
              ? {
                  bankName: from.name ?? null,
                  accountName: from.name ?? '',
                  accountNumber: bank.accountNumber ?? '',
                  routingNumber: bank.routingNumber ?? '',
                }
              : null,
          bpay:
            bpay && bpay.billerCode
              ? // (bpay.referenceNumber && saveBpayRefForFuture)
                {
                  billerCode: bpay.billerCode ?? null,
                  referenceNumber: bpay.referenceNumber ?? null,
                }
              : null,
        };
        const createdContactData = await createContact({
          variables: {
            input: createContactInput,
          },
        });
        newFrom = createdContactData.data.createContact;
        fromType = FromToType.CONTACT;
      } else {
        if (
          'type' in from &&
          (from.type === ('ENTITY' as any) || from.type === ('BPAY' as any))
        ) {
          newFrom = from;
          fromType = FromToType.ENTITY;
        } else {
          fromType = FromToType.CONTACT;
          if (bpay && saveBpayRefForFuture) {
            const isModified =
              bpay.billerCode !==
                documentAnalysis?.matchedContact?.bpay?.billerCode ||
              bpay.referenceNumber !==
                documentAnalysis?.matchedContact?.bpay?.referenceNumber;
            if (isModified) {
              const updateContactInput: UpdateContactInput = {
                id: from.id,
                bpay: {
                  billerCode: bpay.billerCode ?? null,
                  referenceNumber: bpay.referenceNumber ?? null,
                },
                entityId: (from as Contact).entityId,
              };
              const updatedContact = await updateContact({
                variables: {
                  input: updateContactInput,
                },
              });
              newFrom = updatedContact.data?.updateContact;
            } else {
              newFrom = from;
            }
          } else if (bank) {
            const isModified =
              bank.accountNumber !==
                documentAnalysis?.matchedContact?.bank?.accountNumber ||
              bank.routingNumber !==
                documentAnalysis?.matchedContact?.bank?.routingNumber;

            const newBank = {
              bankName: (from as Contact).bank?.bankName ?? null,
              holderType: (from as Contact).bank?.holderType ?? null,
              accountType: (from as Contact).bank?.accountType ?? null,
              country: (from as Contact).bank?.country ?? null,
              accountName:
                (from as Contact).bank?.accountName || from.name || '',
              accountNumber:
                bank.accountNumber ??
                (from as Contact).bank?.accountNumber ??
                '',
              routingNumber:
                bank.routingNumber ??
                (from as Contact).bank?.routingNumber ??
                '',
            };

            const fullDetailProvided =
              newBank.accountNumber && newBank.routingNumber;
            if (isModified && fullDetailProvided) {
              const updateContactInput: UpdateContactInput = {
                id: from.id,
                entityId: (from as Contact).entityId,
                bank: newBank,
              };
              const updatedContact = await updateContact({
                variables: {
                  input: updateContactInput,
                },
              });
              newFrom = updatedContact.data?.updateContact;
            } else {
              newFrom = from;
            }
          } else {
            newFrom = from;
          }
        }
      }
    else {
      newFrom = from as any;
    }

    if (!newFrom || !('id' in newFrom)) {
      console.log('From is required');
      throw new Error('From is required');
    }
    const isTax =
      (newFrom as AutocompleteResult)?.metadata?.subCategory === 'TAX';

    const task: CreateTaskInput = {
      fromId: newFrom?.id,
      fromType: fromType,
      toType: FromToType.ENTITY,
      toId: to?.id,
      amount: parseInt(((amount ?? 0) * 100)?.toString() ?? '0'),
      dueAt: dueAt,
      paymentTypes: [
        PaymentType.PAY_NOW,
        PaymentType.SCHEDULED,
        ...(isTax &&
        (paymentFrequency ?? PaymentFrequency.ONCE) === PaymentFrequency.ONCE
          ? [PaymentType.INSTALLMENTS]
          : []),
      ],
      issuedAt: issuedAt,
      documentAnalysisId: documentAnalysis?.id,
      lineItems: lineItems.map(transformDocumentAnalysisLineItemInput),
      reference: reference ?? '',
      documents: [transformS3UploadInput(document)],
      type: TaskType.PAY_ONLY,
      paymentFrequency: paymentFrequency ?? PaymentFrequency.ONCE,
      isUnlimitedRecurring: paymentFrequency !== PaymentFrequency.ONCE, //TODO: support number of payments - old task logic
      direction: TaskDirection.RECEIVING,
      bpayReferenceNumber:
        (bpay?.billerCode ? bpay?.referenceNumber : null) ?? null,
    };

    return task;
  };

  const showSnackbar = useSnackbar();

  const createBill = async (bill: Bill, isDraft = false): Promise<Task> => {
    const task = await billAdapter(bill);
    if (isDraft) {
      task.status = CreateTaskStatus.DRAFT;
    }
    const createdTask = await createTask({ variables: { input: task } });
    return createdTask.data?.createTask;
  };
  const createBills = async (bills: Bill[]) => {
    let noValidationErrors = false;
    try {
      let index = 0;

      for (const bill of bills) {
        console.log(bill);
        if (!(await trigger(`bills.${index}`))) {
          onErrorRow({
            index,
            errors: [],
            fieldErrors: errors,
          });
          throw new Error(`Invalid bill ${index}`);
        }
        const result = BillSchema.safeParse(bill);

        if (!result.success) {
          // Extract and format detailed error messages
          const errors = result.error.errors
            .map((err) => `${err.path.join('.')} - ${err.message}`)
            .join('; ');
          onErrorRow({
            index,
            errors: result.error.errors.map((err) => ({
              path: err.path.join('.'),
              message: err.message,
            })),
          });
          throw new Error(`Invalid bill ${index}: ${errors}`);
        }
        index++;
      }
      noValidationErrors = true;
      const tasks: Task[] = [];
      for (const bill of bills) {
        if (bill.checked) {
          const task = await createBill(bill);
          tasks.push(task);
        }
      }
      showSnackbar({
        message: 'Bills created successfully',
        severity: 'success',
        horizontal: 'right',
        vertical: 'bottom',
      });
      setTimeout(async () => {
        await postCreateBills(tasks);
      }, 1000);
    } catch (error: any) {
      if (noValidationErrors) {
        showSnackbar({
          message: error?.message ?? 'Failed to create bills',
          severity: 'error',
          horizontal: 'right',
          vertical: 'bottom',
        });
      }
      throw error;
    }
  };
  const navigate = useNavigate();
  const postCreateBills = async (bills: Task[]) => {
    if (bills.length === 1) {
      await postCreateBill(bills[0]);
    } else {
      navigate(`/taskbox?direction=${TaskDirection.RECEIVING}&status=Due`);
    }
  };
  const apolloClient = useApolloClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const selectEntity = useEntitySelect();
  const postCreateBill = async (task: Task) => {
    apolloClient.cache.evict({ fieldName: 'selectedEntityId' });
    apolloClient.cache.gc();
    selectEntity(task.entityId);
    if (!isMobile)
      navigate(
        `/taskbox/${task?.id ?? ''}?direction=${task.direction}&status=Due`
      );
    else navigate(`/taskbox?direction=${task.direction}&status=Due`);
  };
  const createBillsDraft = async (bills: Bill[]) => {
    let index = 0;

    for (const bill of bills) {
      console.log(bill);
      if (
        !(await trigger(`bills.${index}.from`)) ||
        !(await trigger(`bills.${index}.to`)) ||
        !(await trigger(`bills.${index}.dueAt`))
      ) {
        onErrorRow({
          index,
          errors: [],
          fieldErrors: errors,
        });
        throw new Error(`Invalid bill ${index}`);
      }
      const result = BillDraftSchema.safeParse(bill);

      if (!result.success) {
        // Extract and format detailed error messages
        const errors = result.error.errors
          .map((err) => `${err.path.join('.')} - ${err.message}`)
          .join('; ');
        onErrorRow({
          index,
          errors: result.error.errors.map((err) => ({
            path: err.path.join('.'),
            message: err.message,
          })),
        });
        throw new Error(`Invalid bill ${index}: ${errors}`);
      }
      index++;
    }
    const tasks: Task[] = [];
    for (const bill of bills) {
      if (bill.checked) {
        const task = await createBill(bill, true);
        tasks.push(task);
      }
    }
    setTimeout(async () => {
      await postCreateBills(tasks);
    }, 1000);
  };
  return {
    createBillsDraft,
    createBills,
  };
};
