import { Entity } from '@admiin-com/ds-graphql';
import React from 'react';
import SimpleDrawDlg from '../../components/SimpleDrawDlg/SimpleDrawDlg';
import VerificationScreen from '../VerificationScreen';

export interface VerificationDlgProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  entity: Entity;
}

export type VerificationStep =
  | 'Start'
  | 'Business'
  | 'BeneficialOwner'
  | 'Complete';

export function VerificationDlg({
  open,
  onClose,
  entity,
  onSuccess,
}: VerificationDlgProps) {
  const [verificationStep, setVerficationStep] =
    React.useState<VerificationStep>('Start');

  const [reset, setReset] = React.useState<boolean>(false);

  const handleClose = () => {
    onClose();
    setReset(true);
  };

  React.useEffect(() => {
    if (open)
      if (reset) {
        setVerficationStep('Start');
        setReset(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (verificationStep === 'Complete') {
      onSuccess && onSuccess();
    }
  }, [verificationStep]);

  const handleSuccess = () => {
    if (verificationStep === 'Start') {
      setVerficationStep('Business');
    } else if (verificationStep === 'Business') {
      setVerficationStep('Complete');
    } else if (verificationStep === 'Complete') {
      onSuccess();
    }
  };

  return (
    <SimpleDrawDlg
      open={open}
      handleClose={handleClose}
      fullWidth
      dialogSx={{ height: '100%' }}
      PaperPropsSx={{ width: '100%', height: '100%' }}
    >
      <VerificationScreen
        entity={entity}
        verificationStep={verificationStep}
        onClose={handleClose}
        onSuccess={handleSuccess}
      />
    </SimpleDrawDlg>
  );
}

export default VerificationDlg;
