import {
  S3UploadLevel,
  TaskGuest,
  TaskSignatureStatus,
} from '@admiin-com/ds-graphql';
import { AnnotationsUnion } from 'pspdfkit';
import { PdfPlaceholderV2 } from '../../components/PdfPlaceholder/PdfPlaceholder';
import {
  createCustomSignatureNode,
  downloadPdf,
  uploadPdfToSignedUrl,
} from '../../helpers/signature';
import React from 'react';
import { deleteFromS3Storage, uploadToS3Storage } from '@admiin-com/ds-amplify';
import { v4 as uuidv4 } from 'uuid';
import { getUrl, GetUrlWithPathInput } from 'aws-amplify/storage';
import { S3UploadType } from '@admiin-com/ds-common';

const { VITE_PSPDFKIT_KEY, VITE_PSPDFKIT_LOCALHOST_KEY } = import.meta.env;
const PSPDFKIT_KEY =
  window.location.hostname === 'localhost'
    ? VITE_PSPDFKIT_LOCALHOST_KEY
    : VITE_PSPDFKIT_KEY;

const renderConfigurations: Record<any, any> = {};

const getAnnotationRenderers = ({
  annotation,
}: {
  annotation: AnnotationsUnion;
}) => {
  // Use cached render configuration
  if (renderConfigurations[annotation.id]) {
    return renderConfigurations[annotation.id];
  }

  const { customData } = annotation;
  const placeholderUi = <PdfPlaceholderV2 customData={customData} />;
  const customNode = createCustomSignatureNode(placeholderUi, customData);

  renderConfigurations[annotation.id] = {
    node: customNode,
    append: true,
  };
  return renderConfigurations[annotation.id] || null;
};

export const useSignedDocumentUpload = () => {
  const instanceRef = React.useRef<any>(null);

  const downloadDocument = async (
    documentUrl: string,
    taskGuest?: TaskGuest,
    annotations?: string | null
  ) => {
    console.log('downladoing', taskGuest);
    try {
      await updatedPdfRef(
        documentUrl,
        !taskGuest || taskGuest?.signatureStatus === TaskSignatureStatus.SIGNED
          ? undefined
          : annotations ?? taskGuest?.annotations
      );
      await downloadPdf(instanceRef, taskGuest);
    } catch (e: any) {
      console.error(e?.message);
    }
  };

  const updatedPdfRef = async (
    documentUrl: string,
    annotations?: null | string
  ) => {
    const PSPDFKit: any = await import('pspdfkit');

    const pspdfParams: any = {
      // TODO: types for init pspdfkit
      document: documentUrl,
      licenseKey: PSPDFKIT_KEY,
      baseUrl: `${window.location.origin}/`,
      toolbarItems: [],
      headless: true,
      disableTextSelection: false,
      customRenderers: {
        Annotation: getAnnotationRenderers,
      },
      styleSheets: ['/viewer.css'],
      initialViewState: new PSPDFKit.ViewState({ readOnly: true }),
    };

    if (annotations) {
      pspdfParams.instantJSON = JSON.parse(annotations);
    }

    instanceRef.current = await PSPDFKit.load(pspdfParams);

    // await instanceRef.current.applyOperations([
    //   {
    //     type: "applyInstantJson",
    //     instantJson: JSON.parse(annotations ?? '[]'),
    //     format: "https://pspdfkit.com/instant-json/v2"
    //   }
    // ])

    const viewState = instanceRef?.current?.viewState;
    instanceRef?.current?.setViewState(viewState.set('showToolbar', false));
  };

  const uploadSignedDocumentFromS3 = async (
    document: {
      key: string;
      identityId?: string | null;
      level: S3UploadLevel;
      type: S3UploadType;
    },
    annotations: string
  ) => {
    const options: GetUrlWithPathInput = {
      path:
        document.level === 'public'
          ? `public/${document.key}`
          : `${document.level}/${document.identityId}/${document.key}`,
      options: {
        useAccelerateEndpoint: true,
      },
    };

    const documentUrl = (await getUrl(options))?.url?.toString();
    await updatedPdfRef(documentUrl, annotations);

    const pdf = await instanceRef?.current?.exportPDF({
      flatten: true,
    });

    await deleteFromS3Storage(document.key, document.level as S3UploadLevel);

    const type = document.key.split('.').pop();
    const newKey = `${uuidv4()}.${type}`;
    await uploadToS3Storage({
      key: newKey,
      file: pdf,
      level: document.level,
      contentType: pdf.mimeType,
    });
    return newKey;
  };

  const uploadSignedDocumentFromSignedUrl = async (
    documentUrl: string,
    uploadingUrl: string,
    annotations: string
  ) => {
    await updatedPdfRef(documentUrl, annotations);

    const pdf = await instanceRef?.current?.exportPDF({
      flatten: true,
    });

    await uploadPdfToSignedUrl(pdf, uploadingUrl);
  };

  return {
    uploadSignedDocumentFromSignedUrl,
    uploadSignedDocumentFromS3,
    downloadDocument,
  };
};
