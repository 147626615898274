import { WBBox, WBTypography } from '@admiin-com/ds-web';

import { Paper, useTheme } from '@mui/material';
import React from 'react';

interface TaskSectionLayoutProps {
  title: string;
  children: React.ReactNode;
}

export const TaskSectionLayout = ({
  title,
  children,
}: TaskSectionLayoutProps) => {
  const theme = useTheme();

  return (
    <WBBox mb={2} width="100%">
      <WBTypography
        fontWeight="bold"
        fontSize={{ xs: 'h5.fontSize', sm: 'h3.fontSize' }}
      >
        {title}
      </WBTypography>
      <Paper
        sx={{
          p: { xs: 2, md: 2 },
          mt: 2,
          fontWeight: 'bold',
          boxShadow: '0 11px 19px -8.5px #636363',
          fontSize: 'body2.fontSize',
          bgcolor: theme.palette.background.default,
        }}
      >
        {children}
      </Paper>
    </WBBox>
  );
};
