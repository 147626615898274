import {
  Timeline as MuiTimeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab';
import {
  Payment,
  PaymentStatus,
  Task,
  TaskDirection,
} from '@admiin-com/ds-graphql';
import { ScrollViews, ScrollViewsContainer } from '../ScrollViews/ScrollViews';
import {
  WBBox,
  WBFlex,
  WBGrid,
  WBLinkButton,
  WBMenu,
  WBMenuItem,
  WBTooltip,
  WBTypography,
} from '@admiin-com/ds-web';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  frontDateFromBackendDate,
  frontDateFromISO,
} from '@admiin-com/ds-common';
import { CurrencyNumber } from '../CurrencyNumber/CurrencyNumber';
import { usePaymentContext } from '../PaymentContainer/PaymentContainer';
import { useTaskBoxContext } from '../../pages/TaskBox/TaskBox';
import { sortPayments } from '../../helpers/payments';
import React, { useState } from 'react';
import BackModal from '../BackModal/BackModal';
import { useSentTaskOptions } from '../../hooks/useSentTaskOptions/useSentTaskOptions';
import RemittanceDialog from '../RemittanceDialog/RemittanceDialog';

interface TaskInstallmentsTimelineProps {
  task?: Task;
}

export function TaskInstallmentsTimeline({
  task,
}: TaskInstallmentsTimelineProps) {
  const payments: Payment[] = (task?.payments?.items.filter(
    (payment: Payment | null) => !!payment
  ) ?? []) as Payment[];

  const { t } = useTranslation();
  const [copying, setCopying] = useState<boolean>(false);
  const [showRemittance, setShowRemittance] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const menuOpen = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const [clickedPayment, setClickedPayment] = useState<
    Payment | null | undefined
  >(null);
  const theme = useTheme();

  const { directionFilter } = useTaskBoxContext();
  const paymentContext = usePaymentContext() ?? {};

  const sortedPayments = sortPayments(payments);

  const { paymentMethod, retryPayment } = paymentContext;

  const showFees = false;

  const onPaymentClick = (
    event: React.MouseEvent<HTMLElement>,
    payment: Payment
  ) => {
    setAnchorEl(event.currentTarget);
    setClickedPayment(payment);
  };

  const { handleCopyPaymentLink, QRCodeComponent } = useSentTaskOptions({
    task,
    payment: clickedPayment,
  });

  const handleCopyLink = async (resend: boolean | undefined = false) => {
    setCopying(true);
    await handleCopyPaymentLink(resend);
    setCopying(false);
    handleClose();
  };

  // const handleShowQRCode = () => {
  //   setModalOpen(true);
  //   handleClose();
  // };

  return (
    <>
      <RemittanceDialog
        open={showRemittance}
        handleClose={() => setShowRemittance(false)}
        payment={clickedPayment}
      />
      <ScrollViewsContainer data={sortedPayments}>
        <WBGrid container alignItems={'center'} spacing={0}>
          <WBGrid
            xs={1}
            padding={'18px 8px'}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              boxShadow: '5px 1px 5px -5px rgba(0, 0, 0, 0.5)',
            }}
          >
            <ScrollViews.Back name="ChevronBack" />
          </WBGrid>
          <WBGrid xs={10}>
            <MuiTimeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                  marginLeft: -3,
                  marginRight: -3,
                },
                pr: 0,
                my: 0,
                pl: 3,
                // transform: 'rotate(90deg)',
                flexDirection: 'row',
                ml: -3,
                padding: -3,
              }}
            >
              <ScrollViews
                noPadding
                render={(item: Payment, index: number) => (
                  <WBTooltip
                    title={
                      item.status === PaymentStatus.DECLINED ||
                      item.status === PaymentStatus.PAYOUT_FAILED
                        ? item.declinedReason || item.payoutFailureReason
                        : ''
                    }
                    placement={'top'}
                  >
                    <TimelineItem
                      sx={{
                        minHeight: '55px',
                        //  transform: 'rotate(180deg)'
                        flexDirection: 'column',
                        color:
                          item.status === PaymentStatus.SUCCEEDED
                            ? 'success.main'
                            : item.status === PaymentStatus.DECLINED ||
                              item.status === PaymentStatus.PAYOUT_FAILED
                            ? 'error.main'
                            : item.status === PaymentStatus.VOIDED
                            ? 'grey.500'
                            : 'warning.main',
                        fontSize: 'body2.fontSize',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => onPaymentClick(e, item)}
                      key={index}
                    >
                      <TimelineContent
                        sx={{
                          // transform: 'rotate(-180deg)',
                          textAlign: 'center',
                          position: 'relative',
                          fontSize: 'inherit',
                          minWidth: 50,
                          fontWeight: 'medium',
                        }}
                      >
                        {directionFilter === TaskDirection.RECEIVING && (
                          <WBBox
                            sx={{
                              visibility:
                                item.status === PaymentStatus.DECLINED
                                  ? 'visible'
                                  : 'hidden',
                            }}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              e.preventDefault();
                              retryPayment(item);
                            }}
                          >
                            <WBLinkButton
                              color="primary.main"
                              sx={{
                                textDecoration: 'underline',
                              }}
                            >
                              {t('retry', { ns: 'taskbox' })}
                            </WBLinkButton>
                          </WBBox>
                        )}
                        <WBTypography
                          color={'inherit'}
                          textAlign={'inherit'}
                          fontSize={'inherit'}
                          fontWeight={'inherit'}
                        >
                          {t(item.status, { ns: 'taskbox' })}
                        </WBTypography>
                      </TimelineContent>
                      <TimelineSeparator
                        sx={{ flexDirection: 'row', color: 'inherit' }}
                      >
                        <TimelineConnector
                          sx={{
                            height: '4px',
                            bgcolor:
                              index === 0
                                ? 'transparent'
                                : item.status === PaymentStatus.SUCCEEDED
                                ? 'success.main'
                                : 'grey.light',
                          }}
                        />
                        <TimelineDot
                          variant="outlined"
                          sx={{
                            bgcolor: 'white',
                            borderColor: 'inherit',
                            padding: '6px',
                            margin: 0,
                            borderWidth: 4,
                          }}
                        />
                        <TimelineConnector
                          sx={{
                            height: '4px',
                            bgcolor:
                              index < payments.length - 1
                                ? item.status === PaymentStatus.SUCCEEDED
                                  ? 'success.main'
                                  : 'grey.light'
                                : 'transparent',
                          }}
                        />
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          // transform: 'rotate(-180deg)',
                          textAlign: 'center',
                          minWidth: 50,
                          fontSize: 'inherit',
                        }}
                      >
                        <WBTooltip
                          title={
                            item.status === PaymentStatus.SCHEDULED ||
                            item.status === PaymentStatus.GUEST_SCHEDULED ? (
                              ''
                            ) : (
                              <>
                                <WBFlex
                                  justifyContent={'space-between'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <WBTypography
                                    color={'common.black'}
                                    variant="body2"
                                  >
                                    {t('subTotal', { ns: 'taskbox' })}
                                  </WBTypography>
                                  <CurrencyNumber
                                    number={(item.netAmount ?? 0) / 100}
                                    sup={false}
                                    fontWeight="medium"
                                    fontSize={14}
                                  />
                                </WBFlex>
                                <WBFlex
                                  justifyContent={'space-between'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <WBTypography
                                    color={'common.black'}
                                    variant="body2"
                                  >
                                    {t('admiinServiceFee', { ns: 'taskbox' })}
                                  </WBTypography>
                                  <CurrencyNumber
                                    number={
                                      showFees
                                        ? item.status ===
                                          PaymentStatus.SUCCEEDED
                                          ? (item.payerFees ?? 0) / 100
                                          : 0.0
                                        : (item.payerFees ?? 0) / 100
                                    }
                                    sup={false}
                                    fontWeight="medium"
                                    fontSize={14}
                                  />
                                </WBFlex>

                                <WBFlex
                                  justifyContent={'space-between'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <WBTypography
                                    color={'common.black'}
                                    variant="body2"
                                  >
                                    {t('totalAmount', { ns: 'taskbox' })}
                                  </WBTypography>
                                  <CurrencyNumber
                                    number={(item.totalAmount ?? 0) / 100}
                                    sup={false}
                                    fontWeight="medium"
                                    fontSize={14}
                                  />
                                </WBFlex>
                              </>
                            )
                          }
                        >
                          <WBFlex flexDirection={'column'}>
                            <CurrencyNumber
                              number={
                                showFees
                                  ? item.status === PaymentStatus.SUCCEEDED
                                    ? (item.amount ?? 0) / 100
                                    : 0.0
                                  : (item.netAmount ?? 0) / 100
                              }
                              sup={false}
                              color={'text.primary'}
                              textAlign={'center'}
                            />
                            {/* {item.status === PaymentStatus.SUCCEEDED && (
                        <WBFlex justifyContent={'center'}>
                          <CurrencyNumber
                            number={
                              showFees
                                ? item.status === PaymentStatus.SUCCEEDED
                                  ? (item.netAmount ?? 0) / 100
                                  : addFee({
                                      amount: item?.netAmount ?? 0,
                                      paymentType: PaymentType.INSTALLMENTS,
                                      isTax: task?.category === 'TAX',
                                      paymentMethodType:
                                        paymentMethod.paymentMethodType,
                                    }) / 100
                                : (item.netAmount ?? 0) / 100
                            }
                            sup={false}
                            color={'text.primary'}
                            fontWeight="light"
                            fontSize={14}
                          />
                        </WBFlex>
                      )}

                      {item.status === PaymentStatus.SUCCEEDED && (
                        <WBFlex justifyContent={'center'}>
                          <CurrencyNumber
                            number={
                              showFees
                                ? item.status === PaymentStatus.SUCCEEDED
                                  ? (item.payerFees ?? 0) / 100
                                  : addFee({
                                      amount: item?.payerFees ?? 0,
                                      paymentType: PaymentType.INSTALLMENTS,
                                      isTax: task?.category === 'TAX',
                                      paymentMethodType:
                                        paymentMethod.paymentMethodType,
                                    }) / 100
                                : (item.payerFees ?? 0) / 100
                            }
                            sup={false}
                            color={'text.primary'}
                            fontWeight="light"
                            fontSize={14}
                          />
                        </WBFlex>
                      )} */}
                            <WBTypography
                              fontWeight={'bold'}
                              fontSize="11px"
                              textAlign="center"
                              color="textSecondary"
                            >
                              {(item.status !== PaymentStatus.VOIDED &&
                                item.status !== PaymentStatus.COMPLETED &&
                                item.status !== PaymentStatus.SUCCEEDED &&
                                frontDateFromBackendDate(
                                  item?.scheduledAt ?? ''
                                )) ??
                                ''}
                              {item.status === PaymentStatus.VOIDED &&
                                frontDateFromISO(item.voidedAt ?? '')}
                              {item.status === PaymentStatus.SUCCEEDED &&
                                frontDateFromISO(item.paidAt ?? '')}
                            </WBTypography>
                          </WBFlex>
                        </WBTooltip>
                      </TimelineContent>
                    </TimelineItem>
                  </WBTooltip>
                )}
              />
            </MuiTimeline>
          </WBGrid>
          <WBGrid
            xs={1}
            padding={'18px 8px'}
            sx={{ boxShadow: '-5px 1px 5px -5px rgba(0, 0, 0, 0.5)' }}
          >
            <ScrollViews.Forward name="ChevronForward" />
          </WBGrid>
        </WBGrid>
        <WBMenu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableArrow
        >
          {task?.direction === TaskDirection.SENDING &&
            clickedPayment?.status !== PaymentStatus.SUCCEEDED && (
              <WBMenuItem
                onClick={() => handleCopyLink()}
                sx={{
                  ...theme.typography.body2,
                }}
              >
                {t(copying ? 'generatingLink' : 'copyLinkToPayment', {
                  ns: 'taskbox',
                })}
              </WBMenuItem>
            )}
          {clickedPayment?.payoutDetails &&
            task?.direction === TaskDirection.RECEIVING && (
              <WBMenuItem
                onClick={() => setShowRemittance(true)}
                sx={{
                  ...theme.typography.body2,
                }}
              >
                {t('showRemittance', { ns: 'taskbox' })}
              </WBMenuItem>
            )}
        </WBMenu>
        <BackModal
          height={'auto'}
          open={modalOpen}
          fullWidth={false}
          onClose={() => setModalOpen(false)}
        >
          {QRCodeComponent}
        </BackModal>
      </ScrollViewsContainer>
    </>
  );
}

export default TaskInstallmentsTimeline;
