import React, { forwardRef } from 'react';
import {
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from '@mui/material';

interface SwitchProps extends MUISwitchProps {
  label: string;
}
export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ label, ...props }, ref) => {
    return <MUISwitch {...props} ref={ref} />;
  }
);
