import {
  useSnackbar,
  WBBox,
  WBCheckbox,
  WBFlex,
  WBLinkButton,
  WBTypography,
} from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import { t } from 'i18next';
import PenIcon from '../../../assets/icons/pen-icon.svg';
import LoadSvgIcon from '../../component/LoadSvgIcon/LoadSvgIcon';
import SMSNumberModal from './SMSNumberModal';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { isEntityOrContact } from '../../helpers/entities';
import { gql, useMutation } from '@apollo/client';
import {
  Contact,
  Entity,
  updateContact as UPDATE_CONTACT,
} from '@admiin-com/ds-graphql';
interface Props {
  onAdd: () => void;
  contact?: Contact | Entity;
  checked: boolean;
  onCheck: (value: boolean) => void;
}
export const SMSNumber = ({ contact, checked = false, onCheck }: Props) => {
  const isContact = contact && isEntityOrContact(contact) === 'CONTACT';
  const number = isContact
    ? (contact as Contact)?.mobile
    : (contact as Entity)?.contact?.phone;

  const name =
    (isContact ? (contact as Contact)?.name : (contact as Entity)?.name) ?? '';
  const hasNumber = Boolean(number) === true;
  const { setValue } = useFormContext();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(event.target.checked);
  };
  const [modalOpen, setModalOpen] = React.useState(false);
  const [updateContact] = useMutation(gql(UPDATE_CONTACT));
  const showSnackbar = useSnackbar();
  const onUpdateNumber = async (number: string) => {
    console.log(contact, isContact);
    try {
      if (isContact) {
        if (contact?.id) {
          const { data: updatedContactData } = await updateContact({
            variables: {
              input: {
                id: contact?.id,
                entityId: (contact as Contact).entityId,
                mobile: number,
              },
            },
          });
          const updatedContact = updatedContactData?.updateContact;
          if (updatedContact) {
            setValue('to', updatedContact);
          }
        }
      }
    } catch (e: any) {
      showSnackbar({
        message: t(e?.message, { ns: 'errors' }),
        severity: 'error',
        vertical: 'bottom',
      });
    }
  };
  return (
    <Container>
      {hasNumber && <WBCheckbox checked={checked} onChange={handleChange} />}
      <WBBox flex={1}>
        <WBTypography fontWeight={'bold'}>
          {t('sendViaSMS', { ns: 'taskbox' })}
        </WBTypography>
        <WBTypography color={'text.secondary'}>
          {number || t('noNumber', { ns: 'taskbox' })}
        </WBTypography>
      </WBBox>
      {isContact && (
        <WBBox>
          <WBLinkButton
            color="primary.main"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {hasNumber ? (
              <LoadSvgIcon component={PenIcon} width={25} height={25} />
            ) : (
              <WBTypography color={'primary.main'}>
                {t('addNumber', { ns: 'taskbox' })}
              </WBTypography>
            )}
          </WBLinkButton>
        </WBBox>
      )}
      {modalOpen && (
        <SMSNumberModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onOK={async (number: string) => {
            await onUpdateNumber(number);
          }}
          name={name}
          defaultNumber={number ?? ''}
          disabledEdit={!isContact}
        />
      )}
    </Container>
  );
};

const Container = styled(WBFlex)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(2),
}));
