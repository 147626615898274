import {
  WBButton,
  WBFlex,
  WBLinkButton,
  WBTypography,
} from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import React, { ReactNode } from 'react';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import { t } from 'i18next';

interface Props {
  onGetStarted?: () => void;
  title?: string;
  description?: string | ReactNode;
  buttonTitle?: string;
  disabled?: boolean;
  loading?: boolean;
  lottiePath?: string;
  segment?: [number, number];
  onClose?: () => void;
  closeButtonText?: string;
}

const OnboardingMessage: React.FC<Props> = ({
  title,
  description,
  disabled = false,
  loading = false,
  buttonTitle,
  onGetStarted,
  lottiePath,
  segment,
  onClose,
  closeButtonText,
}: Props) => {
  return (
    <Conatiner
      flexDirection={'column'}
      alignItems={'center'}
      rowGap={[1.5, 2.5]}
    >
      <ImagePlaceholder>
        {lottiePath && <LottieAnimation path={lottiePath} segment={segment} />}
      </ImagePlaceholder>
      <Title textAlign={'center'}>{title}</Title>
      <Description variant="body1" textAlign={'center'}>
        {description}
      </Description>
      {onGetStarted && (
        <StartButton
          onClick={onGetStarted}
          disabled={disabled}
          loading={loading}
        >
          {buttonTitle}
        </StartButton>
      )}
      {onClose && (
        <WBLinkButton color="primary.main" onClick={onClose}>
          {closeButtonText ?? t('backToTaskbox', { ns: 'taskbox' })}
        </WBLinkButton>
      )}
    </Conatiner>
  );
};
const ImagePlaceholder = styled(WBFlex)(({ theme }) => ({
  //width: '200px',
  //height: '200px',
  backgroundColor: 'transparent',
  borderRadius: '100%',
  [theme.breakpoints.down('sm')]: {
    //width: '90px',
    //height: '90px',
  },
}));
const StartButton = styled(WBButton)(({ theme }) => ({
  padding: theme.spacing(2, 24),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 0),
    width: '100%',
  },
  flexWrap: 'nowrap',
}));

const Title = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.h3,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.h3,
  },
}));

const Description = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.body1,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.body2,
  },
  color: theme.palette.text.secondary,
}));

const Conatiner = styled(WBFlex)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(20),
  //marginTop: theme.spacing(5),
  paddingTop: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
}));

export default OnboardingMessage;
