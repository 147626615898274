import { CSGetEntity as GET_ENTITY } from '@admiin-com/ds-graphql';
import { gql, useQuery } from '@apollo/client';
import { useFirmId } from '../useClientWorkflow/useClientWorkflow';

const useSelectedFirm = () => {
  const { firmId } = useFirmId();

  const { data } = useQuery(gql(GET_ENTITY), {
    variables: {
      id: firmId,
    },
    notifyOnNetworkStatusChange: true, // This is important to notify when network status changes
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-first',
    initialFetchPolicy: 'cache-first',
    skip: !firmId,
  });
  const selectedFirm = data?.getEntity;
  return { selectedFirm };

  // const [selectedFirm, setSelectedFirm] = useState<Entity | null>(null);
  // const [getEntity] = useLazyQuery(gql(GET_ENTITY), {
  //   notifyOnNetworkStatusChange: true, // This is important to notify when network status changes
  //   nextFetchPolicy: 'cache-first',
  //   fetchPolicy: 'cache-first',
  //   initialFetchPolicy: 'cache-first',
  // });

  // // get entity from firmId
  // useEffect(() => {
  //   const fetchFirm = async () => {
  //     if (firmId) {
  //       const { data } = await getEntity({
  //         variables: {
  //           id: firmId,
  //         },
  //       });

  //       setSelectedFirm(data?.getEntity);
  //     }
  //   };

  //   fetchFirm();
  // }, [firmId, getEntity]);

  return { selectedFirm };
};

export default useSelectedFirm;
