import {
  getTaskPublic as GET_TASK_PUBLIC,
  TaskGuest,
  updateTaskPublic as UPDATE_TASK_PUBLIC,
  getPaymentGuest as GET_PAYMENT_GUEST,
  PaymentGuest,
  TaskStatus,
  PaymentStatus,
  TaskDirection,
  UpdateTaskQuotePublicStatus,
  TaskQuoteStatus,
  TaskType,
  TaskPaymentStatus,
  TaskSignatureStatus,
} from '@admiin-com/ds-graphql';
import {
  WBBox,
  WBButton,
  WBContainer,
  WBFlex,
  WBIcon,
  WBLink,
  WBSkeleton,
  WBStack,
  WBTypography,
  useMediaQuery,
  useSnackbar,
  useTheme,
} from '@admiin-com/ds-web';
import { gql, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import CreditCardIcons from '../../../assets/icons/creditcards.svg';
import AdmiinLogo from '../../../assets/images/logo.svg';
import AdmiinColorLogo from '../../../assets/images/admiin-logo-coloured.svg';
import { CSIsLoggedIn as IS_LOGGED_IN } from '@admiin-com/ds-graphql';
import React, { useRef } from 'react';
import { useClientContext } from '../ApolloClientProvider/ApolloClientProvider';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import { TaskBoxContext } from '../../pages/TaskBox/TaskBox';
import {
  isPayableTask,
  isPendingSigantureTask,
  isSignableTask,
} from '../../helpers/tasks';
import AirwallexPaymentMethod from '../AirwallexOnboarding/AirwallexPaymentMethod';
import { GuestSignature } from './GuestSignature';
import TaskSummaryGuestCard from '../TaskSummaryCard/TaskSummaryGuestCard';
import { QuoteAccept } from './QuoteAccept';
import { useFeatureFlags } from '../../hooks/useFeatureFlags/useFeatureFlags';
import { InvoicePay } from './InvoicePay';
import { fetchAuthSession } from 'aws-amplify/auth';

const StyledGuestPayment = styled.div`
  color: pink;
  background: linear-gradient(
    to bottom,
    rgba(140, 81, 255, 0.7) 0%,
    transparent 50%
  );
  height: 100%;
  overflow-y: scroll;
`;
interface TaskGuestData extends TaskGuest {
  metaHashData: any;
}
export function GuestPayment() {
  const { t } = useTranslation();
  const { clientType, setClientType } = useClientContext();
  const { data: loggedInData } = useQuery(gql(IS_LOGGED_IN));
  const isLoggedIn = React.useMemo(
    () => loggedInData?.isLoggedIn || false,
    [loggedInData]
  );
  const [annotations, setAnnotations] = React.useState<any>(null);
  // React.useEffect(() => {
  //   if (!isLoggedIn && clientType === 'userPool') {
  //     setClientType('iam');
  //   } else if (isLoggedIn && clientType === 'iam') setClientType('userPool');
  // }, [isLoggedIn, clientType]);

  React.useEffect(() => {
    const figureAuth = async () => {
      let user;
      try {
        user = await fetchAuthSession({ forceRefresh: true });
      } catch (err) {
        console.log('ERROR refresh user session: ', err);
      }

      console.log('isLoggedIn: ', isLoggedIn);
      console.log('user: ', user);
      console.log('clientType: ', clientType);

      if ((!user?.tokens && clientType === 'userPool') || !clientType) {
        setClientType('iam');
      } else if (user?.tokens && clientType === 'iam')
        setClientType('userPool');
    };

    figureAuth();
  }, [isLoggedIn, clientType]);

  const [searchParams] = useSearchParams();
  const [entityId, setEntityId] = React.useState<string | null>(null);
  const [taskId, setTaskId] = React.useState<string | null>(null);

  const token = searchParams.get('token');

  const {
    data: taskPublicData,
    loading,
    error,
  } = useQuery(gql(GET_TASK_PUBLIC), {
    variables: {
      token,
    },
    notifyOnNetworkStatusChange: true,
    skip: !token || (!isLoggedIn && clientType === 'userPool'),
  });
  const taskGuest: TaskGuestData | undefined = taskPublicData?.getTaskPublic;
  const paymentId = taskGuest?.metaHashData
    ? JSON.parse(taskGuest?.metaHashData).paymentId
    : null;
  const taxableEntity = taskGuest?.metaHashData
    ? JSON.parse(taskGuest?.metaHashData).taxable ?? true
    : true;

  React.useEffect(() => {
    if (taskGuest && taskGuest.entityId && !entityId) {
      setEntityId(taskGuest.entityId);
    }
    if (taskGuest && taskGuest.id && !taskId) {
      setTaskId(taskGuest.id);
    }
  }, [taskGuest]);

  const { data: paymentGuestData } = useQuery(gql(GET_PAYMENT_GUEST), {
    variables: {
      id: paymentId,
    },
    skip: !paymentId || (!isLoggedIn && clientType === 'userPool'),
  });

  const paymentGuest: PaymentGuest | undefined = React.useMemo(
    () => paymentGuestData?.getPaymentGuest,
    [paymentGuestData]
  );

  const theme = useTheme();
  const [paid, setIsPaid] = React.useState<boolean | null>(null);
  React.useEffect(() => {
    if (taskGuest || paymentGuest) {
      if (
        taskGuest?.status === TaskStatus.COMPLETED ||
        paymentGuest?.status === PaymentStatus.SUCCEEDED
      ) {
        setIsPaid(true);
      } else {
        setIsPaid(false);
      }
    }
    if (taskGuest) {
      if (isPendingSigantureTask(taskGuest as any, 'undefined'))
        setAnnotations(taskGuest.annotations);
    }
  }, [taskGuest, paymentGuest]);
  const pdfSignatureRef = useRef<any>(null);

  const refetchQueries = [
    {
      query: gql(GET_TASK_PUBLIC),
      variables: {
        token,
      },
    },
  ];

  const [updateTaskPublic, { loading: updatingTask }] = useMutation(
    gql(UPDATE_TASK_PUBLIC)
  );
  const showSnackbar = useSnackbar();

  const submitInvoice = async () => {
    setIsPaid(true);
    console.log('SDK sending success: waiting for webhook resp');
  };
  const isDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const isSignature =
    taskGuest?.type === 'SIGN_ONLY' ||
    (taskGuest?.type === 'SIGN_PAY' &&
      taskGuest.signatureStatus === TaskSignatureStatus.PENDING_SIGNATURE);

  const [quoteAccepting, setQuoteAccepting] = React.useState(false);
  const [quoteDeclining, setQuoteDeclining] = React.useState(false);

  const handleAccept = async () => {
    try {
      setQuoteAccepting(true);
      await updateTaskPublic({
        variables: {
          input: {
            token,
            quoteStatus: UpdateTaskQuotePublicStatus.ACCEPTED,
          },
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
      showSnackbar({
        message: t('quoteAccepted', { ns: 'payment' }),
        severity: 'success',
        horizontal: 'right',
        vertical: 'bottom',
      });
    } catch (error) {
      console.error('Error accepting accept-decline-quote', error);
      showSnackbar({
        message: t('errorAcceptingQuote', { ns: 'payment' }),
        severity: 'error',
        horizontal: 'right',
        vertical: 'bottom',
      });
    } finally {
      setQuoteAccepting(false);
    }
  };
  const handleDecline = async () => {
    try {
      setQuoteDeclining(true);
      await updateTaskPublic({
        variables: {
          input: {
            token,
            quoteStatus: UpdateTaskQuotePublicStatus.DECLINED,
          },
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
      showSnackbar({
        message: t('quoteDeclined', { ns: 'payment' }),
        severity: 'success',
        horizontal: 'right',
        vertical: 'bottom',
      });
    } catch (error) {
      console.error('Error declining accept-decline-quote', error);
      showSnackbar({
        message: t('errorDecliningQuote', { ns: 'payment' }),
        severity: 'error',
        horizontal: 'right',
        vertical: 'bottom',
      });
    } finally {
      setQuoteDeclining(false);
    }
  };
  const { featureFlags } = useFeatureFlags();
  const quoteSignPayEnabled = Boolean(featureFlags?.quoteSignPay);

  const isQuote = (taskGuest as any)?.invoiceStatus === 'QUOTE';
  const isInvoice =
    ((taskGuest as any)?.invoiceStatus === 'INVOICE' &&
      taskGuest &&
      taskGuest.type === TaskType.PAY_ONLY) ||
    (taskGuest?.type === 'SIGN_PAY' &&
      (taskGuest.paymentStatus === TaskPaymentStatus.PENDING_PAYMENT ||
        taskGuest.paymentStatus === TaskPaymentStatus.SCHEDULED ||
        taskGuest.paymentStatus === TaskPaymentStatus.PAID) &&
      taskGuest.signatureStatus === TaskSignatureStatus.SIGNED);

  if (quoteSignPayEnabled && isQuote && taskGuest && token)
    return <QuoteAccept taskGuest={taskGuest} token={token} />;
  if (isInvoice && taskGuest && token)
    return (
      <InvoicePay
        taskGuest={taskGuest}
        token={token}
        paymentGuest={paymentGuest}
        paymentId={paymentId}
      />
    );
  return isSignature && token ? (
    <GuestSignature taskGuest={taskGuest} token={token} />
  ) : (
    <StyledGuestPayment>
      <WBContainer>
        <WBBox py={8} width={'100%'}>
          {isDownMD && (
            <WBFlex
              justifyContent={'center'}
              mb={2}
              mt={-2}
              alignItems={'center'}
            >
              <AdmiinLogo
                //@ts-ignore
                width={'155px'}
                height={'51px'}
              />
            </WBFlex>
          )}
          <WBFlex justifyContent={'center'} alignContent={'center'}>
            <WBTypography variant="h4" fontWeight="bold" mb={0}>
              {taskGuest
                ? t(
                    isPayableTask(taskGuest as any)
                      ? isSignableTask(taskGuest as any)
                        ? 'signatureAndPaymentDueTitle'
                        : 'paymentDueTitle'
                      : isSignableTask(taskGuest as any)
                      ? 'signatureDueTitle'
                      : 'paidGuestInvoiceTitle',
                    {
                      ns: 'payment',
                      invoice: taskGuest?.reference,
                      invoiceStatus: isQuote ? 'Quote' : 'Invoice',
                    }
                  )
                : ''}
            </WBTypography>
          </WBFlex>
          <WBFlex
            mt={9}
            width={'100%'}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <WBFlex flex={[7]} mr={[0, 0, 5]}>
              {!loading && taskGuest ? (
                <TaskBoxContext.Provider value={{ pdfSignatureRef }}>
                  {token && (
                    <TaskSummaryGuestCard
                      task={taskGuest}
                      token={token}
                      installment={paymentGuest?.installment || 1}
                      annotations={annotations}
                    />
                  )}
                </TaskBoxContext.Provider>
              ) : (
                <WBSkeleton
                  variant="rectangular"
                  width={'509px'}
                  height={'630px'}
                />
              )}
            </WBFlex>
            <WBFlex
              flex={5}
              mt={[5, 5, 0]}
              ml={[0, 0, 5]}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              {!loading && taskGuest ? (
                <>
                  <WBBox
                    p={5}
                    sx={{
                      boxShadow: theme.shadows[6],
                      bgcolor: 'background.default',
                    }}
                  >
                    <WBFlex
                      justifyContent={'center'}
                      mb={7}
                      mt={3}
                      alignItems={'center'}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                    >
                      <WBBox>
                        {/*//@ts-ignore*/}
                        <AdmiinColorLogo width="10em" height="3em" />
                      </WBBox>
                    </WBFlex>
                    {!isQuote &&
                    taskGuest?.entityId &&
                    (!paymentId || (paymentId && paymentGuest?.id)) &&
                    paid !== null &&
                    !paid ? (
                      <AirwallexPaymentMethod
                        isGuest={
                          taskGuest.direction === TaskDirection.SENDING
                            ? true
                            : !taxableEntity
                        }
                        entityId={taskGuest.entityId ?? ''}
                        taskId={taskGuest.id ?? ''}
                        intentId={paymentGuest?.paymentIntentId || ''}
                        paymentId={paymentId}
                        onSuccess={submitInvoice}
                        onClose={() => console.log('CLOSED')}
                      />
                    ) : (
                      ''
                    )}
                    {isQuote && !paid && (
                      <WBFlex
                        flexDirection={['column', 'row']}
                        justifyContent={'space-between'}
                        gap={2}
                      >
                        <WBButton
                          onClick={handleDecline}
                          variant="outlined"
                          loading={quoteDeclining}
                          disabled={quoteAccepting}
                          style={{ paddingLeft: 40, paddingRight: 40 }}
                        >
                          {t('decline', { ns: 'common' })}
                        </WBButton>
                        <WBButton
                          onClick={handleAccept}
                          loading={quoteAccepting}
                          disabled={quoteDeclining}
                          variant="contained"
                          style={{ paddingLeft: 40, paddingRight: 40 }}
                        >
                          {t('accept', { ns: 'common' })}
                        </WBButton>
                      </WBFlex>
                    )}
                    {paid ? (
                      <WBBox
                        sx={{
                          mt: 7,
                          borderRadius: '50px',
                          padding: 2,
                          paddingX: 6,
                          color: 'common.black',
                          bgcolor: 'success.main',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        <WBIcon
                          name="Checkmark"
                          color={'common.black'}
                          size={'small'}
                        ></WBIcon>
                        {t(
                          taskGuest.quoteStatus === TaskQuoteStatus.ACCEPTED
                            ? 'quoteAccepted'
                            : taskGuest.quoteStatus === TaskQuoteStatus.DECLINED
                            ? 'quoteDeclined'
                            : 'invoicePaid',
                          { ns: 'payment' }
                        )}
                      </WBBox>
                    ) : (
                      ''
                    )}
                  </WBBox>
                  <WBFlex
                    justifyContent={'end'}
                    alignItems="center"
                    flexDirection={'column'}
                    flexGrow={1}
                  >
                    <WBStack direction={'row'} spacing={2} mb={2}>
                      {/*@ts-ignore */}
                      <CreditCardIcons width="170px" height="50px" />
                      {/* <CreditCardIcon type={'visa'} width={100} height={50} />
                  <CreditCardIcon type={'mastercard'} />
                  <CreditCardIcon type={'american_express'} /> */}
                    </WBStack>
                    <WBTypography>
                      {t('needHelp', { ns: 'common' })}{' '}
                      <WBLink
                        to={'mailto:support@admiin.com'}
                        underline="always"
                      >
                        support@admiin.com
                      </WBLink>
                    </WBTypography>
                  </WBFlex>
                </>
              ) : (
                <WBSkeleton
                  width={'100%'}
                  height={'100%'}
                  variant="rectangular"
                />
              )}
            </WBFlex>
          </WBFlex>
        </WBBox>
      </WBContainer>
      <ErrorHandler isDialog errorMessage={error?.message} />
    </StyledGuestPayment>
  );
}

export default GuestPayment;
