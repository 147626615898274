import {
  PaymentGuest,
  PaymentStatus,
  TaskGuest,
  TaskStatus,
} from '@admiin-com/ds-graphql';
import GuestLayout from './GuestLayout';
import {
  WBBox,
  WBButton,
  WBContainer,
  WBFlex,
  WBIcon,
  WBLink,
  WBTypography,
} from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import React, { useRef } from 'react';
import AirwallexPaymentMethod from '../AirwallexOnboarding/AirwallexPaymentMethod';
import { t } from 'i18next';
import OnboardingMessage from '../InvoiceCreateForm/OnboardingMessage';
import { useNavigate } from 'react-router-dom';
import { TaskSuportDocumentsGuest } from '../../pages/TaskDetail/TaskSupportDocumentsGuest';
import TaskPdfSignatureGuest from '../TaskPdfSignature/TaskPdfSignatureGuest';
import { BottomDrawer } from '../ESignature/BottomDrawer';
import { SupportEmail } from './QuoteAccept';
import { downloadPdf } from '../../helpers/signature';

interface Props {
  taskGuest: TaskGuest;
  token?: string;
  paymentGuest?: PaymentGuest;
  paymentId?: string;
}
export const InvoicePay = (props: Props) => {
  const pdfInstanceRef = useRef<any>(null);
  const { taskGuest, paymentGuest, paymentId } = props;
  const [paid, setIsPaid] = React.useState<boolean | null>(null);
  const navigate = useNavigate();
  const submitInvoice = async () => {
    setIsPaid(true);
    console.log('SDK sending success: waiting for webhook resp');
  };
  React.useEffect(() => {
    if (taskGuest || paymentGuest) {
      if (
        taskGuest?.status === TaskStatus.COMPLETED ||
        paymentGuest?.status === PaymentStatus.SUCCEEDED
      ) {
        setIsPaid(true);
      } else {
        setIsPaid(false);
      }
    }
  }, [taskGuest, paymentGuest]);
  if (paid && taskGuest.automaticGenerateInvoice) {
    return (
      <GuestLayout taskGuest={props.taskGuest} token={props.token}>
        <OnboardingMessage
          lottiePath={'/tick.lottie'}
          title={t('invoicePaidTitle', { ns: 'taskbox' })}
          description={t('invoicePaidSubTitle', {
            ns: 'taskbox',
            entity: props.taskGuest.to?.name,
          })}
          onClose={() => {
            setIsPaid(null);
          }}
          onGetStarted={() => navigate('/sign-up')}
          buttonTitle="Create account for Admiin"
          closeButtonText="Close this screen"
        />
      </GuestLayout>
    );
  }

  return (
    <GuestLayout taskGuest={props.taskGuest} token={props.token}>
      <WBContainer
        maxWidth="xl"
        sx={{
          px: 4,
          pb: [16, 10],
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <PdfContainer maxWidth="md">
          {props.token && (
            <TaskPdfSignatureGuest
              taskGuest={taskGuest}
              token={props.token}
              ref={pdfInstanceRef}
            />
          )}
          <WBFlex
            width={'100%'}
            flexDirection="column"
            px={6}
            pt={2}
            sx={{
              display: ['none', 'block'],
              position: 'absolute',
              left: 0,
              bottom: 0,
              transform: 'translateY(100%)',
            }}
          >
            {props.token && (
              <TaskSuportDocumentsGuest
                taskGuest={taskGuest}
                token={props.token}
              />
            )}
          </WBFlex>
        </PdfContainer>
        <WBFlex
          width={'100%'}
          flexDirection="column"
          px={2}
          mt={2}
          sx={{ display: ['block', 'none'] }}
        >
          {props.token && (
            <TaskSuportDocumentsGuest
              taskGuest={taskGuest}
              token={props.token}
            />
          )}
        </WBFlex>
        <PayForm>
          <WBBox
            p={5}
            sx={{
              bgcolor: 'background.default',
            }}
            width={'100%'}
          >
            {(taskGuest?.fromEntity || taskGuest?.fromContact) &&
            (!paymentId || (paymentId && paymentGuest?.id)) &&
            paid !== null &&
            !paid ? (
              <WBFlex flexDirection="column">
                <AirwallexPaymentMethod
                  isGuest={true}
                  entityId={taskGuest.entityId ?? ''}
                  taskId={taskGuest.id ?? ''}
                  intentId={paymentGuest?.paymentIntentId || ''}
                  paymentId={paymentId}
                  onSuccess={submitInvoice}
                  onClose={() => console.log('CLOSED')}
                />
              </WBFlex>
            ) : (
              ''
            )}
            {paid || paid === null ? (
              <WBBox
                sx={{
                  mt: 7,
                  borderRadius: '50px',
                  padding: 2,
                  paddingX: 6,
                  color: 'common.black',
                  bgcolor: 'success.main',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                <WBIcon
                  name="Checkmark"
                  color={'common.black'}
                  size={'small'}
                ></WBIcon>
                {t(
                  taskGuest.direction === 'SENDING'
                    ? 'invoicePaid'
                    : 'billPaid',
                  { ns: 'payment' }
                )}
              </WBBox>
            ) : null}
          </WBBox>
        </PayForm>
      </WBContainer>
      <BottomDrawer showAlways>
        <WBFlex
          flexDirection={['column']}
          p={1}
          sx={{ bgcolor: 'background.paper' }}
        >
          <WBFlex
            gap={[2, 3]}
            justifyContent={'space-between'}
            p={1}
            flexDirection={['column-reverse', 'row']}
            alignItems={['flex-start', 'center']}
            px={[1, 3]}
          >
            <WBFlex gap={1} flex={1}>
              <WBTypography>
                {t('Need help? Contact', { ns: 'taskbox' })}
              </WBTypography>
              <WBLink href={`mailto:${SupportEmail}`}>
                {t(SupportEmail, { ns: 'taskbox' })}
              </WBLink>
            </WBFlex>
            <WBFlex
              gap={2}
              flexDirection={['column-reverse', 'row']}
              width={['100%', 'auto']}
            >
              <WBButton
                variant="outlined"
                onClick={() => {
                  downloadPdf(pdfInstanceRef, taskGuest);
                }}
              >
                {t('Download')}
              </WBButton>
            </WBFlex>
          </WBFlex>
        </WBFlex>
      </BottomDrawer>
    </GuestLayout>
  );
};

const PayForm = styled(WBFlex)(({ theme }) => ({ flex: 1 }));

const PdfContainer = styled(WBContainer)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  flexDirection: 'row',
  display: 'flex',
  flex: 1,
  paddingTop: theme.spacing(5),
  height: `calc(100% - ${theme.spacing(2)})`,
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(1),
    minHeight: `calc(100% - ${theme.spacing(20)})`,
  },
}));
