import { Task } from '@admiin-com/ds-graphql';
import { WBBox, WBCheckbox, WBFlex, WBTextField } from '@admiin-com/ds-web';
import { t } from 'i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getNestedError } from '../../helpers/string';
import { getTaskDirection } from '../../helpers/tasks';
import { useCurrentEntityId } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { useTaskPayoutDetails } from './useTaskPayoutDetails';

interface Props {
  task: Task;
}
export const TaskPayoutDetailsForm = (props: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { showBankFields, showBpayFields, hasBpayContact } =
    useTaskPayoutDetails(props.task);

  const bpayReferenceNumber = useWatch({
    control,
    name: 'bpay.referenceNumber',
  });

  const inputs = {
    bank: {
      accountNumber: {
        label: t('accountNumber', { ns: 'contacts' }),
        name: 'bank.accountNumber' as const,
        type: 'text',
        placeholder: t('accountNumberPlaceholder', { ns: 'contacts' }),
        defaultValue: '',
        rules: {
          required: t('accountNumberRequired', { ns: 'contacts' }),
          minLength: {
            value: 6,
            message: t('accountNumberMinLength', {
              ns: 'contacts',
              count: 6,
            }),
          },
          maxLength: {
            value: 12,
            message: t('accountNumberMaxLength', {
              ns: 'contacts',
              count: 12,
            }),
          },
        },
      },
      routingNumber: {
        label: t('bsb', { ns: 'contacts' }),
        name: 'bank.routingNumber' as const,
        type: 'text',
        placeholder: t('bsbPlaceholder', { ns: 'contacts' }),
        defaultValue: '',
        rules: {
          required: t('bsbRequired', { ns: 'contacts' }),
          pattern: {
            value: /^\d{6}$/,
            message: t('bsbPattern', { ns: 'contacts' }),
          },
        },
      },
    },
    bpay: {
      billerCode: {
        label: t('billerCode', { ns: 'contacts' }),
        name: 'bpay.billerCode' as const,
        type: 'number',
        placeholder: t('billerCodePlaceholder', { ns: 'contacts' }),
        defaultValue: '',
        rules: bpayReferenceNumber
          ? {
              required: t('billerCodeRequired', { ns: 'contacts' }),
              pattern: {
                value: /^\d+$/,
                message: t('billerCodeMustBeNumbers', { ns: 'contacts' }),
              },
            }
          : {},
      },
      referenceNumber: {
        label: t('referenceNumber', { ns: 'contacts' }),
        name: 'bpay.referenceNumber' as const,
        type: 'text',
        placeholder: t('referenceNumberPlaceholder', { ns: 'contacts' }),
        defaultValue: '',
        rules: {
          pattern: {
            value: /^\d{2,20}$/,
            message: t('referenceNumberMustBeBetween2And20Digits', {
              ns: 'contacts',
            }),
          },
        },
      },
      saveReferenceForFuturePayments: {
        label: 'Save Bpay Reference for future payments',
        name: 'task.saveReferenceForFuturePayments' as const,
        type: 'checkbox',
        defaultValue: false,
        rules: {},
      },
    },
  };
  const entityId = useCurrentEntityId();
  if (getTaskDirection(props.task, entityId) === 'SENDING') {
    return null;
  }

  return (
    <WBBox>
      <WBFlex gap={[0, 4]} flexDirection={{ xs: 'column', sm: 'row' }}>
        {showBankFields && (
          <Controller
            control={control}
            name={inputs.bank.accountNumber.name}
            rules={inputs.bank.accountNumber.rules}
            render={({ field }) => (
              <WBTextField
                {...field}
                label={inputs.bank.accountNumber.label}
                error={!!getNestedError(errors, field.name)}
                helperText={
                  (getNestedError(errors, field.name)?.message as string) ?? ''
                }
              />
            )}
          />
        )}
        {showBankFields && (
          <Controller
            control={control}
            name={inputs.bank.routingNumber.name}
            rules={inputs.bank.routingNumber.rules}
            render={({ field }) => (
              <WBTextField
                {...field}
                label={t('bsb', { ns: 'contacts' })}
                error={!!getNestedError(errors, field.name)}
                helperText={
                  (getNestedError(errors, field.name)?.message as string) ?? ''
                }
              />
            )}
          />
        )}
      </WBFlex>

      {showBpayFields && hasBpayContact && (
        <Controller
          control={control}
          name={inputs.bpay.billerCode.name}
          rules={inputs.bpay.billerCode.rules}
          render={({ field }) => (
            <WBTextField
              {...field}
              label={inputs.bpay.billerCode.label}
              error={!!getNestedError(errors, field.name)}
              helperText={
                (getNestedError(errors, field.name)?.message as string) ?? ''
              }
            />
          )}
        />
      )}
      {showBpayFields && (
        <>
          <Controller
            control={control}
            name={inputs.bpay.referenceNumber.name}
            rules={inputs.bpay.referenceNumber.rules}
            render={({ field }) => (
              <WBTextField
                {...field}
                label={inputs.bpay.referenceNumber.label}
                error={!!getNestedError(errors, field.name)}
                helperText={
                  (getNestedError(errors, field.name)?.message as string) ?? ''
                }
              />
            )}
          />

          <Controller
            control={control}
            name={inputs.bpay.saveReferenceForFuturePayments.name}
            rules={inputs.bpay.saveReferenceForFuturePayments.rules}
            defaultValue={
              inputs.bpay.saveReferenceForFuturePayments.defaultValue
            }
            render={({ field, formState: { errors } }) => (
              <WBCheckbox
                {...field}
                checked={field.value ?? false}
                label={inputs.bpay.saveReferenceForFuturePayments.label}
              />
            )}
          />
        </>
      )}
    </WBBox>
  );
};
