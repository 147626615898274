import { WBBox, WBContainer } from '@admiin-com/ds-web';
import { Header } from './Header';

export const FirmOnboardingLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <WBBox>
      <Header />
      <WBContainer>{children}</WBContainer>
    </WBBox>
  );
};
