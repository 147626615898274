import { WBBox, WBFlex } from '@admiin-com/ds-web';
import { Step, StepContent, StepLabel, Stepper, styled } from '@mui/material';
import React from 'react';
import { UploadBills } from './Upload';
import { ReviewBills } from './Review';
import { useTranslation } from 'react-i18next';
import XModal from '../XModal';
import InvoiceRenderer from '../InvoiceRenderer';
import { UploadBillsEdit } from './Upload/UploadBillsEdit';
import {
  Contact,
  ContactBpay,
  createDocumentAnalysis as CREATE_DOCUMENT_ANALYSIS,
  DocumentAnalysis,
  DocumentAnalysisBankAccount,
  DocumentAnalysisContact,
  DocumentAnalysisLineItemInput,
  Entity,
  PaymentFrequency,
  Task,
} from '@admiin-com/ds-graphql';
import { DATE_FORMATS, S3Upload } from '@admiin-com/ds-common';
import { gql, useMutation } from '@apollo/client';
import {
  useCurrentEntityId,
  useSelectedEntity,
} from '../../hooks/useSelectedEntity/useSelectedEntity';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { DateTime } from 'luxon';
import { useSearchParams } from 'react-router-dom';
import { useTaskCreationContext } from '../../pages/TaskCreation/TaskCreation';
import { useConvertTaskToBill } from './useConvertTaskToBill';

export interface Bill {
  from?: Contact | Entity | DocumentAnalysisContact | null;
  to?: Entity | null;
  amount?: number;
  dueAt: string;
  issuedAt?: string;
  lineItems: DocumentAnalysisLineItemInput[];
  reference?: string;
  document: S3Upload | null;
  checked: boolean;
  documentAnalysis?: DocumentAnalysis;
  bpay?: ContactBpay | null;
  saveBpayRefForFuture?: boolean;
  bank?: DocumentAnalysisBankAccount | null;
  documentSize?: string;
  documentName?: string;
  errors?: { [key: string]: string };
  paymentFrequency?: PaymentFrequency;
}

interface Props {
  draftTask: Task | null;
}

export const PayBillCreateForm = (props: Props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const { t } = useTranslation();

  const handleNext = React.useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const methods = useForm<{ bills: Bill[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { bills: [] },
  });
  const bills = useWatch({ name: 'bills', control: methods.control });
  const setBills = (bills: Bill[]) => {
    methods.setValue('bills', bills);
  };

  const { client } = useTaskCreationContext();
  const { entity } = useSelectedEntity();
  const [preivewModalOpen, setPreviewModalOpen] = React.useState(false);
  const onEnterManually = () => {
    handleNext();
    setBills([
      {
        to: client ?? entity,
        amount: 0,
        dueAt: '',
        issuedAt: DateTime.now().toFormat(DATE_FORMATS.BACKEND_DATE),
        lineItems: [],
        reference: '',
        paymentFrequency: PaymentFrequency.ONCE,
        checked: true,
        document: null,
      },
    ]);
    setIsManualInput(true);
  };

  const convertTaskToBill = useConvertTaskToBill();
  React.useEffect(() => {
    if (props.draftTask) {
      convertTaskToBill(props.draftTask).then((bill) => {
        handleNext();
        setIsManualInput(true);
        setBills([bill]);
      });
    }
  }, [props.draftTask]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangeStepParams = (value: string) => {
    const params = new URLSearchParams(searchParams);
    params.set('step', value);
    setSearchParams(params);
  };
  React.useEffect(() => {
    handleChangeStepParams(activeStep.toString());
  }, [activeStep]);

  const entityId = useCurrentEntityId();

  const onNext = (newBills: Bill[]) => {
    runBillsAnalysis(entityId, newBills);
    setBills(newBills);
    handleNext();
    setIsManualInput(false);
  };

  const [createDocumentAnalysis] = useMutation(gql(CREATE_DOCUMENT_ANALYSIS));

  const runBillsAnalysis = async (
    entityId: string,
    bills: Bill[]
  ): Promise<void> => {
    let runned = false;
    try {
      for (const bill of bills) {
        if (!bill.documentAnalysis) {
          setScanning(true);
          runned = true;
          await createDocumentAnalysis({
            variables: {
              input: {
                entityId: entityId,
                fileKey: bill.document?.key,
              },
            },
          });
        }
      }
      if (!runned) {
        setScanning(false);
      }
    } catch (error) {
      console.error('Error during bulk bill analysis:', error);
      throw error;
    }
  };

  const [isManualInput, setIsManualInput] = React.useState(false);
  const [scanning, setScanning] = React.useState(false);

  const steps = React.useMemo(
    () => [
      {
        label: t('uploadBills', { ns: 'taskbox' }),
        description: (
          <UploadBills
            onEnter={onEnterManually}
            onNext={onNext}
            setBills={setBills}
            bills={bills}
          />
        ),
      },
      {
        label: t('reviewBills', { ns: 'taskbox' }),
        description: (
          <ReviewBills
            scanning={scanning}
            setScanning={setScanning}
            bills={bills}
            setBills={setBills}
            manualBillInput={isManualInput}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bills, isManualInput]
  );

  return (
    <WBBox width="100%">
      <FormProvider {...methods}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<></>}
        >
          {steps.map((step, index) => (
            <Step key={step.label} sx={{ mt: 0 }}>
              <StyledLabel
                onClick={() => {
                  if (index < activeStep) setActiveStep(index);
                }}
              >
                <WBFlex alignItems={'center'} justifyContent={'space-between'}>
                  {step.label}
                  {activeStep !== 0 && index === 0 && (
                    <UploadBillsEdit billsCount={bills.length} />
                  )}
                </WBFlex>
              </StyledLabel>
              <StepContent TransitionProps={{ style: { width: '100%' } }}>
                {step.description}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </FormProvider>
      <XModal
        open={preivewModalOpen}
        onClose={() => {
          setPreviewModalOpen(false);
        }}
      >
        <InvoiceRenderer />
      </XModal>
    </WBBox>
  );
};
const StyledLabel = styled(StepLabel)(({ theme }) => ({
  cursor: 'pointer',
}));
