import { REGEX, STATES_AUSTRALIA } from '@admiin-com/ds-common';
import {
  WBBox,
  WBFlex,
  WBLink,
  WBSelect,
  WBTelInput,
  WBTextField,
} from '@admiin-com/ds-web';
import { matchIsValidTel } from 'mui-tel-input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { ClientInput } from '@admiin-com/ds-graphql';
import { WBLocationCompletion } from '@admiin-com/ds-amplify-web';
import { AddressResponse } from 'libs/amplify-web/src/lib/components/LocationAutoCompletion/LocationAutoCompletion';
import { mapStreetType } from '../../helpers/entities';
import { getInitials } from '../../helpers/string';

export interface ContactFormProps {
  disabled?: boolean;
  prefix?: string;
  isClient?: boolean;
  ocrCreate?: boolean;
  noMobile?: boolean;
  noAddress?: boolean;
}

export function ContactForm({
  disabled = false,
  isClient = false,
  ocrCreate = false,
  noMobile = false,
  noAddress = false,
}: ContactFormProps) {
  const { t } = useTranslation();
  const [isManual, setIsManual] = React.useState(false);
  const inputs = React.useMemo(
    () => ({
      firstName: {
        label: t('firstName', { ns: 'contacts' }),
        name: 'client.firstName' as const,
        type: 'text',
        placeholder: t('firstNamePlaceholder', { ns: 'contacts' }),
        defaultValue: '',
        rules: ocrCreate
          ? {}
          : { required: t('firstNameRequired', { ns: 'contacts' }) },
      },
      lastName: {
        label: t('lastName', { ns: 'contacts' }),
        name: 'client.lastName' as const,
        type: 'text',
        placeholder: t('lastNamePlaceholder', { ns: 'contacts' }),
        defaultValue: '',
        rules: ocrCreate
          ? {}
          : { required: t('lastNameRequired', { ns: 'contacts' }) },
      },
      email: {
        label: t('email', { ns: 'contacts' }),
        name: 'client.email' as const,
        type: 'email',
        defaultValue: '',
        placeholder: t('emailPlaceholder', { ns: 'contacts' }),
        rules: ocrCreate
          ? {
              pattern: {
                value: REGEX.EMAIL,
                message: t('invalidEmail', { ns: 'common' }),
              },
            }
          : {
              required: t('emailRequired', { ns: 'common' }),
              pattern: {
                value: REGEX.EMAIL,
                message: t('invalidEmail', { ns: 'common' }),
              },
            },
      },
      mobile: {
        label: t('mobile', { ns: 'contacts' }),
        name: 'client.mobile' as const,
        type: 'text',
        placeholder: t('mobilePlaceholder', { ns: 'contacts' }),
        defaultValue: '',
        rules: {
          required: isClient ? t('mobileRequired', { ns: 'contacts' }) : false,
          validate: (value: string | null | undefined) => {
            console.log('value: ', value);
            return (
              value === null ||
              value === undefined ||
              value === '' ||
              matchIsValidTel(value) ||
              t('invalidMobile', { ns: 'common' })
            );
          },
        },
      },
      phone: {
        label: t('phone', { ns: 'contacts' }),
        name: 'client.phone' as const,
        type: 'text',
        placeholder: t('phonePlaceholder', { ns: 'contacts' }),
        defaultValue: '',
        rules: {},
      },
      address: {
        label: t(isClient ? 'address' : 'businessAddress', {
          ns: 'onboarding',
        }),
        name: 'client.address.address1' as const,
        type: 'text',
        placeholder: t('businessAddressPlaceholder', { ns: 'onboarding' }),
        defaultValue: '',
        rules: {},
      },
      manual: {
        unit_number: {
          label: t('unitNumber', { ns: 'onboarding' }),
          name: 'client.address.unitNumber' as const,
          type: 'text',
          placeholder: t('unitNumberPlaceholder', { ns: 'onboarding' }),
          defaultValue: '',
          rules: {},
        },
        street_number: {
          label: t('streetNumber', { ns: 'onboarding' }),
          name: 'client.address.streetNumber' as const,
          type: 'text',
          placeholder: t('streetNumberPlaceholder', { ns: 'onboarding' }),
          defaultValue: '',
          rules: {},
        },
        street_name: {
          label: t('streetName', { ns: 'onboarding' }),
          name: 'client.address.streetName' as const,
          type: 'text',
          placeholder: t('streetNamePlaceholder', { ns: 'onboarding' }),
          defaultValue: '',
          rules: isManual
            ? {
                required: t('streetNameRequired', { ns: 'onboarding' }),
              }
            : {},
        },
        city: {
          label: t('city', { ns: 'onboarding' }),
          name: 'client.address.city' as const,
          type: 'text',
          placeholder: t('cityPlaceholder', { ns: 'onboarding' }),
          defaultValue: '',
          rules: isManual
            ? {
                required: t('cityRequired', { ns: 'onboarding' }),
              }
            : {},
        },
        state: {
          label: t('state', { ns: 'onboarding' }),
          name: 'client.address.state' as const,
          type: 'text',
          placeholder: t('statePlaceholder', { ns: 'onboarding' }),
          defaultValue: '',
          rules: isManual
            ? {
                required: t('stateRequired', { ns: 'onboarding' }),
              }
            : {},
        },
        country: {
          label: t('country', { ns: 'onboarding' }),
          name: 'client.address.country' as const,
          type: 'text',
          placeholder: t('countryPlaceholder', { ns: 'onboarding' }),
          defaultValue: 'AUS',
          rules: isManual
            ? {
                required: t('countryRequired', { ns: 'onboarding' }),
              }
            : {},
        },
        postcode: {
          label: t('postcode', { ns: 'onboarding' }),
          name: 'client.address.postalCode' as const,
          type: 'text',
          placeholder: t('postcodePlaceholder', { ns: 'onboarding' }),
          defaultValue: '',
          rules: isManual
            ? {
                required: t('postcodeRequired', { ns: 'onboarding' }),
              }
            : {},
        },
      },
    }),
    [t, isClient, isManual, ocrCreate]
  );

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<{
    client: ClientInput;
  }>();

  return (
    <>
      <WBFlex flexDirection={['column', 'row']}>
        <WBBox flex={1} pr={[0, 3]}>
          <Controller
            control={control}
            name={inputs.firstName.name}
            rules={inputs.firstName.rules}
            defaultValue={inputs.firstName.defaultValue}
            render={({ field }) => (
              <WBTextField
                {...field}
                type="text"
                variant="standard"
                label={inputs.firstName.label}
                placeholder={inputs.firstName.placeholder}
                error={!!errors?.client?.firstName}
                helperText={errors?.client?.firstName?.message || ''}
                margin="dense"
                disabled={disabled}
              />
            )}
          />
        </WBBox>
        <WBBox flex={1} pl={[0, 3]}>
          <Controller
            control={control}
            name={inputs.lastName.name}
            rules={inputs.lastName.rules}
            defaultValue={inputs.lastName.defaultValue}
            render={({ field }) => (
              <WBTextField
                {...field}
                type="text"
                variant="standard"
                label={inputs.lastName.label}
                placeholder={inputs.lastName.placeholder}
                error={!!errors?.client?.lastName}
                helperText={errors?.client?.lastName?.message || ''}
                margin="dense"
                disabled={disabled}
              />
            )}
          />
        </WBBox>
      </WBFlex>
      <WBFlex flexDirection={['column', 'row']} mt={[0, 4]}>
        <WBBox flex={1} pr={[0, 3]}>
          <Controller
            control={control}
            name={inputs.email.name}
            rules={inputs.email.rules}
            defaultValue={inputs.email.defaultValue}
            render={({ field }) => (
              <WBTextField
                {...field}
                type="text"
                variant="standard"
                label={inputs.email.label}
                placeholder={inputs.email.placeholder}
                error={!!errors?.client?.email}
                helperText={errors?.client?.email?.message || ''}
                margin="dense"
                disabled={disabled}
              />
            )}
          />
        </WBBox>
        {!noMobile && (
          <WBBox flex={1} pl={[0, 3]}>
            <Controller
              control={control}
              name={inputs.mobile.name}
              defaultValue={inputs.mobile.defaultValue}
              rules={inputs.mobile.rules}
              render={({ field, fieldState }) => (
                //@ts-ignore - value shouldn't be null but is possible by react-form-hooks
                <WBTelInput
                  {...field}
                  variant="standard"
                  helperText={
                    fieldState.invalid
                      ? t('invalidPhone', { ns: 'common' })
                      : ''
                  }
                  error={fieldState.invalid}
                  focusOnSelectCountry
                  defaultCountry="AU"
                  label={inputs.mobile.label}
                  margin="dense"
                  disabled={disabled}
                />
              )}
            />
          </WBBox>
        )}
      </WBFlex>

      <WBFlex flexDirection={['column', 'row']} mt={[0, 4]}>
        <WBBox flex={1} pr={[0, 3]}>
          <Controller
            control={control}
            name={inputs.phone.name}
            defaultValue={inputs.phone.defaultValue}
            rules={inputs.phone.rules}
            render={({ field, fieldState }) => (
              <WBTextField
                {...field}
                type="text"
                variant="standard"
                label={inputs.phone.label}
                placeholder={inputs.phone.placeholder}
                error={!!errors?.client?.phone}
                helperText={errors?.client?.phone?.message || ''}
                margin="dense"
                disabled={disabled}
              />
            )}
          />
        </WBBox>
      </WBFlex>
      {!isClient && !noAddress && (
        <WBFlex flexDirection={['column']} mt={[0, 4]}>
          <Controller
            control={control}
            name={inputs.address.name}
            defaultValue={inputs.address.defaultValue}
            rules={inputs.address.rules}
            render={({ field }) => (
              <WBBox>
                <WBLocationCompletion
                  {...field}
                  label={inputs.address.label}
                  type={inputs.address.type}
                  placeholder={inputs.address.placeholder}
                  error={
                    !!(
                      errors.client?.address?.address1 &&
                      errors.client?.address?.address1.message
                    )
                  }
                  helperText={
                    ((errors.client?.address?.address1 &&
                      errors.client?.address?.address1.message) as string) || ''
                  }
                  onChange={(address: AddressResponse) => {
                    field.onChange(address.label);
                    console.log(address);
                    setValue('client.address.addressId', address.id);
                    setValue('client.address.unitNumber', address.unitNumber);
                    setValue('client.address.postalCode', address.postalCode);
                    //TODO: add support for suburb - airwallex considers suburb as city
                    //setValue('client.address.city', address.municipality);
                    //setValue('client.address.suburb', address.neighborhood);
                    setValue('client.address.city', address.neighborhood);
                    setValue('client.address.state', address.region);
                    setValue(
                      'client.address.stateCode',
                      getInitials(address.region)
                    );
                    setValue('client.address.streetName', address.street);
                    setValue(
                      'client.address.streetType',
                      mapStreetType(address.street)
                    );
                    setValue(
                      'client.address.streetNumber',
                      address.addressNumber
                    );
                    setValue('client.address.country', address.country);
                  }}
                  margin="dense"
                />
              </WBBox>
            )}
          />
          {!isManual && (
            <WBLink
              variant="body2"
              mt={2}
              underline="always"
              onClick={() => setIsManual(true)}
            >
              {t('enterAddressManually', { ns: 'onboarding' })}
            </WBLink>
          )}
        </WBFlex>
      )}
      {isManual && !noAddress && (
        <>
          <WBFlex flexDirection={['column', 'row']}>
            <WBBox flex={1} pr={[0, 3]}>
              <Controller
                control={control}
                name={inputs.manual.unit_number.name}
                rules={inputs.manual.unit_number.rules}
                defaultValue={inputs.manual.unit_number.defaultValue}
                render={({ field }) => (
                  <WBTextField
                    {...field}
                    type="text"
                    label={inputs.manual.unit_number.label}
                    placeholder={inputs.manual.unit_number.placeholder}
                    error={!!errors?.client?.address?.unitNumber}
                    helperText={
                      errors?.client?.address?.unitNumber?.message || ''
                    }
                    margin="dense"
                  />
                )}
              />
            </WBBox>
            <WBBox flex={1} pr={[0, 3]}>
              <Controller
                control={control}
                name={inputs.manual.street_number.name}
                rules={inputs.manual.street_number.rules}
                defaultValue={inputs.manual.street_number.defaultValue}
                render={({ field }) => (
                  <WBTextField
                    {...field}
                    label={inputs.manual.street_number.label}
                    type="text"
                    placeholder={inputs.manual.street_number.placeholder}
                    error={!!errors?.client?.address?.streetNumber}
                    helperText={
                      errors?.client?.address?.streetNumber?.message || ''
                    }
                    margin="dense"
                  />
                )}
              />
            </WBBox>
            <WBBox flex={3}>
              <Controller
                control={control}
                name={inputs.manual.street_name.name}
                rules={inputs.manual.street_name.rules}
                defaultValue={inputs.manual.street_name.defaultValue}
                render={({ field }) => (
                  <WBTextField
                    {...field}
                    label={inputs.manual.street_name.label}
                    type="text"
                    placeholder={inputs.manual.street_name.placeholder}
                    error={!!errors?.client?.address?.streetName}
                    helperText={
                      errors?.client?.address?.streetName?.message || ''
                    }
                    margin="dense"
                  />
                )}
              />
            </WBBox>
          </WBFlex>

          <WBFlex mt={[0, 4]}>
            <Controller
              control={control}
              name={inputs.manual.city.name}
              rules={inputs.manual.city.rules}
              defaultValue={inputs.manual.city.defaultValue}
              render={({ field }) => (
                <WBTextField
                  {...field}
                  label={inputs.manual.city.label}
                  type="text"
                  placeholder={inputs.manual.city.placeholder}
                  error={!!errors?.client?.address?.city}
                  helperText={errors?.client?.address?.city?.message || ''}
                  margin="dense"
                />
              )}
            />
          </WBFlex>
          <WBFlex flexDirection={['column', 'row']}>
            <Controller
              control={control}
              name={inputs.manual.state.name}
              rules={inputs.manual.state.rules}
              defaultValue={inputs.manual.state.defaultValue}
              render={({ field }) => (
                <WBBox flex={1} pr={[0, 3]}>
                  <WBSelect
                    {...field}
                    label={inputs.manual.state.label}
                    error={!!errors?.client?.address?.state}
                    helperText={errors?.client?.address?.state?.message || ''}
                    margin="dense"
                    options={STATES_AUSTRALIA.map(
                      (state: { code: string; name: string }) => ({
                        label: state.name,
                        value: state.name,
                      })
                    )}
                  />
                </WBBox>
              )}
            />

            <Controller
              control={control}
              name={inputs.manual.country.name}
              rules={inputs.manual.country.rules}
              defaultValue={inputs.manual.country.defaultValue}
              render={({ field }) => (
                <WBBox flex={1} pr={[0, 3]}>
                  <WBTextField
                    {...field}
                    aria-readonly
                    value={'AUS'}
                    label={inputs.manual.country.label}
                    type="text"
                    placeholder={inputs.manual.country.placeholder}
                    error={!!errors?.client?.address?.country}
                    helperText={errors?.client?.address?.country?.message || ''}
                    margin="dense"
                  />
                </WBBox>
              )}
            />
            <Controller
              control={control}
              name={inputs.manual.postcode.name}
              rules={inputs.manual.postcode.rules}
              defaultValue={inputs.manual.postcode.defaultValue}
              render={({ field }) => (
                <WBBox flex={1}>
                  <WBTextField
                    {...field}
                    label={inputs.manual.postcode.label}
                    type="text"
                    placeholder={inputs.manual.postcode.placeholder}
                    error={!!errors?.client?.address?.postalCode}
                    helperText={
                      errors?.client?.address?.postalCode?.message || ''
                    }
                    margin="dense"
                  />
                </WBBox>
              )}
            />
          </WBFlex>
        </>
      )}
    </>
  );
}

export default ContactForm;
