import { Task, updateContact } from '@admiin-com/ds-graphql';
import { useSnackbar, WBForm, WBTypography } from '@admiin-com/ds-web';
import { FormProvider, useForm } from 'react-hook-form';
import { useTaskToContactName } from '../../hooks/useTaskToName/useTaskToName';
import React from 'react';
import { t } from 'i18next';
import { useUpdateTask } from '../../hooks/useUpdateTask/useUpdateTask';
import { gql, useMutation } from '@apollo/client';
import { usePaymentContext } from '../../components/PaymentContainer/PaymentContainer';
import ConfirmationDlg from '../../components/ConfirmationDlg/ConfirmationDlg';
import { TaskPayoutDetailsForm } from './TaskPayoutDetailsForm';
import { useTaskPayoutDetails } from './useTaskPayoutDetails';
import { isPayoutFailedTask } from '../../helpers/tasks';

type TaskPayoutDetailFormData = {
  bank: {
    accountNumber: string;
    routingNumber: string;
  };
  bpay: {
    billerCode: string;
    referenceNumber: string;
    saveReferenceForFuturePayments: boolean;
  };
};

export const TaskPayoutDetailModal = ({
  onSubmit,
  task,
  open,
  onClose,
  isRetry,
}: {
  onSubmit: () => void;
  task: Task | null;
  open: boolean;
  onClose: () => void;
  isRetry?: boolean;
}) => {
  const showSnackbar = useSnackbar();
  const [updateTaskMutation] = useUpdateTask(task);
  const [updateContactMutation] = useMutation(gql(updateContact));
  const { showBankFields, showBpayFields, contact, hasBpayContact } =
    useTaskPayoutDetails(task);

  const methods = useForm<TaskPayoutDetailFormData>({
    values: {
      bank: {
        accountNumber: contact?.bank?.accountNumber ?? '',
        routingNumber: contact?.bank?.routingNumber ?? '',
      },
      bpay: {
        billerCode: contact?.bpay?.billerCode?.toString() ?? '',
        referenceNumber:
          task?.bpayReferenceNumber ??
          task?.futureLinkedTask?.bpayReferenceNumber ??
          contact?.bpay?.referenceNumber ??
          '',
        saveReferenceForFuturePayments: true,
      },
    },
  });

  const updatePaymentDetails = async () => {
    const data = methods.watch();
    if (!data) {
      onClose();
      return;
    }
    try {
      if (showBankFields || hasBpayContact)
        await updateContactMutation({
          variables: {
            input: {
              id: task?.contactId,
              entityId: contact?.entityId,
              bank: showBankFields
                ? {
                    ...contact?.bank,
                    accountName:
                      contact?.bank?.accountName ??
                      contact?.name ??
                      contact?.legalName,
                    accountNumber: data.bank.accountNumber,
                    routingNumber: data.bank.routingNumber,
                    __typename: undefined,
                  }
                : undefined,
              bpay: hasBpayContact
                ? {
                    ...contact?.bpay,
                    billerCode: parseInt(data.bpay.billerCode),
                    referenceNumber: data.bpay.saveReferenceForFuturePayments
                      ? data.bpay.referenceNumber
                      : contact?.bpay?.referenceNumber,
                    __typename: undefined,
                  }
                : undefined,
            },
          },
        });

      if (showBpayFields && task)
        await updateTaskMutation({
          variables: {
            input: {
              id: task.id,
              entityId: task.entityId,
              bpayReferenceNumber:
                data?.bpay.referenceNumber ??
                task.bpayReferenceNumber ??
                task.futureLinkedTask?.bpayReferenceNumber,
              futureLinkedTask: task.futureLinkedTask
                ? {
                    ...task.futureLinkedTask,
                    __typename: undefined,
                    lineItems: task.futureLinkedTask.lineItems?.map((item) => ({
                      ...item,
                      __typename: undefined,
                    })),
                    bpayReferenceNumber:
                      data?.bpay.referenceNumber ??
                      task.futureLinkedTask?.bpayReferenceNumber,
                  }
                : null,
            },
          },
        });
      onSubmit();
    } catch (error: any) {
      showSnackbar({
        message: error.message,
        severity: 'error',
      });
    } finally {
      onClose();
    }
  };

  return (
    <FormProvider {...methods}>
      <WBForm onSubmit={methods.handleSubmit(updatePaymentDetails)}>
        <ConfirmationDlg
          title={t('updateRetryPayout', { ns: 'payment' })}
          confirmText={
            isPayoutFailedTask(task)
              ? t('updateAndRetryPayout', { ns: 'payment' })
              : t('Update', { ns: 'payment' })
          }
          open={open}
          onClose={onClose}
          onOK={methods.handleSubmit(updatePaymentDetails)}
          notCloseAutomatically
        >
          <WBTypography>
            {t('correctPayoutDetails', {
              ns: 'payment',
            })}
          </WBTypography>
          {task && <TaskPayoutDetailsForm task={task} />}
        </ConfirmationDlg>
      </WBForm>
    </FormProvider>
  );
};
