import {
  WBBox,
  WBFlex,
  WBSwitch,
  WBTooltip,
  WBTypography,
} from '@admiin-com/ds-web';
import { t } from 'i18next';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BillPayForm } from '../../PayBill/Review/BillPayForm';
import { useSelectedEntity } from '../../../hooks/useSelectedEntity/useSelectedEntity';
import { useTaskCreationContext } from '../../../pages/TaskCreation/TaskCreation';
import { Switch } from '@mui/material';

export const SignaturePayForm = () => {
  const [showForm, setShowForm] = React.useState(false);
  const { setValue } = useFormContext();
  const { entity } = useSelectedEntity();
  const { taskDirection } = useTaskCreationContext();
  const isNonAbnEntity = !entity?.taxNumber;
  console.log(isNonAbnEntity, taskDirection, entity?.taxNumber);

  return (
    <WBBox>
      <WBFlex mt={5} alignItems={'center'} justifyContent={'space-between'}>
        <WBBox>
          <WBTypography variant="h5">
            {t('signaturePayTitle', { ns: 'taskbox' })}
          </WBTypography>
          <WBTypography color={'text.secondary'}>
            {t('signaturePaySubTitle', { ns: 'taskbox' })}
          </WBTypography>
        </WBBox>
        <WBTooltip
          title={
            taskDirection === 'SENDING' && isNonAbnEntity
              ? t('nonAbnSignaturePayDisabled', { ns: 'taskbox' })
              : ''
          }
        >
          <Switch
            inputProps={{ 'aria-label': 'controlled' }}
            checked={showForm}
            onChange={(e) => {
              if (taskDirection === 'SENDING' && isNonAbnEntity) return;
              setShowForm(e.target.checked);
              if (!e.target.checked) {
                setValue('futureTaskInput', undefined);
              }
            }}
          />
        </WBTooltip>
      </WBFlex>
      {showForm && <BillPayForm isNonAbnEntity={isNonAbnEntity} />}
    </WBBox>
  );
};
