import { useEffect, useState } from 'react';
import PdfViewer from '../../PdfViewer/PdfViewer';
import { getFromS3Storage } from '@admiin-com/ds-amplify';
import { Bill } from '../PayBillCreateForm';
import { WBS3Image } from '@admiin-com/ds-amplify-web';
import { WBImage } from '@admiin-com/ds-web';
import {
  createDocumentAnalysisDocumentUrl,
  S3UploadType,
} from '@admiin-com/ds-graphql';
import { gql, useMutation } from '@apollo/client';

export const BillViewer = (props: { bill: Bill }) => {
  const { bill } = props;
  const [url, setUrl] = useState(bill.document?.src);

  const [createDocumentAnalysisDocumentUrlMuation] = useMutation(
    gql(createDocumentAnalysisDocumentUrl)
  );

  useEffect(() => {
    if (bill.document?.src) {
      setUrl(bill.document?.src);
    } else {
      const id = bill.documentAnalysis?.id;
      createDocumentAnalysisDocumentUrlMuation({
        variables: {
          input: {
            documentAnalysisId: id,
          },
        },
      }).then((data) => {
        const url = data.data.createDocumentAnalysisDocumentUrl.url;
        setUrl(url);
      });
    }
  }, [bill.document, bill.documentAnalysis?.id]);

  const isImage = bill.document?.type === S3UploadType.IMAGE;
  return isImage && url ? (
    <WBImage src={url} responsive />
  ) : (
    <PdfViewer documentUrl={url} />
  );
};
