import {
  InvoiceStatus,
  Task,
  TaskDirection,
  TaskPaymentStatus,
  TaskQuoteStatus,
  TaskSignatureStatus,
  TaskStatus,
  TaskType,
} from '@admiin-com/ds-graphql';
import {
  useSnackbar,
  WBBox,
  WBIconButton,
  WBMenu,
  WBMenuItem,
  WBStack,
  WBSvgIcon,
  WBTooltip,
  WBTypography,
} from '@admiin-com/ds-web';
import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChatIcon from '../../../assets/icons/chat-icon.svg';
import DotIcon from '../../../assets/icons/tripledot.svg';
import BackModal from '../../components/BackModal/BackModal';
import ConfirmationDlg from '../../components/ConfirmationDlg/ConfirmationDlg';
import { downloadPdf } from '../../helpers/signature';
import { isSignedTask } from '../../helpers/tasks';
import { useSentTaskOptions } from '../../hooks/useSentTaskOptions/useSentTaskOptions';
import { useTaskProperty } from '../../hooks/useTaskProperty/useTaskProperty';
import { useUpdateTask } from '../../hooks/useUpdateTask/useUpdateTask';
import { useTaskBoxContext } from '../TaskBox/TaskBox';
import { TaskCreation } from '../TaskCreation/TaskCreation';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { TaskSignersModal } from './TaskSignersModal';

export default function TaskMenu({ task }: { task: Task }) {
  const taskboxContext = useTaskBoxContext();
  const { setSelectedTask, pdfSignatureRef } = taskboxContext ?? {};

  const { isAchivable, isCreatedBy, isDownloadable, canCopyLink } =
    useTaskProperty(task);
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [updateTask, { loading: archiving }] = useUpdateTask(task);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const showSnackbar = useSnackbar();
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const { entity } = useSelectedEntity();

  const [copying, setCopying] = useState(false);
  const [resending, setResending] = useState(false);

  const handleArchiveTask = async () => {
    handleMenuClose();
    if (task) {
      try {
        await updateTask({
          variables: {
            input: {
              id: task.id,
              entityId: task.entityId,
              status: TaskStatus.ARCHIVED,
            },
          },
        });
        showSnackbar({
          message: t('taskArchived', { ns: 'taskbox' }),
          severity: 'success',
          horizontal: 'center',
          vertical: 'bottom',
        });
        setSelectedTask(null);
      } catch (error: any) {
        showSnackbar({
          title: t('taskArchivedFailed', { ns: 'taskbox' }),
          message: error?.message,
          severity: 'error',
          horizontal: 'center',
          vertical: 'bottom',
        });
      }
    }
  };

  const handleMarkAsPaidTask = async () => {
    handleMenuClose();
    if (task) {
      try {
        await updateTask({
          variables: {
            input: {
              id: task.id,
              entityId: task.entityId,
              paymentStatus: TaskPaymentStatus.MARKED_AS_PAID,
            },
          },
        });
        // setStatusFilter('Completed');
        showSnackbar({
          message: t('markAsPaidSuccess', { ns: 'taskbox' }),
          severity: 'success',
          horizontal: 'center',
          vertical: 'bottom',
        });
      } catch (error: any) {
        showSnackbar({
          title: t('markAsPaidFailed', { ns: 'taskbox' }),
          message: error?.message,
          severity: 'error',
          horizontal: 'center',
          vertical: 'bottom',
        });
      }
    }
  };

  const handleMarkAsCompletedTask = async () => {
    handleMenuClose();
    if (task) {
      try {
        await updateTask({
          variables: {
            input: {
              id: task.id,
              entityId: task.entityId,
              paymentStatus: TaskPaymentStatus.MARKED_AS_PAID,
            },
          },
        });
        showSnackbar({
          message: t('markAsCompletedSuccess', { ns: 'taskbox' }),
          severity: 'success',
          horizontal: 'center',
          vertical: 'bottom',
        });
      } catch (error: any) {
        showSnackbar({
          title: t('markAsCompletedFailed', { ns: 'taskbox' }),
          message: error?.message,
          severity: 'error',
          horizontal: 'center',
          vertical: 'bottom',
        });
      }
    }
  };

  const handleMarkAsAcceptedTask = async () => {
    handleMenuClose();
    if (task) {
      try {
        await updateTask({
          variables: {
            input: {
              id: task.id,
              entityId: task.entityId,
              quoteStatus: TaskQuoteStatus.MARKED_AS_ACCEPTED,
            },
          },
        });
        showSnackbar({
          message: t('markAsAcceptedSuccess', { ns: 'taskbox' }),
          severity: 'success',
          horizontal: 'center',
          vertical: 'bottom',
        });
      } catch (error: any) {
        showSnackbar({
          title: t('markAsAcceptedFailed', { ns: 'taskbox' }),
          message: error?.message,
          severity: 'error',
          horizontal: 'center',
          vertical: 'bottom',
        });
      }
    }
  };

  const handleMarkAsSignedTask = async () => {
    handleMenuClose();
    if (task) {
      try {
        await updateTask({
          variables: {
            input: {
              id: task.id,
              entityId: task.entityId,
              signatureStatus: TaskSignatureStatus.SIGNED,
            },
          },
        });
        // setStatusFilter('Completed');
        showSnackbar({
          message: t('markAsPaidSuccess', { ns: 'taskbox' }),
          severity: 'success',
          horizontal: 'center',
          vertical: 'bottom',
        });
      } catch (error: any) {
        showSnackbar({
          title: t('markAsPaidFailed', { ns: 'taskbox' }),
          message: error?.message,
          severity: 'error',
          horizontal: 'center',
          vertical: 'bottom',
        });
      }
    }
  };

  const downloadDocument = () => {
    downloadPdf(pdfSignatureRef, task as any);
  };

  const noOptions =
    task?.direction === TaskDirection.RECEIVING &&
    !(
      isAchivable ||
      isDownloadable ||
      (isCreatedBy && task?.paymentStatus === TaskPaymentStatus.PENDING_PAYMENT)
    );
  const { handleCopyTaskLink, QRCodeComponent } = useSentTaskOptions({ task });

  const [taskSignerModalOpen, setTaskSignerModalOpen] =
    React.useState<boolean>(false);
  const handleCopyLink = async (resend: boolean | undefined = false) => {
    if (task.type === TaskType.SIGN_ONLY) {
      setTaskSignerModalOpen(true);
      handleMenuClose();
    } else {
      setCopying(true);
      await handleCopyTaskLink(resend);
      handleMenuClose();
      setCopying(false);
    }
  };
  const handleCopyLinkResend = async () => {
    setResending(true);
    await handleCopyTaskLink(true);
    setResending(false);
  };
  const canEditSigners = !isSignedTask(task);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const handleEditSigners = () => {
    setTaskEditModalOpen(true);
    handleMenuClose();
  };

  const [taskEditModalOpen, setTaskEditModalOpen] =
    React.useState<boolean>(false);

  return (
    <>
      {task ? (
        <WBStack
          direction={'row'}
          spacing={1}
          sx={{
            position: { xs: 'absolute', lg: 'relative' },
            right: { xs: '24px', md: '64px', lg: '0px' },
            top: { xs: '32px', lg: '0px' },
            // ...(contact
            //   ? { mt: { xs: -32, lg: 0 }, ml: { xs: -14, lg: 0 } }
            //   : { mt: { xs: -10, lg: 0 }, ml: { xs: -6, lg: 0 } }),
          }}
        >
          <WBTooltip title={t('chatComingSoon', { ns: 'taskbox' })}>
            <WBBox>
              <WBIconButton disabled>
                <WBSvgIcon fontSize="small" color={theme.palette.text.primary}>
                  <ChatIcon />
                </WBSvgIcon>
              </WBIconButton>
            </WBBox>
          </WBTooltip>

          <WBIconButton disabled={noOptions} onClick={handleOpenMenu}>
            <WBSvgIcon fontSize="small" color={theme.palette.text.primary}>
              <DotIcon />
            </WBSvgIcon>
          </WBIconButton>
        </WBStack>
      ) : null}
      <WBMenu
        sx={{ mt: -2 }}
        id="customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {/* Todo: check if user is entity owner and bill is in status(TBC)*/}
        {isAchivable ? (
          <WBMenuItem
            onClick={() => {
              setConfirmDialogOpen(true);
              handleMenuClose();
            }}
            sx={{
              ...theme.typography.body2,
              fontWeight: 'bold',
              color: 'error.main',
            }}
          >
            {t('archiveTask', { ns: 'taskbox' })}
          </WBMenuItem>
        ) : null}
        {isDownloadable && (
          <WBMenuItem
            onClick={downloadDocument}
            sx={{
              ...theme.typography.body2,
            }}
          >
            {t('download', { ns: 'taskbox' })}
          </WBMenuItem>
        )}
        {isCreatedBy &&
          task?.paymentStatus === TaskPaymentStatus.PENDING_PAYMENT && (
            <WBMenuItem
              onClick={handleMarkAsPaidTask}
              sx={{
                ...theme.typography.body2,
              }}
            >
              {t('markAsPaid', { ns: 'taskbox' })}
            </WBMenuItem>
          )}
        {isCreatedBy &&
          task?.paymentStatus === TaskPaymentStatus.SCHEDULED &&
          task.isUnlimitedRecurring && (
            <WBMenuItem
              onClick={handleMarkAsCompletedTask}
              sx={{
                ...theme.typography.body2,
              }}
            >
              {t('markAsCompleted', { ns: 'taskbox' })}
            </WBMenuItem>
          )}
        {isCreatedBy &&
          task.status !== TaskStatus.DRAFT &&
          ((task as Task)?.quoteStatus === TaskQuoteStatus.PENDING_ACCEPTANCE ||
            (task as Task)?.invoiceStatus === InvoiceStatus.QUOTE) && (
            <WBMenuItem
              onClick={handleMarkAsAcceptedTask}
              sx={{
                ...theme.typography.body2,
              }}
            >
              {t('markAsAccepted', { ns: 'taskbox' })}
            </WBMenuItem>
          )}
        {task.status !== TaskStatus.DRAFT &&
          isCreatedBy &&
          task?.signatureStatus === TaskSignatureStatus.PENDING_SIGNATURE && (
            <WBMenuItem
              onClick={handleMarkAsSignedTask}
              sx={{
                ...theme.typography.body2,
              }}
            >
              {t('markAsSigned', { ns: 'taskbox' })}
            </WBMenuItem>
          )}

        {task.status !== TaskStatus.DRAFT &&
          isCreatedBy &&
          canEditSigners &&
          task.type === TaskType.SIGN_ONLY && (
            <WBMenuItem
              onClick={handleEditSigners}
              sx={{
                ...theme.typography.body2,
              }}
            >
              {t('editSigners', { ns: 'taskbox' })}
            </WBMenuItem>
          )}

        {(canCopyLink || !entity?.taxNumber) &&
          ((task.toId !== entity?.id && task.direction === 'SENDING') ||
            task.direction === 'RECEIVING') && (
            // !isInstallmentTask(task) &&
            <WBMenuItem
              onClick={() => handleCopyLink(false)}
              sx={{
                ...theme.typography.body2,
              }}
            >
              {copying && t('generatingLink', { ns: 'taskbox' })}
              {!copying &&
                t(
                  task.type !== TaskType.SIGN_ONLY
                    ? (task as Task).invoiceStatus === 'INVOICE'
                      ? 'copyLinkToPayment'
                      : !entity?.taxNumber
                      ? 'resendEmailPayment'
                      : 'copyLinkToQutoe'
                    : 'copyLinkToDocument',
                  { ns: 'taskbox' }
                )}
            </WBMenuItem>
          )}
        {canCopyLink &&
          ((task.toId !== entity?.id && task.direction === 'SENDING') ||
            task.direction === 'RECEIVING') &&
          (task.type === 'PAY_ONLY' || task.type === 'QUOTE') && (
            // !isInstallmentTask(task) &&
            <WBMenuItem
              onClick={() => handleCopyLinkResend()}
              sx={{
                ...theme.typography.body2,
              }}
            >
              {resending && t('sendingEmail', { ns: 'taskbox' })}
              {!resending &&
                t(
                  (task as Task).invoiceStatus === 'INVOICE'
                    ? 'resendEmailToInvoice'
                    : 'resendEmailToQuote',
                  { ns: 'taskbox' }
                )}
            </WBMenuItem>
          )}
        {/*{task?.direction === TaskDirection.SENDING && task.type === TaskType.PAY_ONLY && (*/}
        {/*  <WBMenuItem*/}
        {/*    onClick={() => handleCopyLink(true)}*/}
        {/*    sx={{*/}
        {/*      ...theme.typography.body2,*/}
        {/*      //fontWeight: 'bold',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {t(*/}
        {/*      (task as Task).invoiceStatus === 'INVOICE'*/}
        {/*        ? 'resendEmailPayment'*/}
        {/*        : 'resendEmailQuote',*/}
        {/*      { ns: 'taskbox' }*/}
        {/*    )}*/}
        {/*  </WBMenuItem>*/}
        {/*)}*/}
        {/*{task.direction === TaskDirection.SENDING && (*/}
        {/*  // !isInstallmentTask(task) &&*/}
        {/*  <WBMenuItem*/}
        {/*    onClick={() => {*/}
        {/*      setModalOpen(true);*/}
        {/*      handleMenuClose();*/}
        {/*    }}*/}
        {/*    sx={{*/}
        {/*      ...theme.typography.body2,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {t('showQRCode', { ns: 'taskbox' })}*/}
        {/*  </WBMenuItem>*/}
        {/*)}*/}
      </WBMenu>
      <ConfirmationDlg
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        title={t('archiveTask', { ns: 'taskbox' })}
        onOK={handleArchiveTask}
        loading={archiving}
      >
        <WBTypography variant="body1" mt={1}>
          {t('archiveTaskConfirmation', { ns: 'taskbox' })}
        </WBTypography>
      </ConfirmationDlg>
      <BackModal
        height={'auto'}
        open={modalOpen}
        fullWidth={false}
        onClose={() => setModalOpen(false)}
      >
        {QRCodeComponent}
      </BackModal>
      {taskEditModalOpen && (
        <TaskCreation
          task={task}
          open={taskEditModalOpen}
          handleCloseModal={() => setTaskEditModalOpen(false)}
        />
      )}
      <TaskSignersModal
        open={taskSignerModalOpen}
        onClose={() => setTaskSignerModalOpen(false)}
        task={task}
      />
    </>
  );
}
