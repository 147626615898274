import React from 'react';
import { WBFullScreenModal } from '@admiin-com/ds-web';
import { useTranslation } from 'react-i18next';
import { Contact } from '@admiin-com/ds-graphql';
import { ContactsCreateForm } from '../ContactDetail/ContactsCreateForm';
import SimpleDrawDlg from '../../components/SimpleDrawDlg/SimpleDrawDlg';
import { DialogContent, DialogTitle } from '@mui/material';
import { ContactDetailUpdateForm } from '../ContactDetail/ContactDetailUpdateForm';
interface BulkImportProps {
  open: boolean;
  entityId?: string;
  onSuccess?: (contact: Contact) => void;
  handleCloseModal: () => void;
  updatingContact?: any;
  ocrCreate?: boolean;
}

export function ContactDetailUpdateModal({
  open,
  onSuccess,
  entityId,
  handleCloseModal,
  updatingContact,
  ocrCreate,
}: BulkImportProps) {
  const { t } = useTranslation();

  return (
    <SimpleDrawDlg handleClose={handleCloseModal} open={open}>
      <DialogTitle variant="h3">
        {t('Update contact', { ns: 'contacts' })}
      </DialogTitle>
      <DialogContent>
        <ContactDetailUpdateForm
          entityId={entityId}
          updatingContact={updatingContact}
          onSubmitted={(contact) => {
            onSuccess && onSuccess(contact);
            handleCloseModal();
          }}
          ocrCreate={ocrCreate}
        />
      </DialogContent>
    </SimpleDrawDlg>
  );
}
