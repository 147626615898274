import { generateSignatureUrl, Task } from '@admiin-com/ds-graphql';
import SimpleDrawDlg from '../../components/SimpleDrawDlg/SimpleDrawDlg';
import { getSignersFromAnnotations } from '../../helpers/signature';
import {
  useSnackbar,
  WBButton,
  WBFlex,
  WBRadio,
  WBRadioGroup,
  WBTypography,
} from '@admiin-com/ds-web';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useClipboard } from '@admiin-com/ds-hooks';

interface Props {
  open: boolean;
  onClose: () => void;
  task: Task;
}

export const TaskSignersModal: React.FC<Props> = ({ open, task, onClose }) => {
  const signers = getSignersFromAnnotations(task);
  const [selectedSigner, setSelectedSigner] = React.useState(signers?.[0]?.id);

  const [generateSignatureUrlMutate] = useMutation(gql(generateSignatureUrl));

  const [copyLinkLoading, setCopyLinkLoading] = React.useState(false);
  const [resendLoading, setResendLoading] = React.useState(false);
  const [copyLinkDisabled, setCopyLinkDisabled] = React.useState(false);
  const [resendDisabled, setResendDisabled] = React.useState(false);

  const showSnackbar = useSnackbar();
  const [_, copyToClipboard] = useClipboard();
  const onCopyLink = async () => {
    try {
      setCopyLinkDisabled(false);
      setResendDisabled(true);
      setCopyLinkLoading(true);
      const generatedSignatureUrlData = await generateSignatureUrlMutate({
        variables: {
          input: {
            entityId: task?.entityId,
            taskId: task?.id,
            resend: false,
            signerId: selectedSigner,
          },
        },
      });
      const urlData = generatedSignatureUrlData?.data?.generateSignatureUrl;
      const url = JSON.parse(urlData).url;
      copyToClipboard(url);
      showSnackbar({
        message: t('documentLinkCopied', { ns: 'taskbox' }),
        severity: 'success',
      });
    } catch (e: any) {
      showSnackbar({
        message: t(e?.message, { ns: 'errors' }),
        severity: 'error',
      });
    } finally {
      setCopyLinkLoading(false);
      setResendDisabled(false);
      setCopyLinkDisabled(false);
      onClose();
    }
  };

  const onResend = async () => {
    try {
      setResendDisabled(true);
      setResendLoading(true);
      await generateSignatureUrlMutate({
        variables: {
          input: {
            entityId: task?.entityId,
            taskId: task?.id,
            resend: true,
            signerId: selectedSigner,
          },
        },
      });
      showSnackbar({
        message: t('resentDocumentEmail', { ns: 'taskbox' }),
        severity: 'success',
      });
    } catch (e: any) {
      showSnackbar({
        message: t(e?.message, { ns: 'errors' }),
        severity: 'error',
      });
    } finally {
      setResendLoading(false);
      setResendDisabled(false);
      setCopyLinkDisabled(false);
      onClose();
    }
  };

  return (
    <SimpleDrawDlg open={open} handleClose={onClose}>
      <DialogTitle variant="h3">Signers</DialogTitle>
      <DialogContent>
        <WBFlex gap={2} flexDirection={'column'}>
          <WBRadioGroup
            defaultValue={signers?.[0]?.id}
            value={selectedSigner}
            onChange={(e) => setSelectedSigner(e.target.value)}
          >
            {signers.map((signer, index) => (
              <FormControlLabel
                value={signer.id}
                key={signer.id}
                control={<WBRadio />}
                label={
                  <WBFlex flexDirection="column">
                    <WBTypography fontWeight="bold">{signer.name}</WBTypography>
                    <WBTypography>{signer.email}</WBTypography>
                  </WBFlex>
                }
                sx={{ mt: 1 }}
              />
            ))}
          </WBRadioGroup>
        </WBFlex>
      </DialogContent>
      <DialogActions>
        <WBButton
          variant="outlined"
          onClick={onCopyLink}
          disabled={copyLinkDisabled}
          loading={copyLinkLoading}
        >
          {t('Copy link')}
        </WBButton>
        <WBButton
          onClick={onResend}
          disabled={resendDisabled}
          loading={resendLoading}
        >
          {t('Resend')}
        </WBButton>
      </DialogActions>
    </SimpleDrawDlg>
  );
};
