import { styled, Toolbar } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import AdmiinLogo from 'apps/react-app/src/app/components/AdmiinLogo/AdmiinLogo';
import { EntitySelector } from 'apps/react-app/src/app/components/EntitySelector/EntitySelector';
import RightItems from './RightItems';

export const TopBarDesktop = () => {
  return (
    <AppBar position="fixed">
      <Toolbar sx={{ height: '80px' }}>
        <AdmiinLogo />
        <EntitySelector />
        <RightItems />
      </Toolbar>
    </AppBar>
  );
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));
