import { DocumentAnalysisLineItemInput, TaxType } from '@admiin-com/ds-graphql';
import { WBBox, WBSelect, WBTextField } from '@admiin-com/ds-web';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputAdornment, InputLabel } from '@mui/material';
import { CurrencyNumber } from '../../../CurrencyNumber/CurrencyNumber';
import React from 'react';

export default function LineItemsCreateForm() {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<DocumentAnalysisLineItemInput>();
  const { t } = useTranslation();

  const [quantity, unitPrice, taxType] = useWatch({
    control,
    name: ['quantity', 'unitPrice', 'taxType'],
    defaultValue: { quantity: 1, unitPrice: 0, taxType: TaxType.GST },
  });

  const changePriceByUnitPrice = (unitPrice: number) => {
    const newPrice =
      (quantity ?? 1) * unitPrice * (taxType === TaxType.GST ? 1.1 : 1);
    setValue('price', newPrice ? Math.round(newPrice * 100) / 100 : 0);
  };

  const changePriceByQuantity = (quantity: number) => {
    const newPrice =
      quantity * (unitPrice ?? 0) * (taxType === TaxType.GST ? 1.1 : 1);
    setValue('price', newPrice ? Math.round(newPrice * 100) / 100 : 0);
  };

  const changePriceByTaxType = (taxType: TaxType) => {
    const newPrice =
      (quantity ?? 1) * (unitPrice ?? 0) * (taxType === TaxType.GST ? 1.1 : 1);
    setValue('price', newPrice ? Math.round(newPrice * 100) / 100 : 0);
  };

  const changeUnitPriceByPrice = (price: number) => {
    const newUnitPrice =
      price / (quantity ?? 1) / (taxType === TaxType.GST ? 1.1 : 1);
    setValue(
      'unitPrice',
      newUnitPrice ? Math.round(newUnitPrice * 100) / 100 : 0
    );
  };

  return (
    <>
      <Controller
        name="description"
        control={control}
        defaultValue={''}
        rules={{ required: t('descriptionRequired', { ns: 'taskbox' }) }}
        render={({ field }) => (
          <WBTextField
            {...field}
            label={t('description', { ns: 'taskbox' })}
            helperText={(errors[field.name]?.message as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />
      <Controller
        name="quantity"
        defaultValue={1}
        control={control}
        render={({ field }) => (
          <WBTextField
            {...field}
            onChange={(e) => {
              field.onChange(e);
              changePriceByQuantity(Number(e.target.value));
            }}
            label={t('qty', { ns: 'taskbox' })}
            type="number"
            helperText={(errors[field.name]?.message as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />
      <Controller
        name="unitPrice"
        defaultValue={0}
        control={control}
        render={({ field }) => (
          <WBTextField
            {...field}
            onChange={(e) => {
              field.onChange(e);
              changePriceByUnitPrice(Number(e.target.value));
            }}
            label={t('price', { ns: 'taskbox' })}
            type="number"
            leftIcon={<InputAdornment position="start">$</InputAdornment>}
            helperText={(errors[field.name]?.message as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />

      <Controller
        name="taxType"
        defaultValue={TaxType.GST}
        control={control}
        rules={{ required: t('gstRequired', { ns: 'taskbox' }) }}
        render={({ field }) => (
          <WBSelect
            {...field}
            onChange={(e) => {
              field.onChange(e);
              changePriceByTaxType(e.target.value as TaxType);
            }}
            placeholder={t('selectGST', { ns: 'taskbox' })}
            label={t('gst', { ns: 'taskbox' })}
            options={Object.keys(TaxType).map((key) => ({
              value: key,
              label: t(key, { ns: 'taskbox' }),
            }))}
            helperText={(errors[field.name]?.message as string as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />

      <Controller
        name="price"
        defaultValue={0}
        control={control}
        render={({ field }) => (
          <WBTextField
            {...field}
            onChange={(e) => {
              field.onChange(Number(e.target.value));
              changeUnitPriceByPrice(Number(e.target.value));
            }}
            label={t('amount', { ns: 'taskbox' })}
            type="number"
            leftIcon={<InputAdornment position="start">$</InputAdornment>}
            helperText={(errors[field.name]?.message as string) ?? ''}
            error={!!errors[field.name]}
          />
        )}
      />
    </>
  );
}
