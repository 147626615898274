import { WBFlex } from '@admiin-com/ds-web';
import PdfViewer from '../PdfViewer/PdfViewer';
import { useDocumentGuestUrl } from '../../hooks/useDocumentUrl/useDocumentUrl';
import { styled } from '@mui/material';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import { forwardRef } from 'react';
import { TaskGuest, TaskSignatureStatus } from '@admiin-com/ds-graphql';

/* eslint-disable-next-line */
export interface TaskPdfSignatureProps {
  token: string;
  taskGuest: TaskGuest;
}

export const TaskPdfSignatureGuest = forwardRef<
  HTMLDivElement,
  TaskPdfSignatureProps
>(({ token, taskGuest }, ref) => {
  const { documentUrl, loading } = useDocumentGuestUrl(token);

  return (
    <WBFlex flex={1} sx={{ height: '100%', justifyContent: 'center' }}>
      {loading ? (
        <ImagePlaceholder>
          <LottieAnimation loop path="/lottie/loading-pdf.lottie" />
        </ImagePlaceholder>
      ) : documentUrl && token ? (
        <PdfViewer
          ref={ref ?? null}
          documentUrl={documentUrl}
          annotations={
            taskGuest.signatureStatus === TaskSignatureStatus.SIGNED
              ? null
              : taskGuest.annotations
          }
        />
      ) : null}
    </WBFlex>
  );
});

const ImagePlaceholder = styled(WBFlex)(({ theme }) => ({
  borderRadius: '100%',
  width: '30%',
  height: '30%',
  [theme.breakpoints.down('sm')]: {},
}));

export default TaskPdfSignatureGuest;
