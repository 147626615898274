import {
  WBButton,
  WBFlex,
  WBForm,
  WBTypography,
  useMediaQuery,
  useSnackbar,
} from '@admiin-com/ds-web';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Contact,
  CreateContactInput,
  CSGetSelectedEntityId as GET_SELECTED_ENTITY_ID,
} from '@admiin-com/ds-graphql';
import { useTheme } from '@mui/material';
import { useUpdateContact } from '../../hooks/useUpdateContact/useUpdateContact';
import ContactForm from '../../components/ContactForm/ContactForm';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import EntityCreateForm from '../../components/EntityCreateForm/EntityCreateForm';

type SimpleContact = CreateContactInput & {
  id?: string;
};

export interface ContactDetailUpdateFormData {
  client: SimpleContact;
  entity: { name: string; taxNumber: string; companyNumber: string };
}

interface ContactDetailUpdateFormProps {
  entityId?: string;
  updatingContact?: SimpleContact | null;
  onSubmitted?: (contact: Contact) => void;
  ocrCreate?: boolean;
}

export function ContactDetailUpdateForm({
  updatingContact = null,
  entityId: entityIdProps,
  onSubmitted,
  ocrCreate,
}: ContactDetailUpdateFormProps) {
  const { t } = useTranslation();
  const methods = useForm<ContactDetailUpdateFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    values: {
      client: updatingContact ?? {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        mobile: '',
        id: '',
        entityId: entityIdProps ?? '',
      },
      entity: {
        name: updatingContact?.name ?? '',
        taxNumber: updatingContact?.taxNumber ?? '',
        companyNumber: updatingContact?.companyNumber ?? '',
      },
    },
  });
  const { handleSubmit } = methods;

  const [loading, setLoading] = useState(false);

  const [updateContact, { error: updateError }] = useUpdateContact();

  const { data: updatingContactEntityIdData } = useQuery(
    gql(GET_SELECTED_ENTITY_ID)
  );

  const entityId =
    entityIdProps ?? updatingContactEntityIdData?.updatingContactEntityId;

  const showSnackbar = useSnackbar();
  const onSubmit = async (data: ContactDetailUpdateFormData, event: any) => {
    event.stopPropagation();
    setLoading(true);
    try {
      const contact: CreateContactInput = {
        email: data.client.email ?? '',
        firstName: data.client.firstName ?? '',
        lastName: data.client.lastName ?? '',
        entityId,
        address: data.client.address?.address1 ? data.client.address : null,
      };
      if (data.client.phone) contact.phone = data.client.phone;
      if (data.client.mobile) contact.mobile = data.client.mobile;

      if (updatingContact && updatingContact.id) {
        const updatedContact = await updateContact({
          variables: {
            input: {
              ...contact,
              id: updatingContact.id,
            },
          },
        });
        onSubmitted && onSubmitted(updatedContact?.data.updateContact);
        showSnackbar({
          message: t('contactUpdated', { ns: 'contacts' }),
          severity: 'success',
          horizontal: 'right',
          vertical: 'bottom',
        });
      }

      setLoading(false);
    } catch (err) {
      console.log('error create contact: ', err);
      setLoading(false);
    }
  };
  return (
    <WBFlex flexDirection="column" alignItems="center" mb={4}>
      <FormProvider {...methods}>
        <WBForm
          mt={0}
          onSubmit={(e) => {
            e.stopPropagation(); // Prevent the event from bubbling up to the outer form
            handleSubmit(onSubmit)(e);
          }}
          alignSelf="stretch"
        >
          <ContactForm ocrCreate={ocrCreate} />
          <WBTypography
            variant={'h3'}
            noWrap
            mt={5}
            component="div"
            color="dark"
            sx={{ flexGrow: 1, textAlign: 'left' }}
          >
            {t('businessDetails', { ns: 'contacts' })}
          </WBTypography>
          <EntityCreateForm
            noType
            noAddress
            disabled={true}
            ocrCreate={ocrCreate}
          />
          <WBButton
            sx={{
              mt: {
                xs: 6,
                sm: 8,
              },
            }}
            loading={loading}
          >
            {t(`${updatingContact ? 'updateContact' : 'createContact'}`, {
              ns: 'contacts',
            })}
          </WBButton>
        </WBForm>
      </FormProvider>
      <ErrorHandler errorMessage={updateError?.message} />
    </WBFlex>
  );
}
