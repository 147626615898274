import {
  createUploadTaskUrlGuest as CREATE_UPLOADTASK_URL_GUEST,
  getTaskPublic as GET_TASK_PUBLIC,
  PaymentGuest,
  TaskGuest,
  TaskPaymentStatus,
  TaskSignatureStatus,
  TaskType,
  updateTaskPublic as UPDATE_TASK_PUBLIC,
} from '@admiin-com/ds-graphql';
import { useSnackbar, WBFlex, WBTypography } from '@admiin-com/ds-web';
import { styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GuestSignDesktop } from './GuestSignDesktop';
import { GuestSignMobile } from './GuestSignMobile';
import { PdfScrollProvider } from '../PdfSign/PdfScrollContext';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSignedDocumentUpload } from '../../hooks/useSignedDocumentUpload';
import { useDocumentGuestUrl } from '../../hooks/useDocumentUrl/useDocumentUrl';
import { Annotation } from 'pspdfkit';
import React, { useRef, useState } from 'react';
import { InvoicePay } from './InvoicePay';
import { set } from 'lodash';

interface Props {
  taskGuest: TaskGuest;
  token?: string;
  paymentGuest?: PaymentGuest;
  paymentId?: string;
}
export const GuestSignature = (props: Props) => {
  const theme = useTheme();

  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const { t } = useTranslation();
  const headerColor = theme.palette.mode === 'dark' ? 'white' : 'white';
  const [updateTaskPublic] = useMutation(gql(UPDATE_TASK_PUBLIC));
  const showSnackbar = useSnackbar();
  const { documentUrl } = useDocumentGuestUrl(props.token);
  const [createUploadTaskUrlGuest] = useMutation(
    gql(CREATE_UPLOADTASK_URL_GUEST)
  );
  const [disableDownload, setDisableDownload] = React.useState(false);

  const [getTaskPublic] = useLazyQuery(gql(GET_TASK_PUBLIC));
  const { uploadSignedDocumentFromSignedUrl } = useSignedDocumentUpload();

  const [signed, setSigned] = useState(false);

  const updateAnnotations = async (annoations: string) => {
    try {
      const taskGuestData = await getTaskPublic({
        variables: { token: props.token },
        fetchPolicy: 'no-cache',
      });
      const taskGuest = taskGuestData?.data?.getTaskPublic;
      const preSignedAnnotations = JSON.parse(
        taskGuest.annotations
      )?.annotations;
      const newAnnotations = JSON.parse(annoations)?.annotations;
      const postSignedAnnotations = preSignedAnnotations.map(
        (annotation: any) => {
          const userAnnotation = newAnnotations.find(
            (newAnnotation: Annotation) =>
              newAnnotation?.customData?.id === annotation?.customData?.id
          );
          return userAnnotation ?? annotation;
        }
      );

      const postSignedAnnotationsString = JSON.stringify({
        ...JSON.parse(taskGuest.annotations),
        annotations: postSignedAnnotations,
        attachments: {
          ...JSON.parse(taskGuest.annotations).attachments,
          ...(JSON.parse(annoations).attachments ?? {}),
        },
      });

      const input: any = {
        token: props.token,
        annotations: postSignedAnnotationsString,
      };

      if (taskGuest.isFinalSigner) {
        try {
          const uploadUrlData = await createUploadTaskUrlGuest({
            variables: {
              input: {
                token: props.token,
              },
            },
          });

          const uploadUrl = uploadUrlData.data.createUploadTaskUrlGuest.url;

          await uploadSignedDocumentFromSignedUrl(
            documentUrl as string,
            uploadUrl,
            postSignedAnnotationsString
          );

          setDisableDownload(true);
          setTimeout(() => {
            setDisableDownload(false);
          }, 3000);
        } catch (error: any) {
          if (error.message !== 'UNAUTHORISED_NOT_FINAL_SIGNER') {
            throw error;
          }
        }
      }
      const { data: signedTaskData } = await updateTaskPublic({
        variables: {
          input,
        },
        fetchPolicy: 'no-cache', // Ensure no cache is used
        update: () => null, // Prevent cache updates
      });

      const signedTask = signedTaskData.updateTaskPublic;
      if (
        signedTask.paymentStatus === TaskPaymentStatus.PENDING_PAYMENT &&
        (signedTask.signatureStatus === TaskSignatureStatus.SIGNED ||
          signedTask.signatureStatus ===
            TaskSignatureStatus.PENDING_SIGNATURE) &&
        signedTask.type === TaskType.SIGN_PAY
      ) {
        setSigned(true);

        setTimeout(() => setShowPaymentPage(true), 10000);
      }
    } catch (e: any) {
      showSnackbar({
        message: t(e.message, { ns: 'payment' }),
        severity: 'error',
        horizontal: 'right',
        vertical: 'bottom',
      });
      throw e;
    }
  };

  const redirectFunc = signed ? () => setShowPaymentPage(true) : undefined;

  if (showPaymentPage) {
    return <InvoicePay {...props} />;
  }

  return (
    <Container>
      <StyledWBFlex>
        <HeaderFlex>
          <CenteredTypography
            variant="h5"
            fontWeight="medium"
            color={headerColor}
          >
            {t('requestForSignature', { ns: 'taskbox' })}
          </CenteredTypography>
        </HeaderFlex>
        {props.taskGuest.annotations && (
          <PdfScrollProvider
            annotations={props.taskGuest.annotations}
            onSave={updateAnnotations}
            taskGuest={props.taskGuest}
          >
            <WBFlex
              flexDirection="column"
              display={['none', 'block']}
              width="100%"
              flex={1}
            >
              <GuestSignDesktop
                documentUrl={documentUrl}
                {...props}
                onRedirect={redirectFunc}
                disabledDownload={disableDownload}
              />
            </WBFlex>
            <WBFlex
              flexDirection="column"
              display={['block', 'none']}
              width="100%"
              flex={1}
            >
              <GuestSignMobile
                documentUrl={documentUrl}
                {...props}
                onRedirect={redirectFunc}
                disabledDownload={disableDownload}
              />
            </WBFlex>
          </PdfScrollProvider>
        )}
      </StyledWBFlex>
    </Container>
  );
};

const Container = styled(WBFlex)(({ theme }) => ({
  background: theme.palette.background.default,
  height: '100vh',
}));

const StyledWBFlex = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  height: '100%',
  backgroundColor: theme.palette.background.default,
}));

const HeaderFlex = styled(WBFlex)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
  padding: theme.spacing(3, 8),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#151515' : '#151515',
}));

const CenteredTypography = styled(WBTypography)(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
  marginBottom: 0,
}));

export default GuestSignature;
