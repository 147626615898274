import { WBFlex } from '@admiin-com/ds-web';
import AdmiinLogo from '../AdmiinLogo/AdmiinLogo';
import { WBS3Avatar } from '@admiin-com/ds-amplify-web';

export const Header = () => {
  return (
    <WBFlex
      justifyContent={'space-between'}
      alignItems={'center'}
      px={4}
      py={2.5}
      sx={{ backgroundColor: 'background.paper' }}
    >
      <AdmiinLogo />
      <WBFlex>
        <WBS3Avatar sx={{ borderRadius: '100%' }} />
      </WBFlex>
    </WBFlex>
  );
};
