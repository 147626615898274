import { InvoiceStatus, PaymentFrequency } from '@admiin-com/ds-graphql';
import {
  WBBox,
  WBFlex,
  WBForm,
  WBTextField,
  WBToggleButtonGroup,
  WBCheckbox,
  WBTypography,
} from '@admiin-com/ds-web';
import {
  styled,
  ToggleButton,
  useTheme,
  useMediaQuery,
  InputLabel,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InformationIcon from '../../../../../../libs/design-system-web/src/lib/components/primatives/InformationIcon/InformationIcon';
import { useFeatureFlags } from '../../hooks/useFeatureFlags/useFeatureFlags';
import { InvoiceNumberEdit } from './components/InvoiceNumberEdit';
import AutoCompleteLookup from '../AutoCompleteLookup/AutoCompleteLookup';
import DueDateSelector from '../DueDateSelector/DueDateSelector';
import TaskUpload from './components/TaskUpload';
import { InvoiceCreateButtons } from '../InvoiceCreateForm/InvoiceCreateButtons';
import { useDefaultTaskDirection } from '../../pages/TaskCreation/useDefaultTaskDirection';
import React from 'react';
import { useTaskCreationContext } from '../../pages/TaskCreation/TaskCreation';
import useSelectedFirm from '../../hooks/useSelectedFirm/useSelectedFirm';

interface Props {
  handleNext: () => void;
  onDraft: () => Promise<void>;
  onPreview: () => void;
  inputs: {
    [key: string]: {
      label: string;
      name: string;
      type: string;
      defaultValue: any;
      placeholder: string;
      rules: any;
    };
  };
  isDraft?: boolean;
}

export const InvoiceDetail = ({
  onDraft,
  onPreview,
  inputs,
  handleNext,
  isDraft,
}: Props) => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = methods;
  const disabled = !isValid || Object.keys(errors).length > 0;

  const onSubmit = () => {
    console.log('submit');
  };

  const entityId = watch('from')?.id;
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { fromType, toType, setToType, setFromType } =
    useDefaultTaskDirection();
  const { maximumInstallments, paymentFrequency, allowExtendedTerms } = watch();
  const theme = useTheme();

  const { client } = useTaskCreationContext();
  const { selectedFirm } = useSelectedFirm();
  const additionalFirmOption = client && selectedFirm ? [selectedFirm] : [];

  React.useEffect(() => {
    if (client)
      if (entityId && entityId === selectedFirm?.id) {
        methods.setValue('to', client);
        setToType('Entity');
      }
  }, [entityId, selectedFirm]);

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Controller
          name="invoiceStatus"
          control={methods.control}
          defaultValue={InvoiceStatus.INVOICE}
          render={({ field }) => (
            <WBToggleButtonGroup
              {...field}
              size="medium"
              exclusive
              disabled={isDraft}
            >
              {Object.keys(InvoiceStatus).map((key) => {
                return (
                  <StyledRectToggleButton
                    value={key}
                    key={key}
                    disabled={isDraft}
                  >
                    {t(key, { ns: 'taskbox' })}
                  </StyledRectToggleButton>
                );
              })}
            </WBToggleButtonGroup>
          )}
        />
        <WBBox mt={3}>
          <InvoiceNumberEdit />
        </WBBox>
        <WBFlex flexDirection={['column', 'row']} gap={[0, 4]}>
          <Controller
            control={control}
            name={'from'}
            rules={inputs.from.rules}
            defaultValue={inputs.from.defaultValue}
            render={({ field, formState: { errors } }) => (
              <AutoCompleteLookup
                {...field}
                noPopupIcon
                additonalOptions={additionalFirmOption}
                label={inputs.from.label}
                placeholder={inputs.from.placeholder}
                type={fromType}
                helperText={(errors[field.name]?.message as string) ?? ''}
                error={!!errors[field.name]}
              />
            )}
          />
          <Controller
            control={control}
            name={'to'}
            defaultValue={inputs.to.defaultValue}
            rules={inputs.to.rules}
            render={({ field, formState: { errors } }) => (
              <AutoCompleteLookup
                {...field}
                noPopupIcon
                label={inputs.to.label}
                placeholder={inputs.to.placeholder}
                type={toType}
                entityId={entityId}
                helperText={(errors[field.name]?.message as string) ?? ''}
                error={!!errors[field.name]}
              />
            )}
          />
        </WBFlex>
        <WBFlex flexDirection={['column', 'row']} gap={[0, 4]}>
          <Controller
            control={control}
            name={'issueAt'}
            rules={inputs.issueAt.rules}
            defaultValue={inputs.issueAt.defaultValue}
            render={({ field, formState: { errors } }) => (
              <DueDateSelector
                {...field}
                type={inputs.issueAt.type}
                placeholder={inputs.issueAt.placeholder}
                label={inputs.issueAt.label}
                fullWidth
                sx={{ flex: 1 }}
                helperText={(errors[field.name]?.message as string) ?? ''}
                error={!!errors[field.name]}
              />
            )}
          />
          <Controller
            control={control}
            name={'dueAt'}
            rules={inputs.dueAt.rules}
            defaultValue={inputs.dueAt.defaultValue}
            render={({ field, formState: { errors } }) => (
              <DueDateSelector
                {...field}
                type={inputs.dueAt.type}
                placeholder={inputs.dueAt.placeholder}
                label={inputs.dueAt.label}
                fullWidth
                sx={{ flex: 1 }}
                helperText={(errors[field.name]?.message as string) ?? ''}
                error={!!errors[field.name]}
              />
            )}
          />
        </WBFlex>
        <WBBox mt={2}>
          <Controller
            name="paymentFrequency"
            control={methods.control}
            defaultValue={PaymentFrequency.ONCE}
            render={({ field }) => (
              <WBToggleButtonGroup
                {...field}
                size="medium"
                fullWidth={isMobile}
                exclusive
                sx={{ marginTop: 2, gap: 1 }}
                label={t('paymentFrequency', { ns: 'taskbox' })}
              >
                {[PaymentFrequency.ONCE, PaymentFrequency.MONTHLY].map(
                  (key: PaymentFrequency) => {
                    return (
                      <RoundedToggleButton
                        value={key}
                        key={key}
                        selected={
                          key === PaymentFrequency.ONCE
                            ? key === paymentFrequency
                            : paymentFrequency !== 'ONCE'
                        }
                      >
                        {t(key === PaymentFrequency.ONCE ? key : 'RECURRING', {
                          ns: 'taskbox',
                        })}
                      </RoundedToggleButton>
                    );
                  }
                )}
              </WBToggleButtonGroup>
            )}
          />
        </WBBox>

        {paymentFrequency !== PaymentFrequency.ONCE && (
          <WBFlex mt={2}>
            <Controller
              name="paymentFrequency"
              control={methods.control}
              defaultValue={PaymentFrequency.ONCE}
              render={({ field }) => (
                <WBToggleButtonGroup
                  {...field}
                  size="medium"
                  fullWidth={isMobile}
                  exclusive
                  sx={{
                    marginTop: 2,
                    gap: 1,
                    display: 'flex',
                    flexWrap: ['wrap', 'nowrap'],
                  }}
                >
                  {Object.keys(PaymentFrequency)
                    .filter((key) => key !== PaymentFrequency.ONCE)
                    .map((key) => {
                      return (
                        <RoundedToggleButton
                          value={key}
                          key={key}
                          size="small"
                          sx={{ fontSize: theme.typography.body2.fontSize }}
                        >
                          {t(key, {
                            ns: 'taskbox',
                          })}
                        </RoundedToggleButton>
                      );
                    })}
                </WBToggleButtonGroup>
              )}
            />
          </WBFlex>
        )}

        {paymentFrequency === PaymentFrequency.ONCE && (
          <WBBox mt={2}>
            <Controller
              name={inputs.allowExtendedTerms.name}
              control={methods.control}
              defaultValue={false}
              render={({ field }) => (
                <>
                  <InputLabel
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <WBTypography
                      component={'div'}
                      variant="inherit"
                      color={'inherit'}
                    >
                      {t('extendedPaymentTerms', { ns: 'taskbox' })}{' '}
                      <InformationIcon
                        title={t('extendedTermsInfo', { ns: 'taskbox' })}
                      />
                    </WBTypography>
                  </InputLabel>
                  <WBCheckbox
                    {...field}
                    checked={field.value}
                    label={t('allowExtendedTerms', { ns: 'taskbox' })}
                    disabled={paymentFrequency !== PaymentFrequency.ONCE}
                  />
                </>
              )}
            />
          </WBBox>
        )}

        {/*{allowExtendedTerms && (*/}
        {/*  <WBBox mt={2}>*/}
        {/*    <Controller*/}
        {/*      name={inputs.maximumInstallments.name}*/}
        {/*      control={methods.control}*/}
        {/*      defaultValue={inputs.maximumInstallments.defaultValue}*/}
        {/*      render={({ field }) => (*/}
        {/*        <WBToggleButtonGroup*/}
        {/*          {...field}*/}
        {/*          size="medium"*/}
        {/*          fullWidth={isMobile}*/}
        {/*          exclusive*/}
        {/*          sx={{ marginTop: 2, gap: 1 }}*/}
        {/*          label={t('maximumInstallments', { ns: 'taskbox' })}*/}
        {/*          infoTooltip={t('maximumInstallmentsTooltip', {*/}
        {/*            ns: 'taskbox',*/}
        {/*          })}*/}
        {/*        >*/}
        {/*          {[3].map((option: number) => {*/}
        {/*            return (*/}
        {/*              <RoundedToggleButton*/}
        {/*                value={option}*/}
        {/*                key={option}*/}
        {/*                selected={maximumInstallments === option?.toString()}*/}
        {/*              >*/}
        {/*                {`${option} ${t('installments', { ns: 'common' })}`}*/}
        {/*              </RoundedToggleButton>*/}
        {/*            );*/}
        {/*          })}*/}
        {/*        </WBToggleButtonGroup>*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </WBBox>*/}
        {/*)}*/}
        <WBBox mt={2}>
          <Controller
            rules={inputs.documents.rules}
            control={control}
            name={'supportDocuments' as const}
            render={({ field }) => (
              <TaskUpload
                {...field}
                label={inputs.documents.label}
                includeNameInKey
              />
            )}
          />
        </WBBox>
        <Controller
          control={control}
          name={'notes' as const}
          rules={inputs.note.rules}
          render={({ field }) => (
            <WBTextField
              {...field}
              type={inputs.note.type}
              placeholder={inputs.note.placeholder}
              label={inputs.note.label}
              key={field.name}
              multiline
              fullWidth
            />
          )}
        />
      </FormContainer>

      <InvoiceCreateButtons
        onDraft={onDraft}
        onPreview={onPreview}
        handleNext={handleNext}
        isValid={!disabled}
        disableDraft={isDraft}
      />
    </Container>
  );
};

export const StyledRectToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  fontWeight: 500,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  padding: theme.spacing(1.5, 3),
}));

const RoundedToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: '50px !important',
  borderColor: theme.palette.primary.main,
  fontWeight: 500,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  borderWidth: '1px',
  borderLeftColor: `${theme.palette.primary.main} !important`,
  padding: theme.spacing(1.5, 3),
}));

const Container = styled(WBForm)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  alignItems: 'end',
  backgroundColor: theme.palette.background.default,
}));

const FormContainer = styled(WBBox)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(5),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  flex: 1,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
}));
