import { WBFlex } from '@admiin-com/ds-web';
import { useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/system';
import LoadSvgIcon from 'apps/react-app/src/app/component/LoadSvgIcon/LoadSvgIcon';
import NotificationRedIcon from 'apps/react-app/src/assets/icons/notification-red.svg';
import NotificationIcon from 'apps/react-app/src/assets/icons/notification.svg';
import InformationIcon from 'apps/react-app/src/assets/icons/information-circle.svg';
import SearchIcon from 'apps/react-app/src/assets/icons/zoom.svg';
import { AvatarMenu } from './AvatarMenu';

interface Props {
  hasUnreadNotifications?: boolean;
}

const NotificationIconItem = ({
  hasUnreadNotifications,
}: {
  hasUnreadNotifications?: boolean;
}) => {
  const theme = useTheme();
  return hasUnreadNotifications ? (
    <LoadSvgIcon
      component={NotificationRedIcon}
      width={24}
      height={24}
      fill={theme.palette.text.primary}
    />
  ) : (
    <LoadSvgIcon
      component={NotificationIcon}
      width={24}
      height={24}
      fill={theme.palette.text.primary}
    />
  );
};

const RightItemsDesktop = ({ hasUnreadNotifications }: Props) => {
  const theme = useTheme();
  return (
    <WBFlex gap={3} alignItems={'center'}>
      <NotificationIconItem hasUnreadNotifications={hasUnreadNotifications} />
      <LoadSvgIcon
        component={InformationIcon}
        width={24}
        height={24}
        fill={theme.palette.text.primary}
      />
      <AvatarMenu showName />
    </WBFlex>
  );
};

const RightItemsMobile = ({ hasUnreadNotifications }: Props) => {
  const theme = useTheme();
  return (
    <WBFlex alignItems={'center'} gap={2}>
      <LoadSvgIcon
        component={SearchIcon}
        width={24}
        height={24}
        fill={theme.palette.text.primary}
      />
      <NotificationIconItem hasUnreadNotifications={hasUnreadNotifications} />
      <AvatarMenu />
    </WBFlex>
  );
};

export default function RightItems({ hasUnreadNotifications }: Props) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  return isMobile ? (
    <RightItemsMobile />
  ) : (
    <RightItemsDesktop hasUnreadNotifications={hasUnreadNotifications} />
  );
}
