import { CSGetEntity, Entity, getEntity } from '@admiin-com/ds-graphql';
import { WBButton, WBFlex, WBSvgIcon, WBTypography } from '@admiin-com/ds-web';
import { DialogActions, DialogContent } from '@mui/material';
import { t } from 'i18next';
import SecurityIcon from '../../../assets/icons/security.svg';
import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';

export interface VerificationCompleteProps {
  entity?: Entity | null;
  onClose: () => void;
}

export function VerificationComplete({
  entity,
  onClose,
}: VerificationCompleteProps) {
  const [getEntityLazy] = useLazyQuery(gql(CSGetEntity));
  React.useEffect(() => {
    setTimeout(
      () =>
        getEntityLazy({
          variables: {
            id: entity?.id,
          },
        }),
      5000
    );
    setTimeout(onClose, 10000);
  }, [onClose]);

  console.log(
    'entityAccountStatusentityAccountStatus: ',
    entity?.accountStatus
  );

  return (
    <>
      <DialogContent>
        <WBFlex
          justifyContent="center"
          alignItems={'center'}
          flexDirection={'column'}
        >
          <WBSvgIcon
            fontSize="large"
            viewBox="0 0 40 40"
            sx={{ width: '2em', height: '2em' }}
          >
            <SecurityIcon />
          </WBSvgIcon>
          <WBTypography my={3} variant="h3">
            {t('KYCSUBMITTED', { ns: 'common' })}
          </WBTypography>
          <WBTypography mb={4} textAlign={'center'}>
            {t('KYCSUBMITTEDSubtitle', { ns: 'common' })}
          </WBTypography>
        </WBFlex>
      </DialogContent>
      <DialogActions>
        <WBButton fullWidth onClick={onClose}>
          {t('close', { ns: 'common' })}
        </WBButton>
      </DialogActions>
    </>
  );
}

export default VerificationComplete;
