import { createTaskSupportDocumentUrl, Task } from '@admiin-com/ds-graphql';
import { TaskSectionLayout } from './TaskSectionLayout';
import { WBFlex, WBLinkButton } from '@admiin-com/ds-web';
import { gql, useMutation } from '@apollo/client';
import { useSignedDocumentUpload } from '../../hooks/useSignedDocumentUpload';

interface TaskSupportDocumentsProps {
  task: Task;
}
export const TaskSuportDocuments = ({ task }: TaskSupportDocumentsProps) => {
  const [createTaskSupportDocumentUrlFunc] = useMutation(
    gql(createTaskSupportDocumentUrl)
  );
  const { downloadDocument } = useSignedDocumentUpload();

  const downloadSupportDocument = async (key: string) => {
    const urlData = await createTaskSupportDocumentUrlFunc({
      variables: {
        input: {
          taskId: task.id,
          key,
          entityId: task.entityId,
        },
      },
    });
    const url = urlData.data?.createTaskSupportDocumentUrl?.url;
    await downloadDocument(url);
  };

  if (task.supportDocuments === null || task.supportDocuments?.length === 0)
    return null;

  return (
    <TaskSectionLayout title="Supporting Documents">
      <WBFlex flexDirection="column" gap={1}>
        {task.supportDocuments?.map((doc, index) => {
          return (
            doc && (
              <WBLinkButton
                key={index}
                sx={{ color: 'primary.main', fontSize: 'body1.fontSize' }}
                onClick={() => {
                  downloadSupportDocument(doc.key);
                }}
              >
                {doc?.key.split('_')[1]}
              </WBLinkButton>
            )
          );
        })}
      </WBFlex>
    </TaskSectionLayout>
  );
};
