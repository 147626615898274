import {
  InvoiceStatus,
  OtherRecipientInput,
  PaymentFrequency,
  PaymentMethod,
  PaymentMethodType,
  TaskSettlementStatus,
  TaxType,
} from '@admiin-com/ds-graphql';
import invoiceString from './invoice';
import * as Handlebars from 'handlebars';
import { userDateFromBackendDate } from '../lib';

const compileTemplate = async (templateSource: string, data: any) => {
  Handlebars.registerHelper('isEven', (index: number) => index % 2 === 0);
  Handlebars.registerHelper(
    'ifEquals',
    function (this: any, arg1, arg2, options) {
      return arg1 === arg2 ? options.fn(this) : options.inverse(this);
    }
  );
  const template = Handlebars.compile(templateSource);
  return template(data);
};

type InvoiceData = {
  from: any; //TODO: types Entity or AutocompleteResult
  to: any; //TODO: types Contact or Entity
  type: string;
  paymentFrequency: PaymentFrequency;
  amount: string;
  dueAt: string;
  reference: string;
  bpayReferenceNumber?: string;
  notes: string;
  lodgementAt: string;
  annotations: any;
  gstInclusive: boolean;
  numberOfPayments: number;
  settlementStatus?: TaskSettlementStatus | null;
  paymentAt: string;
  firstPaymentAt: string;
  lineItems: {
    serviceId?: string | null;
    description?: string | null;
    quantity?: number | null;
    unitAmount?: number | null;
    taxType?: string | null;
    lineAmount?: number | null;
    newService?: boolean | null;
  }[];
  invoiceStatus: InvoiceStatus;
  sendSMS: boolean;
  otherRecipients: OtherRecipientInput[];
  sendCopyToContact: boolean;
  brandColor?: string;
  quoteRequiresSignature?: boolean;
};
const hexWithOpacity = (hex: string, opacity: number) => {
  const alpha = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0');
  return `${hex}${alpha}`;
};

export function formatNumberWithCommas(
  value?: number,
  withDecimals = false
): string {
  if (!value) return '';

  return withDecimals
    ? value?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : value?.toLocaleString('en-US');
}

export function formatCurrency(value: number | null): string {
  return value !== null
    ? `$${value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : '';
}

export const getAmountFromLineItems = (
  rows: {
    serviceId?: string | null;
    description?: string | null;
    quantity?: number | null;
    unitAmount?: number | null;
    taxType?: string | null;
    lineAmount?: number | null;
    newService?: boolean | null;
  }[]
) => {
  const subTotal = rows.reduce(
    (acc, row) => acc + (row?.unitAmount ?? 0) * (row.quantity ?? 0),
    0
  );

  const totalGST = rows.reduce(
    (acc, row) =>
      acc +
      (row?.unitAmount ?? 0) *
        (row.quantity ?? 0) *
        (row.taxType === TaxType.GST ? 0.1 : 0),
    0
  );
  const total = subTotal + totalGST;
  return {
    subTotal,
    totalGST,
    total,
  };
};

const generateInvoiceContent = async (task: InvoiceData) => {
  const { subTotal, totalGST, total } = getAmountFromLineItems(task.lineItems);

  const contact = task.to;
  const entity = task.from;

  const getReceivingAccount = (entity: any) => {
    if (!entity || !entity.paymentMethods) {
      return null;
    }
    return entity.paymentMethods.items.find(
      (method: PaymentMethod) =>
        method?.paymentMethodType === PaymentMethodType.CONNECTED_ACCOUNT_WALLET
    );
  };

  console.log('entity', entity);
  const receivingAccount = getReceivingAccount(entity);
  console.log('receivingAccount', receivingAccount);

  const color = task.brandColor ?? entity?.brandColour ?? '#8C52FF';
  const brandColorLight = hexWithOpacity(color, 0.1);
  // extreact invoice data
  const invoiceData = {
    task: {
      reference: task.reference ?? '',
      dueAt: task.dueAt ? userDateFromBackendDate(task.dueAt) : '',
      notes: task.notes || '',
      invoiceStatus: task.invoiceStatus,
      dueTitle: task.invoiceStatus === 'QUOTE' ? 'Valid Until' : 'Due On',
      documentTitle:
        task.invoiceStatus === InvoiceStatus.INVOICE ? 'TAX INVOICE' : 'QUOTE',
      signatureRequired:
        task.invoiceStatus === InvoiceStatus.QUOTE &&
        Boolean(task.quoteRequiresSignature),
    },
    to: {
      name: contact?.name ?? '',
      taxNumber: contact?.taxNumber ?? '',
      address: contact?.address?.address1,
    },
    from: {
      name: entity?.name ?? '',
      taxNumber: entity?.taxNumber ?? '',
      address: entity?.address?.address1 ?? '',
      contact: { email: entity?.contact?.email ?? '' },
      website: entity?.contact?.WEB_DOMAIN,
      brandColor: color,
      brandColorLight: brandColorLight,
    },
    lineItem: task.lineItems?.map((item) => ({
      description: item.description,
      //unitPrice: item.unitAmount?.toString(),
      unitPrice: formatNumberWithCommas(
        item.unitAmount ? item.unitAmount : 0,
        true
      ),
      qty: item.quantity?.toString(),
      gst: item.taxType?.toString(),
      amount: formatNumberWithCommas(
        item.lineAmount ? item.lineAmount : 0,
        true
      ),
    })),
    receivingAccount: {
      accountName: entity?.name ?? '',
      routingNumber: receivingAccount?.routingNumber,
      number: receivingAccount?.accountNumber,
      // accountName: contact?.bank?.accountName.toString() ?? '',
      // routingNumber: contact?.bank?.routingNumber.toString() ?? '',
      // number: contact?.bank?.accountNumber.toString() ?? '',
    },
    pageNumber: 1,
    pageCount: 1,
    subTotal: formatCurrency(subTotal),
    totalGST: formatCurrency(totalGST),
    total: formatCurrency(total),
  };

  const compiledTemplate = await compileTemplate(invoiceString, invoiceData);
  return compiledTemplate;
};
export { invoiceString, generateInvoiceContent, compileTemplate };
