import { WBButton, WBIcon, WBTypography } from '@admiin-com/ds-web';
import { t } from 'i18next';
import { forwardRef } from 'react';

export const CreateTaskButton = forwardRef<HTMLButtonElement>((props, ref) => {
  return (
    <WBButton
      ref={ref}
      sx={{
        mx: 2,
        my: 1.5,
        py: 2,
      }}
      {...props}
    >
      <WBIcon name="Add" color={'inherit'} size="small" />
      <WBTypography ml={1} color="inherit">
        {t('Create a Task', { ns: 'taskbox' })}
      </WBTypography>
    </WBButton>
  );
});
